.modal-header-cookie{
    font-weight: normal;
    font-size: 34px;
    letter-spacing: rem(0.40);
    line-height: 125px;
    text-align: left;
    color: #000;
    margin-left: 3%;
    margin-right: 3%;
    text-transform: uppercase;
    @include media-breakpoint-down(md){
        font-size: 22px;
    }
    @include media-breakpoint-up(md){
        padding-left: 20px;
    }
    @include media-breakpoint-down(md){
        height: 70px;
        line-height: 80px;
    }

}

.tracking-consent {
    margin-left: 3%;
    margin-right: 3%;

    p {
        font-size: 16px;
        letter-spacing: 0.02em;
        line-height: 28px;
        text-align: left;
        color: #797979;

    }
}

.modal-dialog-cookie {
    max-width: 750px !important;
    @include media-breakpoint-only(md){
        max-width: 605px !important;
    }
    }

.decline, .affirm {
    text-transform: uppercase;
    @include media-breakpoint-up(md){
        font-size: 20px !important;
        width: 325px !important;
        font-weight: 700 !important;
    }
    @include media-breakpoint-down(md){
        font-size: 16px !important;
    }
    @include media-breakpoint-down(sm){
        width: 280px !important;
        padding-top: rem(20) !important;
        padding-bottom: rem(20) !important;
        font-weight: 700 !important;
    }
}

.decline {
    @include media-breakpoint-up(lg){
        margin-left: rem(40) !important;
    }
}
.modal-cookie {
    @include media-breakpoint-up(md){
        padding-top: 15%;
    }
    @include media-breakpoint-down(sm){
        padding-right: 0px !important;
    }
}

.button-wrapper-cookie {
    @include media-breakpoint-up(lg){
        padding-top: rem(45);
        padding-bottom: rem(40);
    }
    @include media-breakpoint-only(md){
        text-align: center;
        padding-top: rem(20);
        padding-bottom: rem(20);
    }
    @include media-breakpoint-down(sm) {
        text-align: center;
        margin-bottom: 16px;
    }
}

.modal-body-cookie {
    @include media-breakpoint-up(md){
        padding-left: 20px;
    }
}