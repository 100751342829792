@import "../setup/settings";
@import "quickPdp";

body .product-detail {
  &.product-wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    .primary-images {
      z-index:0;
    }
    .product-information{
      z-index:1;
    }
  }
  &:not(.product-quickview) {
    .primary-images {
      padding-right: 0;
      overflow:hidden;
      @include media-breakpoint-down(md) {
        padding-top: rem(40);
        padding-bottom: rem(70);
        padding-right: rem(15);
        position: relative;
        @include media-breakpoint-down(sm) {
          padding-right: 0;
        }
        &:after {
          content: "";
          position: absolute;
          width: calc(100% + 15px);
          height: 100%;
          background-color: #f3f3f3;
          top: 0;
          left: rem(-10);
          z-index: -1;
          @include media-breakpoint-down(sm) {
            left: rem(-15);
            width: calc(100% + 30px);
          }
        }
      }
      .carousel.slide {
        margin-bottom:2rem;
        @include media-breakpoint-down(md) {
          max-width: 70%;
          margin: 0 auto;
        }
        @include media-breakpoint-down(sm) {
          max-width: rem(273);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @include media-breakpoint-down(md) {
          @include wishlist-block;
          .wishlist-block {
            margin-top: 0;
            position: absolute;
            bottom: rem(-37);
            width: rem(17);
            height: rem(17);
            right: -14%;
            @include media-breakpoint-down(sm) {
              bottom: rem(-48);
              right: -12%;
            }
            button {
              &.cc-wishlist-add {
                width: rem(17);
                height: rem(17);
                padding: 0;
                top: rem(-9);
                &:before {
                  left: 0;
                }
                span {
                  display: none;
                }
              }
            }
          }
        }
        .carousel-indicators {
          @include media-breakpoint-down(md) {
            bottom: rem(-50);
          }
          @include media-breakpoint-down(sm) {
            bottom: rem(-62);
          }
        }
        @include carousel-controls;
        .carousel-control-prev {
          @include media-breakpoint-down(md) {
            left: -16%;
            .icon-prev {
              background-color: transparent;
            }
          }
          @include media-breakpoint-down(sm) {
            left: -17%;
          }
        }
        .carousel-control-next {
          @include media-breakpoint-down(md) {
            right: -16%;
            .icon-next {
              background-color: transparent;
            }
          }
          @include media-breakpoint-down(sm) {
            right: -17%;
          }
        }
      }
      & + div {
        padding-left: rem(90);
        @include media-breakpoint-down(xl) {
          padding-left: rem(40);
        }
        @include media-breakpoint-down(lg) {
          padding-left: rem(23);
        }
        @include media-breakpoint-down(md) {
          padding-top: rem(30);
          padding-left: rem(15);
        }
        @include on-sale;
        .on-sale-wrapper {
          height: auto;
          margin-bottom: rem(41);
          display:table;
          @include media-breakpoint-down(lg) {
            margin-bottom: rem(20);
          }
          @include media-breakpoint-down(md) {
            margin-bottom: rem(40);
          }
          &.short {
            margin-bottom: 0;
          }
          .on-sale {
            top: 0;
            right: 0;
            bottom: auto;
            @include media-breakpoint-down(md) {
              right: 50%;
              transform: translateX(50%);
            }
          }
        }
        .brand-block {
          display:none;
          height:auto;
          .col {
            a {
              @include brand-style;
              font-weight: 400;
              font-size: .75rem;
              letter-spacing: 0.05em;
              line-height: 3;
              font-family: "notosans-light",serif;
            }
          }
        }
        .product-name-block {
          .col {
            h1 {
              @include product-name;
              font-size: 2.125rem;
              margin-bottom: rem(17);
              font-weight:400 !important;
              font-family: notosans-regular,serif;
              @include media-breakpoint-down(lg) {
                margin-bottom: rem(8);
              }
              @include media-breakpoint-down(md) {
                max-width: 100%;
                margin: 0 auto;
              }
              @include media-breakpoint-down(sm) {
                font-size:1.3rem;
              }
              border:0px;
            }
          }
        }
        :not(.product-quickview) .primary-images+div .attributes .product-color span.js-label-color{
          
        }
        .product-color .js-label-color{font-weight:300;}
        .product-text-element p, 
        .description-block p{
          font-weight: 300;
        }
        .cc-product-description-details {
          margin-top: 0px;
          margin-bottom:15px;
        }
        .description-block,
        .description-block a,
        .description-block .cc-show-more {
          font-family: "NotoSans-Light", serif;
          font-size: rem(16);
          letter-spacing: 0.02em;
          line-height: rem(28);
          margin-bottom:0px;
          margin-top:0.5rem;
          @include media-breakpoint-down(lg) {
            font-size: rem(12);
            line-height: rem(20);
            margin-bottom: rem(25);
          }
          @include media-breakpoint-down(md) {
            margin-top: rem(15);
          }
        }
        @include prices-block;
        @include attributes-block;
        @include wishlist-block;
        .prices {
          @include price-range;
          @include media-breakpoint-down(md) {
            text-align: center;
            .price {
              > span {
                .strike-through {
                  margin-right: 0;
                }
              }
            }
          }
        }
        .prices-add-to-cart-actions {
          @include select-quantity;
          @include promotion-details;
          & .quantity{
            height: rem(73);
            width:auto;
          }
        }
        @include buy-button;
        .cc-button-add-to-cart {
          margin-left: 0;
        }
      }
    }
  }
  .priceValidUntil{
    border:1px solid $form-grey;
    display: inline-block;
    padding: 1px 6px;
    float: right;
    border-radius: 2px;
    font-size: 0.75rem !important;
    line-height: 23px;
    @include media-breakpoint-down(sm) {
      margin-top: rem(0);
      padding: 0px 6px;
      line-height: 1.4rem;
      span{
        font-size: .625rem;
        line-height: 1.5rem;
        font-style: italic;
        font-weight:300;
        color:#333;
      }
    }
    svg{
      width: 12px;
      margin-top: -3px;
    }
  }
  .price .h5.sales .value,
  .price del .value,
  .price .range .h5.sales,
  .price span .h5.sales{
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  }
  .prices .price>span .h5.sales {
    margin-bottom: 0rem !important;
  }

  .price del .value {
    text-decoration: none;
  }
  .price del .strike-through{
    width:100%;
  }
  .price .h5.sales .value span,
  .price del .value span,
  .price .range .h5.sales span,
  .price span .h5.sales span{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  }
  .price .h5.sales .value span:first-child,
  .price .h5.sales span span:first-child,
  .price .h5.sales span:first-child,
  .price .range .h5.sales .text{
    font-weight:bold;
    font-size: 18px;
  }
  .price .h5.sales.discount .value span,
  .price del .value span{
    font-weight:300;
    font-size:18px;
  }
  .price .h5.sales .value span:last-child,
  .price del .value span:nth-child(2),
  .price .range .h5.sales span:nth-child(2),
  .price span .h5.sales span:nth-child(2){
    text-align:right;
  }
  .price del .value span:nth-child(2){
    text-decoration: line-through;
  }
  .prices.member .price del .value span:nth-child(2){
    text-decoration: none;
  }
  @include media-breakpoint-down(lg) {
    .product-detail .h5.sales .value,
    .prices .price .range span.value,
    .price del .value{
        font-size: 1.625rem !important;
    }
  }
  :not(.product-quickview) .primary-images + div .prices {
    text-align: left;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    padding: 10px 0;
  }
  :not(.product-quickview) .primary-images+div .prices .price>span .strike-through{margin-bottom:0px;}
  .list.h5{margin-bottom:0px;}
   .h5.sales .text{
    margin-bottom:0px;
  }
  :not(.product-quickview) .primary-images+div .prices .price>span {
    display: block;
  }
  .product-options {
    margin-top: 0em;
  }
  :not(.product-quickview) .primary-images+div .prices {
    text-align: left;
  }
  .prices{
        margin: 10px 0;
  }
  .price del .value {
        text-decoration: line-through;
    font-size: 1.625rem;
    font-weight: 300;
  }
  .on-sale.h7.white{
    position:relative;
    display:inline;
    top: initial !important;
    bottom: initial !important;
    right:initial !important;
    left:initial !important;
  }
  .on-sale,
  .free-delivery-product{
      font-size: 0.75rem !important;
      padding: 0 6px;
      height:20px;
      border:0px;
    @include media-breakpoint-down(sm) {
      font-size:0.625rem !important;
      padding-top: 0.4rem !important;
      padding-bottom: 0.35rem !important;
    }
  }

  .lowprice{
    text-align: right;
    font-weight:300;
    color: $medium-grey;
    font-style: italic;
    font-size:0.8rem;
  }

  :not(.product-quickview) .primary-images+div .attributes .product-color span,
  .product-size span{
    font-size:18px;
    font-weight:bold;
  }
  .product-color .js-label-color{font-weight:300 !important;}
  :not(.product-quickview) .primary-images+div {
    padding-top: 0.9rem;
  }
  :not(.product-quickview) .primary-images+div .product-name-block .col h1 {
    letter-spacing: 0em;
    text-transform: inherit;
    margin-top: 0rem;
  }
  .product-color{
    margin-top:1rem;
  }
  .free-delivery-product{
    background:$light-grey;
    display: inline !important;
    margin-bottom: 0px;
    padding:0.5rem;
    line-height: 1;
    border-radius:3px;
  }
  .on-sale-wrapper .on-sale{
    position: relative;
    display: inline-block;
    padding:0.5rem;
    border-radius:3px;
  }
  :not(.product-quickview) .primary-images+div .on-sale-wrapper {
    margin-bottom: 0;
  }
  .product-breadcrumb .breadcrumb {
    margin: 10px 0 !important;
  }
  @include media-breakpoint-down(sm) {
    .container .breadcrumb .breadcrumb-item a {
      font-size: .6rem;
    }
  }
  
  /* Amount buy button */
  :not(.product-quickview) .primary-images+div .prices-add-to-cart-actions .quantity .quantity-input{
    border:1px solid $form-grey;
    background:#f9f9f9;
  }
  
  .quantity-subtract{
    border-right:1px solid $form-grey;
    padding: 15px 19px 15px 23px !important;
    @include media-breakpoint-down(md) {
      padding: 15px 14px 15px 14px !important;
    }
  }
  .quantity-add{
    border-left:1px solid $form-grey;
    padding: 15px 23px 15px 15px !important;
    @include media-breakpoint-down(md) {
      padding: 15px 14px 15px 14px !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .quantity-add {
        padding: 15px 8px 15px 12px !important;
    }
  }
  /* Stock status */
  .inventory-instock{
    text-align:right;
    margin:15px 0 0 0;
    font-size:14px;
  }
  .inventory-instock .inventory-instock-label svg{
    color: green;
    width: 9px;
    margin-top: -3px;
    margin-right: 3px;
  }
  .inventory-instock label{
    margin-bottom: 0px;
  }
  
  .inventory-outstock{
    text-align:right;
    margin:15px 0 0 0;
    font-size:14px;
  }
  .inventory-outstock .inventory-outstock-label svg{
    color: $mexican-red;
    width: 9px;
    margin-top: -3px;
    margin-right: 3px;
  }
  .inventory-outstock label{
    margin-bottom: 0px;
  }
  
  /* Choose variant */
  .attributes .attribute{
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important;
  }
  .attribute .color-attribute{
    border:1px solid #eaeaea;
    border-radius:100%;
    margin-right: 0.1em !important;
  }
  :not(.product-quickview) .primary-images+div .attributes .color-attribute.selected:before {
    border: 1px solid $medium-grey;
    border-radius: 100%;
  }
}


.cc-info-product {
  margin-top: 15px;
  .cc-info {
    margin-bottom: rem(20);
  }
  .icons {
    flex: 0 0 35px;
    align-self: flex-end;
    @include media-breakpoint-down(lg) {
      flex: 0 0 30px;
    }
    i {
      display: inline-block;
      background-repeat: no-repeat;
    }
  }
  .findinstore {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.351' height='16.744'><path data-name='Tracciato 178' d='M14.351 16.744H8.979v-5.238H5.371v5.238H0V6.378L7.176 0l7.175 6.378zm-4.49-.882h3.608V6.774L7.176 1.18.882 6.774v9.088H4.49v-5.238h5.371z' fill='%232d2d2d'/%3E%3C/svg%3E");
    width: rem(21);
    height: rem(16);
  }
  .truck {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.519' height='15.905'%3E%3Cpath data-name='Tracciato 98' d='M21.519 6.68l-2.9-.968-1.9-5.711H7.494v13.1h3.78a3.27 3.27 0 006.475 0h3.779zm-7.017 8.29a2.339 2.339 0 112.339-2.339 2.342 2.342 0 01-2.339 2.339zm6.081-2.807h-2.844a3.27 3.27 0 00-6.475 0H8.42V.936h7.616l1.838 5.516 2.709.9zm-8.42-6.549h2.807v.936h-3.744V2.808h.936zM0 0h5.614v.936H0zm1.871 3.742h3.742v.936H1.871zm1.871 3.742h1.871v.936H3.742z' fill='%232d2d2d'/%3E%3C/svg%3E");
    width: rem(21);
    height: rem(16);
  }
  .certified {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.926' height='14.278'%3E%3Cpath data-name='Tracciato 103' d='M3.006 6.6H.266a.268.268 0 01-.229-.405 12.342 12.342 0 013.27-3.4h.007A12.686 12.686 0 018.84.303a16.186 16.186 0 019.506 1.022s3.911 1.949 5.545 4.878a.266.266 0 01-.235.392h-1.171a1.068 1.068 0 01-.922-.53 8.989 8.989 0 00-2.662-2.766 11.051 11.051 0 00-7.22-1.635 11.34 11.34 0 00-4.59 1.484h-.007a10.555 10.555 0 00-3.159 3 1.176 1.176 0 01-.919.452zm17.911 1.079h2.74a.268.268 0 01.229.405 12.376 12.376 0 01-3.263 3.4.006.006 0 00-.007.007 12.627 12.627 0 01-5.526 2.492 16.186 16.186 0 01-9.508-1.027S1.671 11.007.037 8.078a.265.265 0 01.235-.392h1.174a1.068 1.068 0 01.922.53 8.989 8.989 0 002.662 2.766 11.05 11.05 0 007.219 1.635 11.341 11.341 0 004.591-1.484.006.006 0 00.007-.007 10.606 10.606 0 003.152-3 1.156 1.156 0 01.918-.451zm-6.173.621a.937.937 0 00-.772.405 2.361 2.361 0 01-1.236.929 2.493 2.493 0 01-2.622-.726l5.715-2.563a.485.485 0 00.248-.6 4.341 4.341 0 10.085 2.858.238.238 0 00-.229-.307zm-5.2-.981a2.438 2.438 0 014.315-1.556L9.576 7.692a1.72 1.72 0 01-.037-.377z' fill='%23007495'/%3E%3C/svg%3E");
    width: rem(24);
    height: rem(14);
  }
  .orders {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.696' height='18.133'%3E%3Cpath data-name='Tracciato 179' d='M8.986 0L-.003 5.393v6.483l10.725 6.256 8.971-6.279V6.246zm.005 1l9.433 5.5-3.444 2.066-9.432-5.5zm1.707 10.137l-9.432-5.5 3.443-2.066 9.432 5.5zM.853 6.386l9.42 5.495v5l-9.42-5.495zm10.276 10.421v-4.93l7.707-4.624v4.159z' fill='%232d2d2d'/%3E%3C/svg%3E");
    width: rem(19);
    height: rem(18);
  }
  .returns {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.074' height='18.073'%3E%3Cpath data-name='Tracciato 102' d='M18.074 3.975l-3.973 3.973-.6-.6 2.954-2.954h-10.8a4.647 4.647 0 00-4.642 4.642v.844H.169v-.844A5.493 5.493 0 015.655 3.55h10.8L13.501.596l.6-.6zm-1.019 4.22v.844a4.647 4.647 0 01-4.642 4.642h-10.8l2.954-2.954-.6-.6-3.973 3.972 3.973 3.973.6-.6-2.954-2.954h10.8a5.492 5.492 0 005.481-5.482v-.844z' fill='%232d2d2d'/%3E%3C/svg%3E");
    width: rem(18);
    height: rem(18);
  }
}

.product-detail {
  .product-wrapper {
    .form-control.select-size {
      @include media-breakpoint-down(lg) {
        background-position-x: 91%;
      }
    }
  }
}

.cc-product-description-details {
  margin-top: 0px;
  margin-bottom: 20px;
  border-top: 1px solid $form-grey;
  border-bottom: 1px solid $form-grey;
  overflow: hidden;
  .cc-product-description {
    border-right: 1px solid $form-grey;
    padding-left: rem(15);
    padding-top: rem(44);
    padding-bottom: rem(65);
    padding-right: 12%;
    @include media-breakpoint-down(xl) {
      padding-left: 0;
      padding-right: 5%;
    }
    @include media-breakpoint-down(lg) {
      border-right: none;
      padding-right: 0;
    }
  }
  .cc-product-detail {
    padding-left: rem(90);
    padding-top: rem(44);
    padding-bottom: rem(44);
    a{
      line-height: inherit !important;
    }
    > .row {
      margin-bottom: rem(15);
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .cc-product-description,
  .cc-product-detail {
    font-size: rem(16);
    letter-spacing: 0.02em;
    line-height: rem(28);
    color: $main-color;
    @include media-breakpoint-down(lg) {
      padding: 0;
    }
    h2 {
      font-size: rem(22);
      letter-spacing: 0.02em;
      line-height: rem(28);
      color: $black;
      margin-bottom: rem(23);
      text-transform: uppercase;
      @include media-breakpoint-down(lg) {
        font-size: .875rem;
        text-transform: none;
        line-height: rem(24);
        margin-bottom: 0;
      }
      & ~ .row {
        line-height: 1;
        .col-5,
        .col-7 {
          margin-bottom: rem(23);
        }
        &:last-of-type {
          .col-5,
          .col-7 {
            margin-bottom: 0;
          }
        }
      }
    }
    #accordion-product-info,
    #accordion-product-detail {
      @include media-breakpoint-down(lg) {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: calc(100% + 40px);
          height: 1px;
          background-color: $form-grey;
          bottom: 0;
          left: rem(-20);
        }
        h4.card-header {
          padding-top: rem(28);
          padding-bottom: rem(28);
          padding-left: rem(5);
          padding-right: rem(5);
          a {
            position: relative;
            display: block;
            &.collapsed {
              &:after {
                transform: translateY(-50%);
              }
            }
            &:after {
              content: "";
              position: absolute;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.625' height='12.203'%3E%3Cpath data-name='Tracciato 104' d='M10.813 12.203L.288 1.678a.983.983 0 111.39-1.39l9.135 9.134L19.948.288a.983.983 0 111.39 1.39z' fill='%23939393'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              width: rem(21);
              height: rem(12);
              top: 50%;
              right: rem(11);
              transform: translateY(-50%) rotate(180deg);
              transform-origin: center 48%;
            }
          }
        }
        .card-block {
          padding-left: rem(5);
          padding-right: rem(15);
          padding-bottom: rem(25);
          font-size: rem(14);
          line-height: rem(24);
        }
      }
    }
    #accordion-product-detail {
      @include media-breakpoint-down(lg) {
        &:after {
          display: none;
        }
      }
    }
  }
}

.product-detail {
  &.product-wrapper {
    @include media-breakpoint-down(sm) {
      .cc-image-product-mobile {
        & ~ .on-sale-wrapper {
          position: relative;
          width: 100%;
          height: rem(58);
          margin-bottom: rem(30);
          &.short {
            height: rem(30);
            margin-bottom: 0;
          }
          .on-sale {
            top: auto;
            left: 50%;
            right: auto;
            bottom: 0;
            padding-top: rem(9);
            padding-bottom: rem(9);
            transform: translateX(-50%);
            font-size: rem(10);
          }
        }
        & ~ .cc-product-brand {
          .col {
            a {
              h1 {
                @include brand-style;
                font-size: rem(12);
                letter-spacing: 0.05em;
                line-height: 1;
              }
            }
          }
        }
        & ~ .cc-product-name {
          .col {
            .product-name {
              @include product-name;
              margin-top: 0;
              margin-bottom: rem(22);
              font-weight: 400;
              font-size: 22px;
              letter-spacing: 0.1em;
              line-height: rem(26) !important;
            }
          }
        }
        & ~ .cc-prices {
          @include prices-block;
          .prices {
            text-align: center;
            .price {
              > span {
                .strike-through {
                  margin-right: 0;
                }
              }
            }
          }
        }
        & ~ .cc-attributes {
          padding-top: rem(8);
          @include attributes-block;
          select.form-control.select-size {
            font-size: rem(14);
            height: rem(60);
            padding-left: rem(23);
            padding-right: rem(23);
            background-size: rem(15);
          }
          select.form-control.quantity {
            height: rem(60);
          }
        }
        & ~ .cc-add-to-cart {
          .quantity {
            padding-right: 0;
            height: rem(60);
            select.form-control.quantity {
              height: rem(60);
              padding-left: 0;
              font-size: rem(14);
              text-indent: 45%;
              text-align-last: auto;
            }
          }
          .buy-button {
            padding-top: rem(15);
            padding-left: 0;
            padding-bottom: 0;
            .cc-button-add-to-cart {
              margin-left: 0;
              button {
                padding: 0;
                margin: 0;
                width: 100%;
                height: rem(60);
                line-height: 1;

                @include media-breakpoint-down(sm) {
                  width: calc(100% - 15px);
                  margin-left: rem(15);
                }

                &:disabled {
                  background: $soldout-grey !important;
                  border-color: $soldout-grey !important;
                }
                i {
                  display: none;
                }
              }
            }
          }
        }
        & ~ .cc-promotions {
          @include promotion-details;
        }
        & ~ .cc-info-product {
          .cc-desktop-info {
            margin-bottom: 0;
          }
        }
        & ~ #accordion-product {
          width: calc(100% + 30px);
          transform: translateX(-15px);
          margin-top: rem(45);
          border-bottom: 1px solid $form-grey;
          .card {
            margin-bottom: 0;
            border: none;
            border-top: 1px solid $form-grey;
            .card-header {
              border-radius: 0;
              border: none;
              padding-top: rem(17);
              padding-bottom: rem(17);
              a {
                position: relative;
                display: block;
                &.collapsed {
                  &:after {
                    transform: translateY(-50%);
                  }
                }
                &:after {
                  content: "";
                  position: absolute;
                  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.625' height='9.203'%3E%3Cpath data-name='Tracciato 104' d='M7.813 9.203l-7.6-7.938a.764.764 0 010-1.048.689.689 0 011 0l6.6 6.889 6.6-6.889a.689.689 0 011 0 .764.764 0 010 1.048z' fill='%23333'/%3E%3C/svg%3E");
                  background-repeat: no-repeat;
                  width: rem(15);
                  height: rem(9);
                  top: 50%;
                  right: rem(2);
                  transform: translateY(-50%) rotate(180deg);
                  transform-origin: center 48%;
                }
                h2 {
                  font-size: rem(14);
                  letter-spacing: 0.02em;
                  line-height: 1;
                  color: $black;
                  margin-bottom: 0;
                }
              }
            }
            .card-block {
              padding-left: rem(20);
              padding-right: rem(20);
              padding-bottom: rem(15);
              font-size: rem(11);
              letter-spacing: 0.02em;
              line-height: rem(24);
              color: $black;
            }
          }
        }
        & ~ .recommendations {
          width: calc(100% + 30px);
          transform: translateX(-15px);
          overflow-x: hidden;
          .cc-title-recommendations {
            text-transform: uppercase;
            text-align: left;
            padding-left: rem(20);
            padding-right: rem(20);
            font-size: rem(16);
          }
          .pdp-product-listing.product-grid {
            padding-left: rem(10);
            padding-right: rem(10);
          }
          .product-tile .tile-body {
            .brand-box a {
            } 
            .price .h5 {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.recommendations {
  margin-top: rem(50);
  margin-bottom: rem(40);
  @media screen and (max-width: 1919px) {
    margin-top: rem(30);
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: rem(30);
  }
  @include media-breakpoint-down(lg) {
    margin-top: rem(25);
  }
  @include media-breakpoint-down(sm) {
    margin-top: rem(20);
  }
  .cc-title-recommendations{
    display:none;
  }
  &:has(.pdp-product-listing) .cc-title-recommendations {
    display:block;
  }
  &.container {
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(xxl) {
      max-width: 1602px;
    }
    @media screen and (max-width: 1919px) {
      max-width: 97%;
    }
    @media screen and (max-width: 1365px) {
      max-width: 100%;
      overflow-x: hidden;
    }
    .cc-title-recommendations {
      text-transform: uppercase;
      font-size: rem(22);
      text-align: left;
      margin-bottom: rem(44);
      @media screen and (max-width: 1365px) {
        padding-left: rem(20);
        padding-right: rem(20);
      }
      @media screen and (max-width: 1024px) {
        font-size: rem(18);
        margin-bottom: rem(25);
      }
    }
    .product-grid {
      @media screen and (max-width: 1365px) {
        width: calc(100vw - 30px);
        margin: 0 auto;
      }
      > .col-sm-4 {
        .product {
          max-width: rem(526);
          @media screen and (max-width: 1024px) {
            max-width: rem(315);
          }
          .product-tile {
            .image-container {
              a {
                .tile-image {
                  max-width: rem(526);
                  max-height: rem(526);
                  @media screen and (max-width: 1024px) {
                    max-width: rem(315);
                  }
                }
              }
            }
            .on-sale {
              top: rem(17);
              left: rem(18);
              &.promo-banner {
                background: $mexican-red;
                color: $white;
                left: auto;
                right: rem(13);
                &.special {
                  max-width: 55%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
          .tile-body {
            @include price-range;
          }
        }
      }
    }
    .product-tile .tile-body {
      .brand-box a {
        font-family: "NotoSans-Regular", sans-serif;
      }
      .price .h5 {
        font-weight: 400;
      }
    }
  }
}

.reccomendations {
  margin-top: 5px;
  .reccomendations-title{
    font-size:18px;
    font-weight:bold;
  }
  .reccomendations-list {
    position: relative;
    text-align: left;
  }
  .reccomendations-list a{
    border:0px !important;
    color:#333;
    line-height: 1.35rem;
    @include media-breakpoint-down(sm) {
      font-size:12px;
      line-height: 18px;
    }
  }
  .reccomendations-list a:hover{
    text-decoration: underline;
  }
  .reccomendations-list label {
    transition: all 0.5s ease;
  }
  .reccomendations-list label:hover {
    background:#eeeeee;
  }
  .reccomendations-list label:before, .reccomendations-list label:after {
    content: '';
    position: absolute;
    top: 50%;
    border-radius: 50%;
  }
  .reccomendations-list label:before {
    left: 11px;
    width: 18px;
    height: 18px;
    margin: -8px 0 0;
    background: #fff;
    border:1px solid #d3d3d3;
  }
  .reccomendations-list label:after {
    left: 14px;
    width: 12px;
    height: 12px;
    margin: -5px 0 0;
    opacity: 0;
    background: $akaroa;
    -webkit-transform: translate3d(0px, 0, 0) scale(0.5);
            transform: translate3d(0px, 0, 0) scale(0.5);
    -webkit-transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  }
  .reccomendations-list input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: -9999px;
    visibility: hidden;
  }
  .reccomendations-list input[type="checkbox"]:checked + label:after {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  .reccomendations-list label:before, .reccomendations-list label:after {
    border-radius: 0;
  }
  .reccomendations-list__item-size{
    color:$grey4;
    font-style: italic;
    font-size: 0.9em;
    margin-bottom:2px;
    @include media-breakpoint-down(sm) {
      line-height: 0.95em;
    }
  }
}

.add-to-wishlist-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1060;
  @include media-breakpoint-down(sm) {
    width: 85%;
  }
}

.add-to-wishlist-alert {
  animation: fade 5s linear forwards;
  box-shadow: none;
  font-weight: 600;
  font-size: rem(16);
  line-height: 1;
  padding: rem(40);

  @include media-breakpoint-down(sm) {
    font-size: rem(16);
    font-weight: 400;
    line-height: rem(22);
    padding: rem(15);
  }

  &.alert-success {
    background-color: $main-color;
    color: $white;
    position: relative;
    padding-left: rem(60);
    @include media-breakpoint-down(sm) {
      padding-left: rem(65);
      text-align: left !important;
    }
    &:before {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg data-name='Componente 23 – 62' xmlns='http://www.w3.org/2000/svg' width='25.793' height='25.176'%3E%3Cpath data-name='Tracciato 111' d='M18.431 0a7.3 7.3 0 00-5.534 2.537A7.3 7.3 0 007.363 0 7.413 7.413 0 00-.001 7.445c0 6.979 11.932 16.932 12.44 17.353l.458.378.457-.378c.509-.42 12.44-10.374 12.44-17.353A7.413 7.413 0 0018.431 0zm0 1.461a5.958 5.958 0 015.918 5.984c0 5.58-9.293 13.96-11.451 15.831C10.739 21.405 1.446 13.027 1.446 7.445a5.958 5.958 0 015.918-5.984 5.893 5.893 0 014.932 2.68l.6.916.6-.916a5.891 5.891 0 014.935-2.68z' fill='%23fff'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      position: absolute;
      width: rem(26);
      height: rem(26);
      top: 50%;
      left: rem(23);
      transform: translateY(-48%);
    }
  }
  &.alert-danger {
    color: $mexican-red;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &.show {
    display: block;
  }
}

.wishlistTile {
  i {
    &.fa-heart-o {
      color: rgba(0, 0, 0, 0.7);
    }

    &.fa-circle {
      color: rgba(255, 255, 255, 0.7);
      text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    }
  }

  span {
    &.fa-stack {
      position: absolute;
      top: 8px;
      right: 16px;
    }
  }
}

.wishlistTile {
  .fa-heart {
    color: black;
  }
}

.cc-pdp-trustpilot {
  transform: scale(0.5);
  transform-origin: center;
  margin-left: 20%;
  margin-top: -5%;
}

.row[data-attr="size"] {
  .attribute {
    position: relative;
    .product-msg-availability {
      position: absolute;
      top: 50%;
      text-transform: uppercase;
      color: $mexican-red;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
      transform: translateY(-50%);
      line-height: 1;
      @include media-breakpoint-down(lg) {
        font-size: rem(14);
      }
    }
  }
}

.product-msg-availability {
  & + select {
    position: relative;
    z-index: 1;
  }
}

.primary-image-pdp {
  background-color: #f3f3f3;
  width: 100%;
  height: 100%;
}

.filter-option-inner-inner {
  text-transform: initial !important;
  padding: 5px 0;
}

.text-description{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

/* USP */
.usp-product {
  border-top:1px solid $form-grey;
  border-bottom:1px solid $form-grey;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
  column-gap: 25px;
  row-gap: 25px;
}
.usp-product-item {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: left;
  @include media-breakpoint-down(lg) {
    font-size: 12px;
  }
  line-height: 16px;
  height: 80px;
}
.cc-usp-product.trust .usp-product{
  @include media-breakpoint-down(sm) {
    row-gap: 0px;
    .usp-product-item:first-child{
      border-bottom:1px solid $form-grey;
    }
  }
}
.cc-usp-product.trust .usp-product{
  grid-template-columns: repeat(2, 1fr);
  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
    border:0px;
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.cc-usp-product.trust .usp-product .usp-product-item{
  padding: 0.8rem 0.2rem 0.5rem 0.5rem;
  font-size: 12px;
  @include media-breakpoint-down(md) {
    height: auto;
  }
  @include media-breakpoint-down(lg) {
    padding: 0.8rem 0rem 0.5rem 0rem;
  }
}
.cc-usp-product.single .usp-product-item,
.cc-usp-product.trust-add .usp-product-item{
  padding: 0.6rem 0rem;
  font-size:13px;
  @include media-breakpoint-down(md) {
    display: inherit !important;
    text-align: center !important;
    font-size:11px;
    line-height:13px;
    padding: 0.5rem 0rem;
    img{
        margin:0 auto 0px auto !important;
    }
  }
}
.usp-product-item::after{
  content: "";
  border-right:1px solid $form-grey;
  display:block;
  width:1px;
  height:60px;
  position: absolute;
  top:10px;
  right:-13px;
}
.usp-product-item:last-child:after{ border:0px; }
.cc-usp-product.trust .usp-product-item::after{
  @include media-breakpoint-down(md) {
    height:70px;
  }
  @include media-breakpoint-down(sm) {
    border:0px;
  }
}

/* SPECS */
.cc-product-description-details {
  border: 0px;
  @media screen and (min-width: 1366px) {
    .cc-product-description #accordion-product-info,
    .cc-product-detail #accordion-product-detail{
        display: block !important;
    }
    #heading-product-info a, #heading-product-detail a{
        cursor:default;
        padding: 0px;
        display: inherit !important;
    }
    .collapse:not(.show) {
      display: block !important;
    }
  } 
}
.cc-product-specs{
  display: grid;
  grid-template-columns: 40% 60%;
  grid-column-gap: 25px;
  column-gap: 25px;
  row-gap: 25px;
  margin: 0px;
  box-sizing: inherit;
  padding-right: 25px;
  @include media-breakpoint-down(lg) {
    padding-right: 0px;
    row-gap: 0px;
  }
  h4.card-header{
    padding: 0px !important;
    a{
      padding-top: 0.875rem !important;
      padding-bottom: 0.875rem !important;
      padding-left: 0.3125rem;
      padding-right: 0.3125rem;
    }
  }
  h2{
    font-weight:bold !important;
    @include media-breakpoint-down(lg) {
      margin-bottom:0px !important;
    }
  }
  .cc-product-description,
  .cc-product-detail{
    background: $light-gray;
    display: flex;
    justify-content: left;
    padding: 4rem;
    text-align: left;
    box-sizing: inherit;
    border:0px !important;
    @include media-breakpoint-down(lg) {
      padding: 0;
      background:transparent;
      border-top:1px solid $form-grey !important;
    }
    .row{
      border-bottom:1px solid #eaeaea;
      margin-bottom: 1rem;
    }
    .row div{
      margin-bottom: 1rem !important;
    }
    .row:last-child{
      border-bottom:0px;
    }
    .d-none{
      width:100%;
    }
  }
  @include media-breakpoint-down(lg) {
    .cc-product-detail{
      border-bottom:1px solid $form-grey !important;
    }
  }
}
@include media-breakpoint-down(lg) {
  .cc-product-description-details .cc-product-detail #accordion-product-detail .card-block {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 15px;
  }
}
#accordion-product-detail,
#accordion-product-info{
  width:100%;
}
.cc-product-description-details .cc-product-description #accordion-product-info:after{height:0px;}
@include media-breakpoint-down(lg) {
  .cc-product-specs{
    display: grid;
    grid-template-columns: 100%;
  }
  .cc-product-description{order: 1;}
  .cc-product-detail{order: 2;}
}

/* Reecommendations */
.recommendations{
  &.container{
    max-width: 100%;
  }
  .cc-title-recommendations{
    padding:5px !important;
    font-weight: bold;
    margin-bottom: 2rem !important;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0.5rem !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .pdp-product-listing.product-grid .col-6.col-sm-4:nth-child(5n+5){
      display:none !important;
    }
  }
}

/* GALLERY PDP */
#thumbnail-slider .splide__slide img {
  width: 100%;
  height: auto;
}

#thumbnail-slider{
  position: absolute;
  bottom: 0px;
  top: 0px;
  overflow: hidden;
  width: 90%;
}

#thumbnail-slider .splide__track{
  height: 100%!important;
}

.splide-container {
    display: flex; 
    flex-direction: row;
    width:100%;
    @include media-breakpoint-up(md) {
      margin-bottom: 15px;
    }
}

.splide-item-left{width:20%; padding-right:15px;}
.splide-item-right{width:80%; position: relative;}

@media only screen and (max-width: 1024px) {
  #thumbnail-slider{
    display:none;
  }
  .product-detail:not(.product-quickview) .primary-images {
    padding-top: 0rem;
    padding-bottom: 0px !important;
    padding-left:0px;
    padding-right:0px;
    background: #fff;
  }
  .splide-item-left{display:none;}
  .splide-item-right{width:100%;} 
  .splide-item-right .splide__slide img {
    width: 100%;
    height: auto;
  }
}

.splide__arrow{
  background:$light-gray !important;
}
.splide__arrow svg {
  position: absolute;
  height: 40px !important;
  width: 40px !important;
  padding-top: 10px;
}
.splide__arrow.splide__arrow--prev svg{
  right: 2px;
}
.splide__arrow.splide__arrow--next svg{
  left: 2px;
}

#thumbnail-slider li.splide__slide{
  background:$light-gray;
}
#thumbnail-slider .splide__slide img {
  mix-blend-mode: multiply;
}

#thumbnail-slider .splide__arrow.splide__arrow--prev svg{
  right: -4px;
  top: -10px;
}
#thumbnail-slider .splide__arrow.splide__arrow--next svg{
  left: -4px;
  bottom:-10px;
}

.splide__track--nav>.splide__list>.splide__slide.is-active {
  border: 1px solid $form-grey !important;
  padding:5px;
}

  #main-slider li.splide__slide{
  background:$light-gray;
}

.splide__pagination__page.is-active {
  background: #333 !important;
}
#main-slider .splide__slide img {
  mix-blend-mode: multiply;
  width: 100%;
}

#main-slider .image360 iframe {
  mix-blend-mode: multiply;
  width: 100%;
  height:100%;
}

button:focus {
  outline: none !important;
}

.splide-item-left{
  position: relative;
}
.splide_fade_bottom{
  position: absolute;
  top:0px;
  left:0px;
  right:0px;
  z-index:1;
  height:100px;
  pointer-events: none;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
.splide_fade_top{
  position: absolute;
  bottom:0px;
  left:0px;
  right:0px;
  z-index:1;
  height:100px;
  pointer-events: none;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}

/* Pagination */
.splide__pagination__page {
  border-radius: 0 !important;
}

.splide-link{
  position:absolute;
  top:15px;
  left:20px;
  @include media-breakpoint-down(md) {
    top:5px;
    left:7px;
  }

  span{
    padding:6px 8px;
    font-size:12px;
    margin-right:5px;
    border:0px;
    background:$white;
    border:1px solid $white;
    display:inline-block;
    margin-top:5px;
    @include media-breakpoint-down(md) {
        font-size:11px;
        padding:4px 8px;
        display:inline-block;
    }
    &:hover{
      cursor:pointer;
      border:1px solid #d3d3d3;
    }
    &::before {
      position: relative;
      content: "\203A";
      padding-right: 0.2rem;
    }
  }
}

@include media-breakpoint-down(md) {
    .cc-wishlist-add {
        margin-left:inherit !important;
        border: 0px !important;
        background: transparent !important;
        span{
          display:none;
        }
        svg{
            fill:$main-color;
        }
    }
    .wishlist-block{
      position:absolute;
      top:10px; right:50px;
      
    }
}