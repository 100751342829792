@import "menu";

$banner-padding: 0.3125em;
$menu-padding: 0.5em;
$menu-item-margin: 0.625em;

.container {
  padding-left: rem(20);
  padding-right: rem(20);
  @include media-breakpoint-down(sm) {
    padding-left: rem(15);
    padding-right: rem(15);
  }
}

.header-hello-bar {
  height: rem(40);
  width: 100%;
  background-color: #D7CEB2;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 14px;
  font-size: 14px;
  font-weight: 300;
  z-index: 5;

  @include media-breakpoint-down(lg) {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
  }
  @include media-breakpoint-down(sm) {
      height: rem(30);
      font-size: 10px;
  }

  strong {
      font-weight: 700;
  }
  p {
      margin:0;
  }
}

header {
  position: relative;
  z-index: 4;
  background-color: $white;
}

.header {
  position: relative;
  background-color: $white;

  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    max-width: 100%;
    border-bottom: 1px solid $form-grey;
  }
  &.container{
    @include media-breakpoint-up(xl) {
        
    }        
    @include media-breakpoint-up(xl) {
        max-width: 1668px;
    }
}
  &.banner-in-page {
    @include media-breakpoint-down(lg) {
      top: rem(40);
    }
    @include media-breakpoint-down(sm) {
      top: rem(30);
    }
  }

  &.hellobar-in-page {
    @include media-breakpoint-down(lg) {
        top: rem(80);
    }
    @include media-breakpoint-down(sm) {
        top: rem(60);
    }
}
}

.navbar-header {
  height: 100%;
  padding-top: rem(15);
  padding-bottom: rem(15);
  @include media-breakpoint-down(xl) {
    padding-top: rem(15);
    padding-bottom: rem(15);
  }
  @include media-breakpoint-down(lg) {
    padding-top: rem(15);
    padding-bottom: rem(105);
  }
  @include media-breakpoint-down(md) {
    padding-top: rem(15);
    padding-bottom: rem(98);
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: rem(72);
  }

  .logo-home {
    display: block;
    border-bottom: none !important;
    .logo {
      width: 100%;
      max-width: rem(216);
    }
  }

  .user,
  .country-selector,
  .search,
  .minicart,
  .wishlist,
  .account {
    display: inline-block;
  }

  .search {
    width: 100%;
    .site-search {
      width: rem(570);
      margin-right: 0;
      @include media-breakpoint-down(xl) {
        width: rem(500);
      }
      @include media-breakpoint-down(lg) {
        width: rem(400);
      }
      form,
      .search-field {
        width: 100%;
        position: relative;
        z-index: 4;
        background-color: $search-grey;
        border-radius: 30px;
        border: 0px;
        font-size: rem(12);
        height: rem(34);
        margin-bottom: rem(18);
        @include media-breakpoint-up(md) {
          height: rem(51);
          font-size: rem(16);
        }
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }
      }
      .search-field ~ .fa-search {
        height: rem(34);
        @include media-breakpoint-up(md) {
          height: rem(51);
        }
      }
      input.search-field{
        border:1px solid;
    }
      .reset-button {
        width: rem(15);
        height: rem(15);
        right: rem(60);
        z-index: 5;
        @include media-breakpoint-down(sm) {
          right: rem(10) !important;
        }
        &:before {
          display: none;
        }
        &:after {
          content: "";
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.922' height='14.922'%3E%3Cpath data-name='Tracciato 577' d='M14.92.684L14.236 0 7.46 6.776.684 0l-.685.684L6.776 7.46l-6.777 6.776.685.684L7.46 8.144l6.776 6.776.684-.684L8.144 7.46z' fill='%232d2d2d'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          width: rem(15);
          height: rem(15);
          top: calc(50% + 1px);
          left: 0;
          transform: translateY(-50%);
          @include media-breakpoint-down(sm) {
            transform: scale(0.8);
            transform-origin: center right;
          }
        }
        &.d-sm-block {
          @include media-breakpoint-down(sm) {
            display: block !important;
            z-index: 6;
            width: rem(18);
            right: rem(17);
            background-color: $search-grey;
            height: 100%;
            &:after {
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
    &-for-mobile {
      @include media-breakpoint-down(lg) {
        transform: translateY(15px);
        .site-search {
          width: rem(430);
          margin: 0 auto;
        }
      }
      @include media-breakpoint-down(sm) {
        transform: translateY(10px);
        width: calc(100vw - 30px);
        .site-search {
          width: calc(100vw - 30px);
          margin: 0 auto;
        }
      }
    }
  }

  .account,
  .wishlist,
  .minicart-link {
    margin-top: 0;
  }

  .account {
    margin-left: rem(70);
    @include media-breakpoint-down(lg) {
      margin-left: rem(28);
    }
    @include media-breakpoint-down(sm) {
      margin-left: rem(13);
    }
    svg {
      width: rem(32);
      height: rem(32);
      @include media-breakpoint-down(sm) {
        width: rem(23);
        height: rem(23);
      }
    }
  }

  .wishlist {
    padding-top: rem(3);
    margin-left: rem(25);
    @include media-breakpoint-down(lg) {
      margin-left: rem(20);
    }
    @include media-breakpoint-down(sm) {
      transform: translateX(10px);
      z-index: 1;
    }
    svg {
      width: rem(35);
      height: rem(32);
      @include media-breakpoint-down(sm) {
        width: rem(26);
        height: rem(23);
      }
    }
  }

  .minicart-link {
    margin-left: rem(25);
    @include media-breakpoint-down(lg) {
      margin-left: rem(27);
    }
    @include media-breakpoint-down(sm) {
      margin-left: rem(13);
      transform: translateX(10px);
    }
    svg {
      width: rem(25);
      height: rem(32);
      @include media-breakpoint-down(sm) {
        width: rem(20);
        height: rem(26);
      }
    }
    .minicart-quantity {
      background-color: $akaroa;
      width: rem(16);
      height: rem(16);
      font-size: rem(9);
      line-height: rem(16);
      letter-spacing: rem(0.32);
      top: auto;
      bottom: rem(-12);
      left: rem(-11);
    }
  }

  .user,
  .country-selector,
  .minicart,
  .wishlist,
  .account,
  .navbar-toggler {
    line-height: 2.25em; /* 36/16 */
    height: auto;
  }

  .navbar-toggler {
    font-size: 1.6em;
    width: auto;

    @include media-breakpoint-down(lg) {
      padding: 0;
      line-height: 1;
      .hamburger {
        transform: translateY(2px);
        @include media-breakpoint-down(sm) {
          transform: translateY(1px) scale(1.2);
        }
        &:hover {
          cursor: pointer;
        }
        &.is-active {
          .line {
            &:nth-child(1) {
              transform: translateY(13px) rotate(45deg);
              @include media-breakpoint-down(sm) {
                transform: translateY(8px) rotate(45deg);
              }
            }
            &:nth-child(2) {
              opacity: 0;
            }
            &:nth-child(3) {
              transform: translateY(-13px) rotate(-45deg);
              @include media-breakpoint-down(sm) {
                transform: translateY(-8px) rotate(-45deg);
              }
            }
          }
        }
        .line {
          width: rem(30);
          height: rem(2);
          background-color: $black;
          display: block;
          margin-top: rem(11);
          margin-bottom: rem(11);
          transition: all 0.25s ease-in-out;
          &:nth-child(2) {
            width: rem(24);
          }
          @include media-breakpoint-down(sm) {
            width: rem(18);
            height: rem(1);
            margin-top: rem(7);
            margin-bottom: rem(3);
            &:nth-child(2) {
              width: rem(14);
            }
          }
        }
      }
    }
  }

  .user {
    position: relative;

    .popover {
      position: absolute;
      display: none;
      padding: 1em;
      top: 85%;
      left: 0;

      a {
        white-space: nowrap;
        margin-bottom: 0.5em;
      }

      &::before {
        left: 1.5rem;
      }

      &::after {
        left: 1.5rem;
      }

      &.show {
        display: block;
      }
    }
  }

  img.logo-desktop {
    width: rem(100);
    height: rem(26);
    margin: rem(12) 0;

    @include media-breakpoint-up(sm) {
      width: rem(141);
      height: rem(38);
      margin: rem(15) 0;
    }
    
    @include media-breakpoint-up(lg) {
      width: rem(155);
      height: rem(42);
    }
  }
}

.brand {
  position: absolute;
  left: 50%;
  display: block;
  text-align: center;
  height: 100%;

  img {
    width: 100%;
    @include media-breakpoint-down(lg) {
      max-width: rem(155);
    }
    @include media-breakpoint-down(sm) {
      max-width: rem(120);
    }
  }

  @include media-breakpoint-down(lg) {
    width: rem(570);
    z-index: 1;
    flex-direction: column;
    transform: translateX(-50%);
  }

  @include media-breakpoint-down(sm) {
    width: 50%;
  }
}

.brand-checkout {
  position: absolute;
  display: block;
  text-align: center;
  height: 100%;

  img {
    width: 100%;
  }

  @include media-breakpoint-down(lg) {
    z-index: 1;
    flex-direction: column;
    transform: translateX(-50%);
    left: 50%;
    width: 50%;
  }

  @include media-breakpoint-down(sm) {
    width: 40%;
    left: 55%;

    img {
      width: 60%;
    }
  }
}

.main-menu {

  .navbar .close-menu button,
  .navbar .close-button button {
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  .menu-wrapper{
    &.container{
        @include media-breakpoint-up(xl) {
            max-width: 1638px;
        }
    }
  }
}

.header-banner {
  height: rem(40);
  text-align: center;
  z-index: 5;
  background-color: $light-grey;
  @include media-breakpoint-down(xl) {
    position: relative;
  }
  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    height: rem(30);
  }

  .content {
    width: 100%;
    text-transform: uppercase;
    font-size: rem(11);
    line-height: rem(14);
    font-weight: normal;
    @include media-breakpoint-down(sm) {
      font-size: rem(10);
    }
    .header-promotions span {
      font-family: "NotoSans-Light" sans-serif;
      font-size: rem(12);
    }
    .padding-right {
      padding-right: 32px;
    }
  }

  &.hellobar-in-page {
    @include media-breakpoint-down(lg) {
        top: rem(40);
    }
    @include media-breakpoint-down(sm) {
        top: rem(30);
    }
  }

  .usp{
    overflow:hidden;
    position:relative; 
    height: 2.5rem;    
    text-align: center;
    @media only screen and (min-width: 768px) {
        .item {
            display: inline;
            margin: 0 15px;
            height: 2.5rem;
            top: 0px;
            width: 100%;
            line-height: 2.5rem;
            font-size: .6875rem;
            font-weight: 400;
            letter-spacing: .0275rem;
            color:#000;
            text-align:center;
        }
    }
    
    @media only screen and (max-width: 768px) {
        .item-1,
        .item-2,
        .item-3 {
            position: absolute;
            display: block;
            height: rem(30);
            top: 0px;
            left:0px;
            width: 100%;
            line-height: rem(30);
            font-size: .6875rem;
            font-weight: 400;
            letter-spacing: .0275rem;
            color:#000;
            text-align:center;
            animation-duration: 7s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }
    @media only screen and (max-width: 768px) {
        .item-1 {
            animation-name: anim-1;
        }
    
        .item-2 {
            animation-name: anim-2;
        }
    
        .item-3 {
            animation-name: anim-3;
        }
    
        @keyframes anim-1 {
          0%,
          8.3% {
            top: -100%;
            opacity: 0;
          }
          8.3%,
          25% {
            top: 0%;
            opacity: 1;
          }
          33.33%,
          100% {
            top: 110%;
            opacity: 0;
          }
        }
    
        @keyframes anim-2 {
          0%,
          33.33% {
            top: -100%;
            opacity: 0;
          }
          41.63%,
         58.29%  {
            top: 0%;
            opacity: 1;
          }
          66.66%,
          100% {
            top: 110%;
            opacity: 0;
          }
        }
    
        @keyframes anim-3 {
          0%,
          66.66% {
            top: -100%;
            opacity: 0;
          }
          74.96%,
          91.62% {
            top: 0%;
            opacity: 1;
          }
          100% {
            top: 110%;
            opacity: 0;
          }
        }
    }
  }
}

.minicart,
.wishlist {
  margin-top: 0.1875em; /* 3/16 */
  vertical-align: top;

  .minicart-icon {
    font-size: 1.5em;
  }

  a.minicart-link {
    &:hover {
      text-decoration: none;
    }
  }

  .minicart-quantity {
    background-color: #{var(--skin-primary-color-1)};
    border-radius: 50%;
    width: 1.25em; /* 20/16 */
    height: 1.25em; /* 20/16 */
    line-height: normal;
    display: inline-block;
    text-align: center;
    font-size: 0.8125em; /* 13/16 */
    position: relative;
    top: -0.9375em; /* 15/16 */
    left: -0.9375em; /* 15/16 */
    color: $white;
  }
}

a.normal {
  color: #{var(--skin-primary-color-1)};
  text-decoration: underline;
}

.dropdown-country-selector {
  margin-top: -0.0625em;
}

.cookie-warning-messaging.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  display: none;
}

.valid-cookie-warning {
  background-color: #{var(--skin-link-color-1)};
  color: $white;
  white-space: nowrap;

  p {
    margin-top: 0;
    margin-bottom: 0.2em;
    padding-right: 2em;
  }
}

.search-mobile {
  @include media-breakpoint-down(xs) {
    padding: 60px 20px 10px 20px;
  }
  @include media-breakpoint-only(sm) {
    padding: 60px 20px 10px 40%;
  }
}

.cc-header-pers {
  z-index: 1001;
  background-color: $white;
}

header {
  @include media-breakpoint-down(lg) {
    height: rem(165);
  }
  @include media-breakpoint-down(sm) {
    height: rem(134);
  }
  &.banner-in-page {
    @include media-breakpoint-down(lg) {
      height: rem(213);
    }
    @include media-breakpoint-down(md) {
      height: rem(206);
    }
    @include media-breakpoint-down(sm) {
      height: rem(156);
    }
  }
  &.hellobar-in-page {
    @include media-breakpoint-down(lg) {
        height: rem(253);
    }
    @include media-breakpoint-down(sm) {
        height: rem(186);
    }
}
  &.header-container-checkout {
    height: auto;
    @include media-breakpoint-down(lg) {
      height: rem(83);
    }
    @include media-breakpoint-down(sm) {
      height: rem(72);
    }
    .header {
      @include media-breakpoint-up(xxl) {
        padding-left: 0;
      }
      .brand-checkout {
        padding-top: rem(46);
        padding-bottom: rem(43);
        position: relative;
        @include media-breakpoint-down(lg) {
          padding-top: 0;
          padding-bottom: 0;
        }
        .logo-home {
          text-align: left;
          transform: none;
          @include media-breakpoint-down(lg) {
            text-align: center;
            padding-top: rem(36);
            padding-bottom: rem(36);
          }
          @include media-breakpoint-down(lg) {
            padding-top: rem(20);
            padding-bottom: rem(20);
          }
          img {
            width: 100%;
            max-width: rem(201);
            @include media-breakpoint-down(lg) {
              max-width: rem(155);
            }
            @include media-breakpoint-down(sm) {
              max-width: rem(120);
            }
          }
        }
      }
    }
  }
}

.logo-500 {
  padding-top: rem(80);
  padding-bottom: rem(80);
  @include media-breakpoint-down(lg) {
    padding-top: rem(35);
    padding-bottom: rem(50);
  }

  @include media-breakpoint-down(sm) {
    text-align: center;
    padding-top: rem(35);
    padding-bottom: rem(35);
  }

  img {
    width: 100%;
    @include media-breakpoint-down(sm) {
      max-width: rem(222) !important;
    }
  }
}
