$breakpoint-name: "lg";
$breakpoint-name: "lg" !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@mixin caret-left() {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-left: 0.3 solid transparent;
  width: 0;
  height: 0;
  display: inline-block;
  margin-bottom: 0.125em;
}

@mixin caret-right() {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  position: absolute;
  right: 0.3em;
  margin-top: 0.55em;
}

@each $size in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($size) {
    .menu-toggleable-left.navbar-toggleable-#{$size} {
      position: fixed;
      left: -100%;
      top: 0;
      transition: $slide-out-animation;
      display: block;
      padding: 0;
      //max-width: 100%;

      @include media-breakpoint-down(sm) {
        bottom: 0;
        border-bottom: none;
      }

      &.in {
        @include media-breakpoint-down(sm) {
          min-width: 100%;
        }
        left: 0;
      }
    }
  }
}

.navbar.bg-inverse {
  background-color: transparent !important;
  padding: 0;

  @include media-breakpoint-up($next-breakpoint) {
    .navbar-nav .nav-item + .nav-item {
      margin-left: 0;
    }

    .navbar-nav .nav-link {
      padding: 0.8rem;
      white-space: nowrap;
    }
  }
}

.navbar-expand-md .navbar-nav.nav-center {
  justify-content: center;
}

.navbar-expand-md .navbar-nav.nav-spaced {
  justify-content: space-evenly;
}

.navbar-expand-md .navbar-nav.nav-right {
  justify-content: end;
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  background-color: $white !important;
  color: $black;
}

@include media-breakpoint-up($next-breakpoint) {
  .nav-item > .nav-link {
    background-color: $ebony;
    color: $white;
  }
}

.main-menu.menu-toggleable-left {
  @include media-breakpoint-down($breakpoint-name) {
    z-index: 4;
  }
}

.menu-toggleable-left {
  .close-menu {
    padding: 15px;
    background-color: $close-menu-bg;
    border-bottom: 1px solid $grey3;
    flex: 0 0 100%;

    @include media-breakpoint-up($next-breakpoint) {
      display: none;
    }
  }

  .nav-item .nav-link {
    @include media-breakpoint-down(lg) {
      color: $white;
    }
  }

  .menu-group {
    flex: 0 0 100%;
    background-color: $white;
  }

  li > .close-menu {
    margin-right: 0;
    margin-top: -0.6rem;
    margin-left: 0;
  }

  @include media-breakpoint-down($breakpoint-name) {
    .bg-inverse {
      background-color: $white !important;
      color: $black;
    }
  }

  .nav-item {
    @include media-breakpoint-down(lg) {
      border-bottom: 1px solid $form-grey;
    }
    .nav-link {
      padding-left: 1rem;

      @include media-breakpoint-down(lg) {
        outline: none;
        padding-left: rem(27);
        margin-bottom: 0;
        text-transform: uppercase;
        line-height: 1;
        padding-top: rem(18);
        padding-bottom: rem(15);
        letter-spacing: rem(1.6);
      }
      @include media-breakpoint-down(sm) {
        padding-left: rem(15);
      }
      &.trueHighlight{
        span{
            background:$cart-bullet;
            color:$white;
            border-radius:3px;
            padding:4px 8px;
            border-radius: 3px;
            padding: 6px 8px 4px 8px;
        }
      }
    }
    a {
      color: $black !important;
    }
  }

  &.in {
    @include media-breakpoint-down($prev-breakpoint) {
      right: 0;
      margin-right: 1.25em; /* 20/16 */
    }

    @include media-breakpoint-down($breakpoint-name) {
      .nav-item + .nav-item {
        border-top: 1px solid $grey2;
        @include media-breakpoint-down(lg) {
          background-color: $white;
          border-top: none;
        }
      }

      .dropdown {
        display: block;
        position: static;
        @include media-breakpoint-down(lg) {
          background-color: $white;
          a {
            color: $black !important;
          }
        }
      }

      .dropdown-toggle {
        padding-left: 1rem;

        @include media-breakpoint-down(lg) {
          padding-left: rem(27);
        }
        @include media-breakpoint-down(sm) {
          padding-left: rem(15);
        }

        &:after {
          @include caret-right();
          right: 1.3em;
          @include media-breakpoint-down(lg) {
            content: "";
            right: rem(30);
            width: rem(6);
            height: rem(9);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.203' height='7.625'%3E%3Cg data-name='Componente 20 – 128'%3E%3Cpath data-name='Tracciato 104' d='M5.203 3.812L.715 7.523a.483.483 0 01-.593 0 .306.306 0 010-.49l3.895-3.221L.122.591a.306.306 0 010-.49.483.483 0 01.593 0z'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            border: none;
            margin-top: 0;
            margin-left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .show > .dropdown-menu {
        left: 0;
        padding-top: 70px;
        border-top: 1px solid $form-grey;
        background-color: $white;
        @include media-breakpoint-down(lg) {
          padding-top: 0;
          border-top: none;
          margin-top: 0;
          margin-bottom: 0;
          height: calc(100vh - 164px);
          overflow-y: auto;
          padding-bottom: 100px;
        }
      }

      .dropdown-menu {
        position: absolute;
        left: -100%;
        top: 0;
        width: 100%;
        height: 100%;
        border: 0 none;
        transition: $slide-out-animation;
        display: block;
        background-color: $ebony;
      }
    }
  }
}

.multilevel-dropdown {
  .dropdown-menu {
    top: 90%;
    border: 0;
    border-radius: 0;

    @include media-breakpoint-up($next-breakpoint) {
      box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
    }
  }

  .dropdown-item.dropdown > .dropdown-toggle::after {
    @include caret-right();
    right: 1.3em;
    @include media-breakpoint-down(lg) {
      content: "";
      right: rem(30);
      width: rem(6);
      height: rem(9);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.203' height='7.625'%3E%3Cg data-name='Componente 20 – 128'%3E%3Cpath data-name='Tracciato 104' d='M5.203 3.812L.715 7.523a.483.483 0 01-.593 0 .306.306 0 010-.49l3.895-3.221L.122.591a.306.306 0 010-.49.483.483 0 01.593 0z'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      border: none;
      margin-top: 0;
      margin-left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .dropdown-menu > .dropdown > .dropdown-menu {
    @include media-breakpoint-up($next-breakpoint) {
      top: -0.65em;
      left: 99%;
    }
  }

  .navbar > .close-menu > .back {
    display: none;
  }

  .close-menu .back {
    .caret-left {
      @include caret-left();
    }
  }

  .dropdown-item {
    padding: 0 0 0 1em;
    @include media-breakpoint-down(lg) {
      background-color: $white;
      padding-left: rem(27);
    }
    @include media-breakpoint-down(sm) {
      padding-left: rem(15);
    }

    .dropdown-link {
      display: block;
      padding: 0.425em 5em 0.425em 0;
      @include media-breakpoint-down(lg) {
        outline: none;
        padding: 0;
        margin-bottom: 0;
        text-transform: uppercase;
        line-height: 1;
        padding-top: rem(18);
        padding-bottom: rem(15);
        padding-left: 0;
        padding-right: 0;
        letter-spacing: rem(1.6);
        &.last-level {
          text-transform: none;
        }
      }
      &.last-level {
        @include media-breakpoint-down(sm) {
          font-size: rem(13);
          font-weight: 400;
          text-transform: uppercase;
        }
      }
    }

    + .dropdown-item {
      border-top: 1px solid $close-menu-bg;
      @include media-breakpoint-down(lg) {
        border-top: none;
        border-bottom: 1px solid $form-grey;
      }
    }

    &.top-category {
      font-weight: bold;
      padding-left: 40px;

      @include media-breakpoint-down(lg) {
        border-bottom: 1px solid $form-grey;
        padding-left: rem(60);
      }

      @include media-breakpoint-down(sm) {
        padding-left: rem(47);
      }

      > .nav-link {
        padding-left: 0;
        @include media-breakpoint-down(lg) {
          border-bottom: 0 !important;
        }
      }
    }
  }
}

.overlay-menu-open {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.58);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0s;
  transition-delay: 0.3s;
  &.in {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.menu-wrapper {
  @include media-breakpoint-down(lg) {
    padding-top: rem(163);
  }
  @include media-breakpoint-down(sm) {
    padding-top: rem(134);
  }
  &.container {
    @media screen and (max-width: 1919px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.banner-in-page {
    @include media-breakpoint-down(lg) {
      padding-top: rem(212);
    }
    @include media-breakpoint-down(md) {
      padding-top: rem(205);
    }
    @include media-breakpoint-down(sm) {
      padding-top: rem(156);
    }
  }
  &.hellobar-in-page {
    @include media-breakpoint-down(lg) {
        padding-top: rem(252);
    }
    @include media-breakpoint-down(sm) {
        padding-top: rem(186);
    }
}
}

.nav-mob-over {
  @include media-breakpoint-down(md) {
    overflow-y: auto;
  }
}

@include media-breakpoint-down(lg) {
  .menu-toggleable-left {
      .nav-mob-over {
          max-height: calc(100vh - 181px);
          overflow-y: auto;
          align-items: flex-start;
      }
      .banner-in-page .nav-mob-over {
          max-height: calc(100vh - 221px);
      }
      .nav-menu-mob {
          height: calc(100vh - 164px);
          position: relative;
          overflow-y: auto;
      }
      li.nav-item {
          width: 100%;
      }
  }
}
@include media-breakpoint-down(sm) {
  .menu-toggleable-left {
      .nav-mob-over {
          max-height: calc(100vh - 134px);
      }
      .banner-in-page .nav-mob-over {
          max-height: calc(100vh - 164px);
          height: calc(100vh - 164px);
      } 
  }
}