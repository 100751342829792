@mixin carousel-controls {
  .carousel-control-prev {
    .icon-prev {
      padding-top: 0;
      width: rem(45);
      height: rem(45);
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 47%;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.203' height='21.625'%3E%3Cg data-name='Componente 43 – 3'%3E%3Cpath data-name='Tracciato 203' d='M.003 10.813L10.525.288a.983.983 0 011.39 1.39l-9.134 9.135 9.134 9.134a.983.983 0 01-1.39 1.39z'/%3E%3C/g%3E%3C/svg%3E");
        width: rem(12);
        height: rem(21);
      }
    }
  }
  .carousel-control-next {
    .icon-next {
      padding-top: 0;
      width: rem(45);
      height: rem(45);
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 53%;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.203' height='21.625'%3E%3Cg data-name='Componente 43 – 2'%3E%3Cpath data-name='Tracciato 203' d='M12.2 10.812L1.678 21.337a.983.983 0 01-1.39-1.39l9.134-9.135L.288 1.678a.983.983 0 011.39-1.39z'/%3E%3C/g%3E%3C/svg%3E");
        width: rem(12);
        height: rem(21);
      }
    }
  }
  .carousel-indicators {
    z-index: 2;
    li {
      width: rem(10);
      height: rem(10);
      border: 1px solid $form-grey;
      border-radius: rem(20);
      margin-left: rem(5);
      margin-right: rem(5);
      opacity: 0.5;
      @include media-breakpoint-down(sm) {
        width: rem(7);
        height: rem(7);
        margin-left: rem(4);
        margin-right: rem(4);
        border: 1px solid $medium-grey;
        background-color: $medium-grey;
      }
      &.active {
        border: 1px solid $cascade;
        background-color: $cascade;
        opacity: 1;
      }
    }
  }
}

@mixin on-sale {
  .on-sale-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: rem(10);
    .on-sale {
      top: auto;
      left: auto;
      right: rem(9);
      bottom: 0;
      padding-top: rem(9);
      padding-bottom: rem(7);
    }
  }
}

@mixin brand-style {
  font-weight: 700;
  font-size: rem(16);
  letter-spacing: 0.05em;
  line-height: rem(28);
  color: $main-color;
  text-transform: uppercase;
}

@mixin product-name {
  font-size: rem(34);
  font-weight: 400;
  letter-spacing: 0.1em;
  color: $black;
  text-transform: uppercase;
  line-height: rem(42);
  margin-top: rem(6);
  margin-bottom: rem(27);
  @include media-breakpoint-down(lg) {
    line-height: rem(38);
  }
}

@mixin prices-block {
  .prices {
    text-align: left;
    padding: 10px 0;
    .price {
      .range {
        display: flex;
        flex-direction: row;
        @include media-breakpoint-down(md) {
          justify-content: center;
        }
        span {
          display: inline-block;
        }
        > span {
          &:first-of-type {
            padding-right: rem(10);
          }
          &:last-of-type {
            padding-left: rem(10);
          }
        }
        del {
          display: none;
        }
      }
      > span {
        display: flex;
        flex-direction: column;
        .h5.sales {
          order: 1;
          font-size: rem(32);
          letter-spacing: 0.02em;
          color: $black;
          margin-bottom: rem(15);
          @include media-breakpoint-down(lg) {
            margin-bottom: 0;
          }
          span:first-child{
            text-align: left;
          }
        }
        del {
          text-decoration: none;
          .value span:first-child{
            text-align: left !important;
          }
          .value span:nth-child(2){
            text-align: right !important;
          }
        }
        .strike-through {
          float:right;
          margin:0px;
          font-family: "NotoSans-Italic", sans-serif;
          font-size: rem(16);
          letter-spacing: 0.02em;
          line-height: rem(28);
          color: $medium-grey;
          margin-bottom: rem(5);
          text-decoration: none;
          .sr-only {
            &:first-of-type {
              position: relative;
              width: auto;
              height: auto;
              margin: 0;
              overflow: visible;
              clip: auto;
            }
          }
        }
      }
    }
  }
}

@mixin attributes-block {
  .attributes {
    .attribute {
      margin-top: rem(20);
      @include media-breakpoint-down(lg) {
        margin-top: rem(16);
      }
    }
    .product-color {
      span {
        line-height: 1;
        font-size: rem(14);
        font-weight: 300;
        @include media-breakpoint-down(sm) {
          font-size: rem(12);
        }
      }
    }
    .color-attribute {
      line-height: 1;
      outline: none;
      margin-right: rem(15);
      position: relative;
      margin-bottom: rem(10);
      &:last-of-type {
        margin-right: 0;
      }
      &.selected {
        &:before {
          content: "";
          position: absolute;
          width: calc(100% + 6px);
          height: calc(100% + 6px);
          top: rem(-3);
          left: rem(-3);
          border: 1px solid $form-grey;
        }
      }
      img {
        width: 100%;
        height: 100%;
        max-width: rem(67);
        max-height: rem(67);
      }
    }
  }
}

@mixin select-quantity {
  select.quantity {
    width: rem(113);
    padding: 0 40px 0 30px;
    text-indent: 45%;
    text-align-last: auto;
  }
}

@mixin buy-button {
  .buy-button {
    width: 100%;
    padding-left: rem(5);
    button {
      width: 100%;
      height: rem(73);
      padding-top: 0;
      padding-bottom: 0;
      &:disabled {
        background: $soldout-grey !important;
        border-color: $soldout-grey !important;
      }
      @include media-breakpoint-down(lg) {
        padding: 0;
      }
      > i {
        display: none;
      }
    }
  }
}

@mixin wishlist-block {
  .wishlist-block {
    margin-top: rem(15);
    .cc-wishlist-add {
      margin-left: 0;
      background: none;
      border: none;
      outline: none;
      font-weight: 300;
      font-size: rem(14);
      letter-spacing: 0.02em;
      line-height: 1;
      position: relative;
      &:disabled {
        &:before {
          opacity: 0.3;
        }
      }
      .fa-heart {
        display: none;
      }
      &:before {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33.794 33.176'%3E%3Cpath data-name='Tracciato 111' d='M24.147 0a9.542 9.542 0 00-7.25 3.343A9.543 9.543 0 009.647 0 9.741 9.741 0 000 9.811c0 9.2 15.632 22.313 16.3 22.867l.6.5.6-.5c.666-.554 16.3-13.671 16.3-22.867A9.742 9.742 0 0024.147 0zm0 1.926A7.829 7.829 0 0131.9 9.811c0 7.354-12.175 18.4-15 20.861-2.829-2.465-15-13.506-15-20.861a7.829 7.829 0 017.754-7.885 7.71 7.71 0 016.461 3.531l.789 1.207.789-1.207a7.708 7.708 0 016.454-3.53z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        top: 0;
        left: rem(-20);
        width: rem(17);
        height: rem(17);
      }
    }
  }
}

@mixin promotion-details {
  .collapsible-promotion-details {
    margin-top: rem(20);
    div {
      background-color: $mexican-red;
      color: $white;
      letter-spacing: 0.05em;
      line-height: rem(22);
      margin-bottom: 0;
      text-transform: uppercase;
      padding-top: rem(4);
      padding-bottom: rem(4);
      padding-left: rem(15);
      padding-right: rem(15);
      text-align: center;
    }
  }
}

@mixin price-range {
  .price {
    .range {
      display: flex;
      flex-direction: row;
      span {
        display: inline-block;
      }
      > span {
        &:first-of-type {
          padding-right: rem(10);
        }
        &:last-of-type {
          padding-left: rem(10);
        }
      }
      del {
        display: none;
      }
    }
  }
}
