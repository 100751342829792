@import "collapsibleItem";
@import "toastMessage";

footer {
    background-color: $black;
    .dropup .dropdown-menu {
        margin-bottom: 0;
    }
    //padding-top: 1.25em; /* 20/16 */
    //padding-bottom: 1.25em;

    h2 {
        font-size: $font-size-base;
        margin-bottom: 0;
        line-height: 2.5em; /* 40/16 */
    }

    ul {
        list-style: none;
        padding-left: 0;
    }

    .social {
        h2 {
            margin-top: 0;
        }

        @include clearfix;
    }

    .copyright,
    .social {
        margin-top: 1.25em;
    }

    .footer-container .footer-item.collapsible-xs button {
        font-family: 'Dosis', sans-serif;
        padding: 0;
    }

    .social-links {

        @include clearfix;

        @include media-breakpoint-down(xs) {
            width: 80%;
        }

        float: left;

        li {
            float: left;
            margin: 0.313em;

            @include media-breakpoint-down(xs) {
                width: 20%;
                text-align: center;
            }
        }

        a {
            font-size: 2.25em;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .store {

        @include media-breakpoint-down(xs) {
            border-bottom: 1px solid $dark-gray;

            .content {
                display: none;
            }

            h2 {
                @include clearfix;

                &::after {
                    font-family: "FontAwesome";
                    float: right;
                    content: "\f041";
                }
            }
        }
    }

    .content {
        font-size: 0.875em;

        li {
            height: 1.875rem;
        }
    }

    .copyright,
    .postscript {
        font-size: 0.8125em;
    }

    .copyright {
        margin-bottom: 0.625em;
    }

    .back-to-top {
        margin: 0.1em 0.313em;
        padding: 0;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;

        i {
            &.fa-arrow-up {
                color: rgba(0, 0, 0, 0.7);
            }

            &.fa-circle {
                text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.footer-item {

    @include media-breakpoint-down(xs) {
        border-bottom: 1px solid $dark-gray;
    }
}

#consent-tracking {
    .button-wrapper {
        button {
            margin: 0.5em;
        }
    }
}

.email-signup-message {
    @include toast-message();
}

.email-signup-alert {
    @include toast-alert();
}


.footer {
    &-top {
        .h10 {
            font-size: rem(12);
            color:$white;
            @include media-breakpoint-down(lg) {
                font-size: rem(12);
                margin-bottom: rem(2);
                line-height:1.5rem;
            }
        }
        h6.h10 {
            text-transform: uppercase;
            font-size: rem(14);
            margin-bottom: rem(16);
            font-family: notosans-italic,sans-serif;
            @include media-breakpoint-down(lg) {
                margin-bottom: rem(16);
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
            }
        }
        a.h10{
            display: inline-block;
            line-height: 1rem;
            color: $white;
            border-bottom: 1px dotted $black;
            &:visited{
              border-bottom: 1px dotted $black;
            }
            &:hover {
                text-decoration: none;
                color: $light-grey;
                border-bottom: 1px dotted $white !important;
            }
        }
        padding-bottom: 35px;
    }
    &-presentation {
        @include media-breakpoint-down(md) {
            padding-bottom: rem(35);
            padding-left: rem(0);
            padding-right: rem(5);
        }
        @include media-breakpoint-down(sm) {
            padding-left: rem(5);
        }
        .footer-logo-container {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          margin-top: 7.25rem;
          margin-bottom: 0.5rem;
        }
        .footer-logo {
            width: 11.375rem;
            height: 3.125rem;
            background-image: url("../images/footer-logo.svg");
            background-size: contain;
            background-repeat: no-repeat;  
            @include media-breakpoint-down(md) {
                width: rem(210);
                height: rem(70);
                margin-top: rem(45);
                margin-bottom: rem(24);
            }
            @include media-breakpoint-down(sm) {
                width: rem(178);
                height: rem(50);
            }
        }
        .footer-logo-royal {	
          width: rem(86);	
          height: rem(86);	
          margin-left: rem(42);	
          background-image: url("../images/logoRoyal.svg");	
          background-size: contain;	
          background-repeat: no-repeat;	
          @include media-breakpoint-down(lg) {	
            width: rem(76);	
            height: rem(76);	
            margin-left: rem(30);	
          }
        }
        .h10 {
            font-size: rem(12);
            margin-bottom: 0;
            &:first-of-type {
                margin-bottom: rem(22);
            }
            @include media-breakpoint-down(lg) {
                padding-left: 0;
                font-size: rem(12);
            }
            @include media-breakpoint-down(md) {
                padding-left: rem(0);
                padding-right: rem(0);
                line-height: rem(24);
            }
        }
    }
    &-footer-social,
    &-about-us,
    &-customer-service,
    &-contact-us {
        padding-top: rem(205);
        @include media-breakpoint-down(md) {
            padding-top: rem(20);
        }
        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }
        a,
        p {
            margin-bottom: rem(5);
            color: $main-color;
            @include media-breakpoint-down(md) {
                font-size: rem(12);
                margin-bottom: rem(12);
            }
        }
    }
    &-contact-us {
        p {
          color: $white;
          @include media-breakpoint-down(lg) {
              margin-bottom: rem(2);
          }
        }
        a {
            &.h10 {
                display: inline-block;
                line-height: rem(16);
                color: $white;
                border-bottom: 1px dotted $white;
                @include media-breakpoint-down(sm) {
                    line-height: rem(12);
                }
                &:visited {
                  text-decoration: none;	
                  color: $medium-wood;	
                  border-bottom: 1px dotted $medium-wood;
                }
                &:hover {
                  text-decoration: none;	
                  color: $river-bed;	
                  border-bottom: 1px dotted $river-bed;
                }
            }
        }
        .pt-2 {
            margin-top: rem(10);
        }
    }
    &-bottom {
        margin-top: rem(70);
        padding-top: rem(28);
        padding-bottom: rem(25);
        border-top: 1px solid $border-grey;
        @include media-breakpoint-down(md) {
            margin-top: rem(0);
        }
        @include media-breakpoint-down(sm) {
            border-top: none;
            padding-top: rem(20);
            padding-bottom: rem(20);
        }
        .container {
            @include media-breakpoint-down(md) {
                padding-left: rem(20);
                padding-right: rem(20);
            }
        }
    }
    &-copyright {
        @include media-breakpoint-down(sm) {
            padding-top: rem(10);
            padding-bottom: rem(10);
        }
        p {
            margin-bottom: 0;
        }
    }
    &-copyright-wrapper {
        @include media-breakpoint-down(md) {
            padding-right: rem(0);
        }
        @include media-breakpoint-down(sm) {
            padding-right: rem(15);
            .content-asset {
                width: 100%;
                text-align: center;
                .footer-copyright {
                    display: inline-block;
                }
            }
        }
    }
    &-social {
        @include media-breakpoint-down(sm) {
            margin-top: rem(18);
            justify-content: center;
        }
        .svg {
            &-yt {
                width: rem(20);
                margin-right: rem(13);
                @include media-breakpoint-down(md) {
                    width: rem(17);
                    margin-right: rem(10);
                }
                @include media-breakpoint-down(sm) {
                    width: rem(31);
                    margin-right: rem(28);
                }
            }
            &-fb {
                width: rem(18);
                margin-right: rem(13);
                @include media-breakpoint-down(md) {
                    width: rem(15);
                    margin-right: rem(10);
                }
                @include media-breakpoint-down(sm) {
                    width: rem(28);
                    margin-right: rem(28);
                }
            }
            &-is {
                width: rem(18);
                @include media-breakpoint-down(md) {
                    width: rem(15);
                }
                @include media-breakpoint-down(sm) {
                    width: rem(26);
                }
            }
        }
    }
    &-payments {
        @include media-breakpoint-down(sm) {
            text-align: center;
            margin-top: rem(30);
            margin-bottom: rem(20);
        }
        .content-asset {
            @include media-breakpoint-down(md) {

            }
            @include media-breakpoint-down(sm) {
                margin-top: rem(10);
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .iconbg{
            background:#fff;
            padding: 3px 5px 5px 5px;
            border-radius: 4px;
            border: 1px solid $form-grey;
        }
        .svg {
            &-visa {
                width: rem(32);
                @include media-breakpoint-down(md) {
                    width: rem(24);
                }
                @include media-breakpoint-down(sm) {
                    width: rem(35);
                }
            }
            &-dk {
                width: rem(32);
                @include media-breakpoint-down(md) {
                    width: rem(24);
                }
                @include media-breakpoint-down(sm) {
                    width: rem(35);
                }
            }
            &-mastercard,
            &-maestro {
                width: rem(32);
                @include media-breakpoint-down(md) {
                    width: rem(24);
                }
                @include media-breakpoint-down(sm) {
                    width: rem(35);
                }
            }
            &-maestro {
            }
            &-mobilepay {
                width: rem(40);
                @include media-breakpoint-down(md) {
                    width: rem(31);
                }
                @include media-breakpoint-down(sm) {
                    width: rem(43);
                }
            }
        }
    }
    &-delivery {
        @include media-breakpoint-down(sm) {
            text-align: center;
            margin-top: rem(20);
            margin-bottom: rem(20);
        }
        .content-asset {
            @include media-breakpoint-down(sm) {
                margin-top: rem(10);
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .iconbg{
            background:#fff;
            padding: 5px;
            border-radius: 4px;
            border: 1px solid $form-grey;
        }
    }
    &-truspilot {
        .content-asset {
            @include media-breakpoint-down(md) {
                text-align: center;
            }
            @include media-breakpoint-down(sm) {
                margin-top: rem(20);
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    &-footer-copyright-light {
        .content-asset {
            @include media-breakpoint-down(md) {
                text-align: center;
            }
            @include media-breakpoint-down(sm) {
                margin-top: rem(20);
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    &-payments-light {
        .content-asset {
            @include media-breakpoint-down(md) {
                text-align: center;
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: rem(20);
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .svg {
            &-visa {
                width: rem(30);
                @include media-breakpoint-down(sm) {
                    width: rem(35);
                    margin-right: rem(15);
                }
            }
            &-dk {
                width: rem(30);
                @include media-breakpoint-down(sm) {
                    width: rem(35);
                }
            }
            &-mastercard,
            &-maestro {
                width: rem(30);
                @include media-breakpoint-down(sm) {
                    width: rem(35);
                }
            }
            // &-maestro {
            //     margin-right: rem(5);
            // }
            &-mobilepay {
                width: rem(38);
                @include media-breakpoint-down(sm) {
                    width: rem(43);
                }
            }
        }
    }
    &-change-language {
        .country-selector {
            float:right;
            @include media-breakpoint-down(lg) {
                padding-left: rem(33);
            }
            @include media-breakpoint-between(sm, md) {
                padding-left: rem(13);
            }
            outline: none;
            a{
                border-bottom:0px;
            }
            .flag-icon {
                transform: translateY(-1px);
                background-size: cover;
                width: 1.3em;
                height: 1.3em;
                line-height: auto;
                border-radius: 20px;
                @include media-breakpoint-down(md) {
                    width: 1.7em;
                    height: 1.7em;
                }
            }
            #dropdownCountrySelector {
                padding: 0;
                padding-left: 0%;
                font-size: rem(12);
                &:after {
                    display: none;
                }
                @include media-breakpoint-down(xl) {
                    padding-left: 0%;
                }
                @include media-breakpoint-down(lg) {
                    padding-left: 0%;
                }
                .flag-icon {
                    transform: translateY(-1px);
                }
            }
            .dropdown-country-selector {
                &.show {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                }
                .flag-icon {
                    transform: translateY(1px);
                }
            }
            #dropdownCountrySelector,
            .dropdown-country-selector {
                .flag-icon {
                    max-width: rem(16);
                }
                .text {
                    font-family: 'Open Sans', sans-serif;
                    font-size: rem(16);
                    padding-left: rem(8);
                    display: inline-block;
                    text-transform: uppercase;
                    letter-spacing: rem(0.16);
                    margin-bottom: 0;
                    line-height: 1;
                    @include media-breakpoint-down(md) {
                        font-size: rem(14);
                    }
                }
                .language {
                    font-weight: 700;
                    @include media-breakpoint-down(sm) {
                        font-weight: 400;
                    }
                }
                .country {
                    text-transform: capitalize;
                }
            }
        }
    }
}

.mobile-accordion {
    @include media-breakpoint-down(md) {
        padding-top: rem(18);
        padding-bottom: rem(18);
        padding-left: rem(20);
        padding-right: rem(20);
        @include media-breakpoint-down(sm) {
            border-top: 1px solid $form-grey;
            &:last-child{
                border-bottom: 1px solid $form-grey;
            }
        }
        #accordion .card-block,
        #accordion2 .card-block,
        #accordion3 .card-block {
            padding-top: rem(20);
            li{ 
                position:relative;
                border-top:1px dotted $form-grey;
                padding-left:12px;
                a.h10{
                    border:0px !important;
                    line-height:45px;
                    display:block;
                    color:$white;
                    text-decoration: underline;
                }
            }
        }
        #accordion4 .card-block {
            padding-top: rem(20);
            li{
                line-height:45px;
                a.h10{
                    border:0px !important;
                }
            }
        }
        .is-accordion {
            h6 {
                font-size: rem(14);
                line-height: 1;
                font-weight: 400;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    width: rem(15);
                    height: rem(9);
                    background-image: url("data:image/svg+xml,%3Csvg data-name='Componente 20 – 20' xmlns='http://www.w3.org/2000/svg' width='15.625' height='9.203'%3E%3Cpath data-name='Tracciato 104' d='M7.813 9.203l-7.6-7.938a.764.764 0 010-1.048.689.689 0 011 0l6.6 6.889 6.6-6.889a.689.689 0 011 0 .764.764 0 010 1.048z' fill='%23333'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    filter: invert(1);
                    top: 45%;
                    transform: translateY(-50%);
                }
                &.is-active {
                    &:after {
                        transform: rotate(180deg);
                        transform-origin: center 20%;
                    }
                }
            }
        }
        .country-selector {
            outline: none;
            font-size: rem(14);
            line-height: rem(14);
            &.show {
                #dropdownCountrySelector {
                    &:after {
                        transform: rotate(180deg);
                        transform-origin: center 20%;
                    }
                }
            }
            #dropdownCountrySelector {
                padding: 0;
                width: 100%;
                text-align: left;
                font-size: rem(14);
                line-height: rem(14);
                display: inline-block;
                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    width: rem(15);
                    height: rem(9);
                    background-image: url("data:image/svg+xml,%3Csvg data-name='Componente 20 – 20' xmlns='http://www.w3.org/2000/svg' width='15.625' height='9.203'%3E%3Cpath data-name='Tracciato 104' d='M7.813 9.203l-7.6-7.938a.764.764 0 010-1.048.689.689 0 011 0l6.6 6.889 6.6-6.889a.689.689 0 011 0 .764.764 0 010 1.048z' fill='%23333'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    top: 45%;
                    transform: translateY(-50%);
                    border: none;
                    margin-left: 0;
                }
                .flag-icon {
                    display: none;
                }
            }
            .dropdown-country-selector {
                &.show {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                }
            }
            #dropdownCountrySelector,
            .dropdown-country-selector {
                .flag-icon {
                    max-width: rem(16);
                }
                .text {
                    font-family: 'Open Sans', sans-serif;
                    padding-left: 0;
                    display: inline-block;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    line-height: 1;
                    font-weight: 400;
                    font-size: rem(14);
                    letter-spacing: rem(0.28);
                }
            }
        }
    }
    
}

.cc-footer-trustpilot-container {
    border-top: 1px solid lightgray;
}

.cc-footer-trustpilot {
    margin: 2% auto;
    max-width:1630px
}

.cc-title-footer-trustpilot {
    text-align: center;
    margin-bottom: 2%;
}

.footer-light-asset{
    padding-top: rem(16);
    padding-bottom: rem(56);
    @include media-breakpoint-down(lg) {
        padding-top: 0% !important;
    }
}

.footer-light {
    background-color: white !important;
    padding-top: rem(40);
    @include media-breakpoint-down(sm) {
        padding-top: rem(10);
        padding-bottom: rem(10);
    }
}

.cc-logo-footer-light {
    margin: 0;

    @include media-breakpoint-up(xl) {
        max-width: 200px;
        padding-top: rem(30);
        padding-right: rem(48);
    }
    @include media-breakpoint-only(md) {
        max-width: 130px;
        margin-right: 30px;
        margin-top: 30px;
    }
    @include media-breakpoint-only(lg) {
        margin-right: 30px;
        max-width: 130px;
    }

    @include media-breakpoint-down(sm) {
        max-width: 78px;
        padding-top: rem(10);
        padding-bottom: rem(26);
    }

}


.cc-finish-accordion{
    @include media-breakpoint-down(sm){
        border-bottom: 1px solid #d3d3d3;
    }
}


.footer-truspilot {
    margin-left: rem(-40);
    @include media-breakpoint-down(sm){
        margin-left: auto;
    }
    @include media-breakpoint-only(md){
        margin-left: rem(-20);
    }
}

.footer-light-copyright{
    margin-inline-start: auto;
    @include media-breakpoint-down(sm){
        padding-left: 0;
        padding-right: 0;
        margin-top: rem(20);
        text-align: center !important;
    }
    .footer-copyright {
        @include media-breakpoint-up(xl) {
            padding-left: 0;
            padding-right: rem(40);
        }
    }
}

.footer-500 {
    background-color: white !important;
}


.cc-logo-footer-500 {
    margin-top: 5%;
    margin-bottom: 5%;

    @include media-breakpoint-up(xl) {
        max-width: rem(193);
        margin-right: -4%;
    }
    @include media-breakpoint-down(lg) {
        max-width: rem(127);
        margin-top: 6.5%;
    }
    @include media-breakpoint-down(sm) {
        max-width: rem(90);
        margin-top: 10%;
    }
}

.footer-bottom-light {
    @include media-breakpoint-down(sm) {
        padding-bottom: rem(90);
    }

    margin-top: 0 !important;
}

.footer-copyright-light {
    @include media-breakpoint-only(md) {
        margin-right: 10px;
    }
    @include media-breakpoint-only(lg) {
        margin-right: 30px;
    }
}

.footer-contact-us-ligth{
    padding-top: rem(10) !important;
}

.footer-copyright{
    @include media-breakpoint-only(md) {
        p  {
            &.h10{
            font-size: 0.65rem;
        }
    }
    }
}

#footercontent {
    .footer-copyright {
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }
}
.footer-bottom {
    .footer-copyright {
        @include media-breakpoint-down(md) {
            padding-left: rem(26);
        }
        @include media-breakpoint-down(sm) {
            padding-left: rem(5);
        }
        .h10 {
            @include media-breakpoint-down(md) {
                padding-left: rem(15);
            }
            @include media-breakpoint-down(sm) {
                padding-left: rem(0);
            }
        }        
    }
    .footer-change-language {
        @include media-breakpoint-down(md) {
            padding-left: rem(20);
        }
        .country-selector {
            padding-left: rem(0);
            #dropdownCountrySelector {
                padding-left: rem(0);
            }
        }
    }
}

.footer-social {
    @include media-breakpoint-down(md) {
        padding-right: rem(0);
    }
    @include media-breakpoint-down(sm) {
        padding-right: rem(15);
    }
}

#to-top-button{
    display: inline-block;
    background-color: $grey2;
    padding-top: 6px;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 1px;
    position: fixed;
    bottom: 130px;
    right: 0px;
    transition: background-color .3s, 
    opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 4;
    &:hover{
        cursor:pointer;
        background-color:$grey5;
        svg{
            fill:$white;
        }
    }
    &.show{
        opacity: 1;
        visibility: visible;
    }
    svg{
        width: 20px;
        height: 20px;  
    }
}