@import "../utilities/swatch";
@import "../variables";

.site-search {
  position: relative;
  height: auto; /* 40/16 */

  @include media-breakpoint-up(sm) {
    margin-right: 0.5em; /* 20/16 */
  }

  @include media-breakpoint-up(xl) {
    width: 20em; /* 320/16 */
  }

  @include media-breakpoint-only(md) {
    width: 14.0625em; /* 225/16 */
  }

  @include media-breakpoint-only(sm) {
    width: 12.5em; /* 200/16 */
  }

  .fa-close,
  .fa-search {
    position: absolute;
    border: none;
    top: 0.5625em; /* 9/16 */
    right: 0.5625em; /* 9/16 */
    padding: 0;
    background-color: transparent;
  }

  .reset-button {
    position: absolute;
    border: none;
    top: 0.5625em; /* 9/16 */
    right: 2em; /* 32/16 */
    padding: 0;
    background-color: transparent;
  }
}

@include media-breakpoint-down(xs) {
  .header-search {
    .site-search {
      display: none;
    }
  }
}

.navbar-header .search{
  justify-content: center !important;
}

.suggestions-wrapper {
  position: relative;
}

.suggestions {
  display: block;
  position: absolute;
  border: 0px solid $grey3;
  background-color: $white;
  top: rem(10);
  right: -75%;
  width: calc(250% - 0px);
  z-index: 3;
  padding:10%;
  @include media-breakpoint-down(sm) {
      padding:2% !important;
  }
  @include media-breakpoint-down(lg) {
    height: 60vh;
    overflow-y: scroll;
    top: rem(-20);
  }
  @include media-breakpoint-down(sm) {
    border: 0;
    height: auto;
    left: rem(-16);
    width: 100vw;
    border-top: 1px solid #eae8e8;
    top: -2px;
    box-shadow: 0rem 0.5rem $white;
  }
  .swatch-circle {
    @include swatch(2.5em, $white);
  }

  .header {
    color: $grey4;
    font-size: 0.875em;
    padding-top: 0.625em;

    &:not(:first-child) {
      border-top: 1px solid $grey3;
    }
  }

  .items {
    padding: 0.313em 0;
  }

  .item {
    padding-bottom: 0.625em;

    .name {
      margin-top: 0.313em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @include media-breakpoint-down(xs) {
      padding-bottom: 0.938em;
    }
  }

  .category-parent {
    color: $grey4;
    font-size: 0.875em;
  }

  .selected {
    background-color: $grey2;
  }

  @mixin results-link {
    font-size: rem(16);
    line-height: rem(28);
    color: $main-color;
    @include media-breakpoint-down(sm) {
      font-size: rem(12);
    }
  }
  .container {
    list-style-type: none;
    &#search-results {
      padding: rem(13);
      padding-top: rem(15);
      margin: 0;
      text-align: left;
      @include media-breakpoint-down(sm) {
        padding: rem(15);
        padding-top: rem(20);
      }
      li {
        &.header-search-results {
          padding-top: 0;
          font-weight: 400;
          font-size: rem(16);
          letter-spacing: 0.1em;
          line-height: 1;
          color: $black;
          text-transform: uppercase;
          margin-bottom: rem(18);
          position: relative;
          @include media-breakpoint-down(sm) {
            font-size: rem(13);
            margin-bottom: rem(10);
          }
          &:not(:first-child) {
            border-top: 0;
            padding-top: rem(23);
            &:before {
              content: "";
              position: absolute;
              width: calc(100% + 26px);
              height: rem(1);
              background-color: #eae8e8;
              top: 0;
              left: rem(-13);
            }
          }
        }
        &.products {
          padding: 0;
          span.item {
            display: block;
            padding-bottom: rem(15);
            @include media-breakpoint-down(sm) {
              padding-left: rem(11);
              padding-bottom: rem(4);
            }
            &:last-of-type {
              padding-bottom: rem(24);
              @include media-breakpoint-down(sm) {
                padding-bottom: rem(10);
              }
            }
            > a {
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .swatch-circle {
              width: rem(71);
              height: rem(71);
              border-radius: 0;
              border: none;
              margin-right: rem(15);
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            span.name {
              @include results-link;
            }
          }
        }
        &.terms,
        &.searches,
        &.contents,
        &.categories {
          padding: 0;
          .item {
            margin-bottom: rem(8);
            padding-bottom: 0;
            @include media-breakpoint-down(sm) {
              margin-bottom: rem(4);
            }
            &:last-of-type {
              margin-bottom: rem(13);
              @include media-breakpoint-down(sm) {
                margin-bottom: rem(10);
              }
            }
            &.term {
              padding-left: rem(11);
              a {
                @include results-link;
              }
            }
            .name {
              margin-top: 0;
              padding-left: rem(11);
              @include results-link;
              a {
                @include results-link;
              }
              .category-parent {
                display: none;
              }
            }
          }
          img.swatch-circle {
            display: none;
          }
        }
      }
    }
  }
  .suggestions-container{
    display: flex;
    height: 100%;
    @include media-breakpoint-down(sm) {
        display: grid;
    }
    .left-column, .right-column{
        display: flex;
        flex-direction: column;
    }
    .left-column{
        width: 30%;
        border-right:1px solid #eaeaea;
        padding-right:4%;
        @include media-breakpoint-down(sm) {
            border:0px;
            width: 100%;
            padding:0px;
            order:1; 
        }
    }
    .right-column{
        width: 70%;
        padding-left:4%;
        @include media-breakpoint-down(sm) {
            width: 100%;
            padding:0px;
            order: -1; 
        }
    }
    .right-column .product-title,
    .left-column .product-title{
        float: left;
        margin-top: 10px;
    }
}
.baskerville{
    margin-bottom:15px;
}
ul{
    padding:0;
}
li {
    padding: 0;
    list-style: none;
}
li a {
    margin-bottom:5px;
    font-size: 0.8rem;
    border:1px solid $grey2;
    background:$grey1;
    padding:0px 8px;
    line-height:50px;
    display:block;
    color: #333 !important;
    font-weight: 700;
    text-align:left;
    &:hover{
        border:1px solid $grey3;
    }
    img{
        width:51px;
        margin-left: -8px;
        margin-right: 8px;
    }

}
.baskerville{
    font-size:26px;
    text-align:left;
}
.all-results{
    float:right;
}
.all-results .btn{
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.2rem;
}
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 25px;
    column-gap: 25px;
    row-gap: 25px;
    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr);
    }
}
.grid-item {
    align-items: top;
    color:#000;
    display: flex;
    justify-content: center;
    text-align: left;
    a{
        display:block;
        width:100%;
        border:0px;
        &:after{
            @include caret-right();
            right: 1.3em;
            @include media-breakpoint-down(sm) {
                content: '';
                right: rem(30);
                width: rem(6);
                height: rem(9);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5.203' height='7.625'%3E%3Cg data-name='Componente 20 – 128'%3E%3Cpath data-name='Tracciato 104' d='M5.203 3.812L.715 7.523a.483.483 0 01-.593 0 .306.306 0 010-.49l3.895-3.221L.122.591a.306.306 0 010-.49.483.483 0 01.593 0z'/%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                border: none;
                margin-top: 0;
                margin-left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .on-sale{
      padding-top: 0.325rem;
      padding-bottom: 0.325rem;
      padding-left: 0.475rem;
      padding-right: 0.4375rem;
      right: inherit;
      left: 0.375rem;
      top: 0.375rem;
      @include media-breakpoint-down(sm) {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0.475rem;
        padding-right: 0.4375rem;
        right: initial;
        left: 0px;
        top: 0;
        width: calc(20% - 15px);
    }
  }
    picture{
        img{
            background: #eaeaea;
        }
    }
    .name{
        color:#333; 
        padding:5px 0; 
        display: block;
        font-size:0.8rem; 
        line-height: 20px; 
            @include media-breakpoint-up(sm) {
                min-height:50px;
            }
        }
    .price{
        display:block;
        width:100%;
    }
    .price .price-now,
    .price .price-before{
        display:block;
        width:100%;
        color:#333;
        font-size:0.8rem;
    }
    .price{
        border-top:1px solid #eaeaea;
        border-bottom:1px solid #eaeaea;
        padding:7px 0;
    }
    .price .price-now .price-amount{
        font-size:22px;
        font-family: "baskerville-display-pt", serif;
    }
    .price .price-before .price-amount{
        text-decoration: line-through;
    }
    .price .price-amount{
        float:right;
    }
    img{
        width:100%;
    }
}
@include media-breakpoint-down(sm) {
    .brand {
        text-align: left;
    }
    picture {
        width: 20%;
        /* display: flex; */
        float: left;
        padding-right:15px;
    }
    .infocontainer{
        width:80%;
    }
    .name{
        width: 80%;
        float: left;
        color:#000 !important;
        padding:0px !important;
        line-height: 1rem;
        margin-bottom: 5px;
    }
    .price{
        width: 80% !important;
        float: left;
        border:0px !important;
        padding:0px !important;
    }
    .grid{
        row-gap: 0px;
        grid-template-columns: inherit;
    }
    .suggestions-container .right-column{
        margin-bottom:20px;
    }
    .suggestions-container .left-column{
        padding-bottom: 30%;
    }
    li a{
        text-align: left;
    }
    .grid-item{
      border-top:1px solid #eaeaea;
      padding:15px 0 15px 0;
      a{
          display: flex;
          justify-content: center;
          align-items: center;
      }
      .price .price-now{
          font-weight:bold;
      }
      .price .price-now .price-amount {
          font-size: 0.8rem;
          font-family: inherit;
      }
      .price .price-before {
          color:#666 !important;
      }
      .price .price-text{
          margin-right:5px;
          line-height: 16px;
      }
      .price .price-amount{
          line-height: 16px;
      }
      .price .price-now, .price .price-before {
          display:flex;
          width: auto;
          color: #000;
      }
  }
}
}
.site-search {
z-index: 5;
}
@include media-breakpoint-up(xl) {
.overlay-search-open {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.58);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    display:none;
    pointer-events: none;
    -webkit-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}
}

.more-below {
  -moz-border-radius: 1.25em;
  background: $grey3;
  border: 0.063em solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25em;
  bottom: 1.875em;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  display: none;
  height: 2.5em;
  position: fixed;
  right: 1.875em;
  width: 2.5em;

  i.fa-long-arrow-down {
    border-radius: 50%;
    color: $white;
    display: table-caption;
    height: 0.75em;
    font-size: 1.5rem;
    left: 0.57em;
    line-height: 0.8em;
    position: absolute;
    top: 0.4em;
    width: 0.8em;
  }
}

.nav-tabs-wrapper {
  @include media-breakpoint-down(lg) {
    margin-bottom: rem(82);
  }
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: rem(45);
  }
  & + .result-count {
    @include media-breakpoint-down(sm) {
      order: 0 !important;
      padding: 0 !important;
      text-align: center !important;
      margin-bottom: rem(30) !important;
      & + .col-8 {
        margin-bottom: rem(6);
        & + .col-4 {
          margin-bottom: rem(6);
        }
      }
    }
  }
  &.spec-ul {
    .nav-tabs {
      @include media-breakpoint-up(xl) {
        padding-left: rem(5);
      }
    }
  }
  .nav-tabs {
    border-bottom: 0;
    max-width: rem(900);
    margin: 0 auto;
    @include media-breakpoint-down(lg) {
      max-width: rem(980);
    }
    @include media-breakpoint-down(md) {
      max-width: calc(100% - 30px);
    }
    @include media-breakpoint-down(sm) {
      max-width: none;
    }
    .nav-item {
      .nav-link {
        font-weight: 700;
        font-size: rem(16);
        color: $black;
        padding: 0;
        line-height: 1;
        text-transform: uppercase;
        outline: none;
        height: rem(50);
        @include media-breakpoint-down(sm) {
          height: rem(33);
          font-size: rem(12);
        }
        &.active {
          border-bottom: 0;
          &:hover {
            background-color: $white !important;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: rem(5);
            background-color: $black;
            @include media-breakpoint-down(sm) {
              height: rem(4);
            }
          }
        }
        &:not(.active) {
          background-color: $white !important;
          &:hover {
            background-color: $white !important;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: rem(1);
            background-color: #c6c6c6;
          }
        }
      }
    }
  }
}
