.container .breadcrumb {
    border-radius: 0;
    border-bottom: $border-width solid $grey3;
    @include media-breakpoint-down(sm) {
        padding-right: 0;
        border-bottom: none;
        margin-bottom: 0;
        .breadcrumb-item {
            a {
                position: relative;
                font-size: rem(11);
                color: $black;
                padding-left: rem(8);
                line-height: 1;
                &:before {
                    content: '';
                    position: absolute;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.415' height='8.954'%3E%3Cpath data-name='Tracciato 108' d='M12.415 4.767H1.147l3.9 3.776-.424.411L.002 4.477 4.623 0l.424.411-3.9 3.776h11.267z' fill='%232d2d2d'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    width: rem(12);
                    height: rem(9);
                    top: 50%;
                    left: rem(-8);
                    transform: translateY(-50%);
                }
            }
        }
    }
}
