.navbar-item {
  padding: 10px;
}

.menu-section-title {
  list-style: none;
}

.cc-navbar {
  .cc-navbar-menu {
    font-size: 0;

    & > .cc-navbar-list > .cc-navbar-item > .cc-navbar-link {
      white-space: nowrap;
    }

    .cc-overlay-tot {
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 0;
      top: 200px;
    }

    .cc-navbar-list {
      display: flex;
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;

      @include media-breakpoint-down(xs) {
        display: flex;
        flex-direction: column;
        padding: 0;
        background: white;
      }

      .cc-navbar-item {
        padding-right: rem(22);
        padding-left: rem(4);
        margin: 0;
        &:last-of-type {
          padding-right: 0;
        }

        @include media-breakpoint-down(md) {
          padding: 0 12px;
        }
        @include media-breakpoint-down(xs) {
          float: none;
          display: block;
          text-align: left;
          padding: 0 15px;
          border-bottom: 1px solid grey;
        }
        @include media-breakpoint-up(md) {
          .col-sm-3 {
              -ms-flex: 0 0 20%;
              flex: 0 0 20%;
              max-width: 20%;
          }
        }

        .cc-navbar-link {
          font-family: "NotoSans-Light", sans-serif;
          display: block;
          position: relative;
          letter-spacing: rem(1.6);
          padding: rem(37) 0 rem(30);
          margin-bottom: 0;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
          font-size: rem(18);
          @include media-breakpoint-down(lg) {
            font-size: rem(14);
          }
          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: rem(5);
            background-color: $black;
            opacity: 0;
            transition: opacity 0.25s ease-out;
          }
          &:hover {
            &:before {
              opacity: 1;
            }
          }
          &.trueHighlight{
            span{
                background:$cart-bullet;
                color:$white;
                border-radius:3px;
                padding:4px 8px;
            }
          }
        }
      }
    }
  }
}

.container{
  max-width: 1638px;
}

.cc-menu-subcategories{
  .col{
      padding:0 0 15px 0;
  }
}

.cc-menu-overlay {
  z-index: 100;
  width: 100%;
  border-top: 1px solid grey;
  overflow-y: auto;
  position: absolute;
  margin-left: 0;
  margin-right: 0;
  left: 0;
  right: 0;
  margin-top: 0;
  background: $white;
  opacity:0;
  visibility: hidden;
  display:block !important;
  transition: all 0s;
  transition-delay: 0.3s;

  &.cc-open {
    top: 0;
  }

  .container{
    max-width: 1638px;
    padding:0px;
  }

  .cc-menu-subcategories{
      .col{
          padding:0 0 15px 0;
      }
  }

  .cc-menu-overlay-container {
    position: relative;
    background: $white;
    @include media-breakpoint-down(xl) {
      padding-left: rem(5);
      padding-right: rem(5);
    }

    .cc-menu-overlay-content {
      text-align: left;

      .cc-height-reg {
        padding: 0;
        margin: 0;

        .cc-menu-subcategories {
          padding-top: rem(47);
          padding-bottom: rem(45);
          overflow: hidden;

          .row,
          .col-sm-3 {
            margin: 0;
            padding: 0;
          }

          .col-sm-3 {
            padding-bottom: rem(25);
          }

          .cc-menu-subcategory-name-container {
            .cc-menu-subcategory-name {
              letter-spacing: rem(1.6);
              text-transform: uppercase;
              color: $black;
              margin-bottom: rem(12);
              font-size: rem(16);
              @include media-breakpoint-down(lg) {
                font-size: rem(14);
              }
            }
          }

          .cc-menu-subcategory:not(:first-of-type) {
            margin-top: 24px;
          }

          .cc-menu-subcategory-content {
            padding-bottom: rem(3);

            .cc-menu-subcategorybis-name {
              letter-spacing: rem(0.32px);
              margin-bottom: 0;
              font-size: rem(13);
              line-height: rem(25);
              @include media-breakpoint-down(lg) {
                font-size: rem(11);
              }
            }
          }
        }
      }

      .cc-height-reg-edit {
        margin: 0;

        .cc-padding-reg {
          padding: 0 !important;
        }
      }
    }

    .cc-menu-overlay-landing {
      bottom: 0;
      width: 100%;
      padding: 12px 16px 16px 18px;
      font-size: 0;
      border-top: 1px solid lightgray;
      text-align: center;

      .cc-menu-overlay-landing-text {
        display: inline-block;
        color: $white;
        font-size: 14px;
        letter-spacing: 3.5px;
        padding: 7px 1px;
        text-transform: uppercase;
        border-bottom: 1px solid black;
      }
    }
  }
}

.cc-navbar-item:hover .cc-menu-overlay{
    opacity:1;
    visibility: visible;
}

 .cc-normalize-ul {
  padding-left: 0;
  list-style: none;
}

.js-back-menu {
  padding-top: 3px;
  padding-left: 10px;
  position: absolute;
  @include media-breakpoint-down(lg) {
    padding-top: 0;
    padding-left: rem(27);
    position: absolute;
    height: rem(50);
    button {
      outline: none;
      background: none;
      border: none;
      height: auto;
      padding: 0;
      width: auto;
      svg {
        position: absolute;
        width: rem(18);
        height: rem(13);
        transform: scale(1.5);
        transform-origin: 50% 20%;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    height: rem(47);
    padding-left: rem(15);
  }
}
