@import "../variables";
@import "../bootstrap/scss/variables";
@import "../bootstrap/scss/mixins/breakpoints";

//VAR
$white: #fff;
$light-grey: #ededed;
$border-grey: #979797;
$main-color: #333333;
$black: #000000;

.cc-page-designer {
  .cc-container {
    max-width: 1670px;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      padding: 0 rem(30);
    }
    @include media-breakpoint-down(xs) {
      padding: 0 rem(15);
    }
  }

  .cc-spacer {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .cc-hero {
    display: block;
    position: relative;

    .cc-content-img {
      .cc-img {
        display: block;
        width: 100%;
        margin: 0;
      }
    }

    .cc-content-elements {
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;

      .cc-content-text {
        padding: 0 rem(60);

        @include media-breakpoint-down(xs) {
          padding: 0;
        }

        .cc-hero-subtitle {
          font-family: "NotoSans-Italic", sans-serif;
          display: block;
          font-size: rem(18);
          line-height: rem(22);
          color: $white;
          text-align: left;
          letter-spacing: rem(0.2);
          text-transform: uppercase;
          padding-bottom: rem(15);
          margin: 0;

          @include media-breakpoint-down(md) {
            font-size: rem(12);
            line-height: rem(20);
            padding-bottom: rem(10);
          }
          @include media-breakpoint-down(xs) {
            line-height: rem(17);
          }
        }

        .cc-hero-title {
          display: block;
          font-weight: 400;
          font-size: rem(70);
          line-height: rem(75);
          color: $white;
          text-align: left;
          text-transform: uppercase;
          padding: 0;
          margin: 0;

          @include media-breakpoint-down(sm) {
            font-size: rem(35);
            line-height: rem(50);
          }
        }

        .btn {
          width: auto;
          display: inline-block;
    
          @include media-breakpoint-down(md) {
            font-size: rem(16);
            padding: rem(14) rem(33);
          }
          @include media-breakpoint-down(xs) {
            width: 100%;
            display: block;
            padding-left: rem(14);
            padding-right: rem(14);
          }
    
          &.btn-outline-primary {
            color:$white;
            border-color:$white;
            border-bottom: 1px solid;
            &:hover{
              background:transparent;
            }
          }
        }
        
        .cc-page-designer .cc-hero-grid .btn.btn-outline-primary {
          border-bottom: 1px solid $grey8;
        }

        .cc-content-icon {
          display: block;
          margin: 0;
          padding-top: rem(25);

          @include media-breakpoint-down(md) {
            padding-top: rem(10);
          }

          .cc-icon {
            display: block;
            width: 100%;
          }
        }
      }

      &.cc-content-elements-left {
        .cc-content-text {
          margin: 0;

          .cc-hero-subtitle {
            text-align: left;

            @include media-breakpoint-down(xs) {
              text-align: center;
            }
          }

          .cc-hero-title {
            text-align: left;

            @include media-breakpoint-down(xs) {
              text-align: center;
            }
          }

          .cc-hero-button {
            text-align: left;

            @include media-breakpoint-down(xs) {
              text-align: center;
            }
          }

          .cc-content-icon {
            margin: 0;

            @include media-breakpoint-down(xs) {
              margin: 0 auto;
            }
          }
        }
      }
      &.cc-content-elements-center {
        .cc-content-text {
          margin: 0 auto;

          .cc-hero-subtitle {
            text-align: center;
          }

          .cc-hero-title {
            text-align: center;
          }

          .cc-hero-button {
            text-align: center;
          }

          .cc-content-icon {
            margin: 0 auto;
          }
        }
      }
      &.cc-content-elements-right {
        .cc-content-text {
          margin: 0 0 0 auto;

          .cc-hero-subtitle {
            text-align: right;

            @include media-breakpoint-down(xs) {
              text-align: center;
            }
          }

          .cc-hero-title {
            text-align: right;

            @include media-breakpoint-down(xs) {
              text-align: center;
            }
          }

          .cc-hero-button {
            text-align: right;

            @include media-breakpoint-down(xs) {
              text-align: center;
            }
          }

          .cc-hero-description {
            text-align: right;
            float: right;

            @include media-breakpoint-down(xs) {
              text-align: center;
              width: auto;
              float: inherit;
            }
          }

          .cc-content-icon {
            margin: 0 0 0 auto;

            @include media-breakpoint-down(xs) {
              margin: 0 auto;
            }
          }
        }
      }
      &.Dark{
        .cc-hero-subtitle{
          color:$grey8;
        }
        .cc-hero-title{
          color:$grey8;
        }
        .cc-hero-description{
          color:$grey8;
        }
        .btn-outline-primary{
          border-color:$grey8;
          color:$grey8;
          &:hover{
            background:$grey8;
            color:$white;
          }
        }
      }
      &.small{
        .cc-hero-title{
          font-size:3rem;
          margin-bottom: 1rem;
          line-height:3rem;
        }
      }
      &.medium{
        .cc-hero-title{
          font-size:5rem;
          margin-bottom: 1rem;
          line-height:4rem;
          @include media-breakpoint-down(xs) {
            font-size:3rem;
            line-height:3rem;
          }
        }
      }
    }
  }

  .cc-hero-grid{
    display: block;
    position: relative;

    .cc-content-img {
      .cc-img {
        display: block;
        width: 100%;
        margin: 0;
      }
    }

    .cc-content-elements {
      pointer-events: none; 
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      &.Top{
        transform: inherit;
        top: 15%;
      }
      &.Bottom{
        transform: inherit;
        top: inherit;
        bottom: 10%;
      }
      @include media-breakpoint-down(sm) {
        &.valign-m-Top{
          transform: inherit;
          top: 15%;
        }
        &.valign-m-Center{
          top: 50%;
        }
        &.valign-m-Bottom{
          transform: inherit;
          top: inherit;
          bottom: 10%;
        }
      }
      .cc-content-text {
        padding: 0 rem(60);

        @include media-breakpoint-down(md) {
          padding: 0 rem(30);
        }

        @include media-breakpoint-down(xs) {
          padding: 0;
        }

        .cc-hero-subtitle {
          display: block;
          font-family: "NotoSans-LightItalic", sans-serif;
          font-weight: 400;
          font-size: rem(16);
          line-height: rem(22);
          color: $white;
          text-align: left;
          letter-spacing: rem(0.2);
          text-transform: uppercase;
          padding-bottom: rem(0);
          margin: 0;

          @include media-breakpoint-down(md) {
            font-size: rem(12);
            line-height: rem(20);
          }
          @include media-breakpoint-down(xs) {
            line-height: rem(17);
          }
        }

        .cc-hero-title {
          display: block;
          font-family: "NotoSans-Light", sans-serif;
          font-weight: 400;
          font-size: rem(55);
          line-height: rem(55);
          color: $white;
          text-align: left;
          text-transform: uppercase;
          padding: 0;
          margin: 10px 0;

          @include media-breakpoint-down(lg) {
            font-size: rem(25);
            line-height: rem(25);
          }
          @include media-breakpoint-down(md) {
            font-size: rem(25);
            line-height: rem(25);
          }
          @include media-breakpoint-down(xs) {
            font-size: rem(40);
            line-height: rem(40);
          }
        }
        .cc-hero-description{
          color:$white;
        }
        .cc-hero-button {
          display:table;
          transition: all 0.2s ease;
          position:relative;
          clear:both;
          &:after{
            content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-arrow-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/%3E%3C/svg%3E");
            font-size:24px;
            position: absolute;
            opacity: 0;  
            top: 15px;
            right: -30px;
            transition: 0.3s;
            @include media-breakpoint-down(md) {
              display:none !important;
            }
          }
        }
        @include media-breakpoint-down(lg) {
          .btn{
            padding-top: 1.1rem;
            padding-bottom: 1.1rem;
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 19px;
          }
        }
        

        .cc-content-icon {
          display: block;
          margin: 0;
          padding-top: rem(25);

          @include media-breakpoint-down(md) {
            padding-top: rem(10);
          }

          .cc-icon {
            display: block;
            width: 100%;
          }
        }
      }
      &.dwsmall{
        .cc-hero-description{
          width:28%;
          @include media-breakpoint-down(sm) {
            width:100%;
          }
        }
      }
      &.dwmedium{
        .cc-hero-description{
          width:50%;
        }
      }
      &.cc-content-elements-left {
        .cc-content-text {
          margin: 0;

          .cc-hero-subtitle {
            text-align: left;

            @include media-breakpoint-down(sm) {
              text-align: center;
            }
          }

          .cc-hero-title {
            text-align: left;

            @include media-breakpoint-down(sm) {
              text-align: center;
            }
          }

          .cc-hero-description {
            text-align: left;
            margin: 0 0 20px 0;

            @include media-breakpoint-down(sm) {
              text-align: center;
              width:auto;
            }
          }

          .cc-hero-button{
            @include media-breakpoint-down(sm) {
              margin:0 auto;
            }
          }
          .cc-content-icon {
            margin: 0;

            @include media-breakpoint-down(xs) {
              margin: 0 auto;
            }
          }
        }
      }
      &.cc-content-elements-center {
        .cc-content-text {
          margin: 0 auto;

          .cc-hero-subtitle {
            text-align: center;
          }

          .cc-hero-title {
            text-align: center;
          }

          .cc-hero-description {
            text-align: center;
            margin: 0 auto 20px auto;
          }

          .cc-hero-button{
            margin: 0 auto;
          }

          .cc-content-icon {
            margin: 0 auto;
          }
        }
      }
      &.cc-content-elements-right {
        .cc-content-text {
          margin: 0 0 0 auto;

          .cc-hero-subtitle {
            text-align: right;

            @include media-breakpoint-down(sm) {
              text-align: center;
            }
          }

          .cc-hero-title {
            text-align: right;

            @include media-breakpoint-down(sm) {
              text-align: center;
            }
          }

          .cc-hero-description {
            text-align: right;
            float: right;
            margin: 0 0 20px 0;

            @include media-breakpoint-down(sm) {
              text-align: center;
              float:inherit;
              width:auto;
            }
          }

          .cc-hero-button{
            float: right;
            @include media-breakpoint-down(sm) {
              float:inherit;
              margin:0 auto;
            }
          }
          .cc-content-icon {
            margin: 0 0 0 auto;

            @include media-breakpoint-down(xs) {
              margin: 0 auto;
            }
          }
        }
      }
      &.Dark{
        .cc-hero-subtitle{
          color:$grey8;
        }
        .cc-hero-title{
          color:$grey8;
        }
        .cc-hero-description{
          color:$grey8;
        }
        .cc-hero-button {
          color: $white;
          &:after{
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-arrow-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/%3E%3C/svg%3E");
          }
        }
        .btn-outline-primary{
          border-color:$grey8;
          color:$grey8;
          &:hover{
            background:$grey8;
            color:$white;
          }
        }
      }
    }
    &:hover{
      .cc-hero-button:after {
        opacity: 1 !important;
        right: 25px !important;
      }
    }

    .btn {
      width: auto;
      display: inline-block;

      @include media-breakpoint-down(md) {
        font-size: rem(16);
        padding: rem(14) rem(33);
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
        display: block;
        padding-left: rem(14);
        padding-right: rem(14);
      }

      &.btn-outline-primary {
        color:$white;
        border-color:$white;
        border-bottom: 1px solid;
        &:hover{
          background:transparent;
        }
      }
    }
  }

  .experience-commerce_layouts-listingTileM1D2{
    .row.cc-row{
      align-items: center;
    }
  }
  
  .cc-intro-text {
    display: block;
    background: $light-grey;
    padding: rem(90) 0;

    @include media-breakpoint-down(md) {
      padding: rem(60) 0;
    }
    @include media-breakpoint-down(xs) {
      padding: rem(30) 0;
    }

    .cc-content-text {
      &.center{
        text-align: center;
        .cc-intro-cta {
          text-align: center;
        }
      }
      &.left{
        text-align: left;
        .cc-intro-cta {
          text-align: left;
        }
      }
      &.right{
        text-align: right;
        .cc-intro-cta {
          text-align: right;
        }
      }
      &.Light{
        color:#fff;
      }

      img{
        max-width:100%;
        &.bottom{
          margin-top:rem(25);
        }
        &.top{
          margin-bottom:rem(25);
        }
      }
      .cc-content-subtitle {
        padding-bottom: rem(8);
        position: relative;

        @include media-breakpoint-down(md) {
          padding-bottom: rem(8);
        }

        &:after {
          content: "";
          display: inline-block;
          width: rem(120);
          height: 1px;
          background: $border-grey;
          position: relative;
        }
        &.Skjult{
          &:after{
            display:none;
          }
        }
      }

      .cc-intro-subtitle {
        font-family: "NotoSans-LightItalic", sans-serif;
        display: block;
        font-size: rem(16);
        line-height: rem(22);
        letter-spacing: rem(0.5);
        text-transform: uppercase;
        padding: 0;
        margin: 0;

        @include media-breakpoint-down(md) {
          font-size: rem(12);
          line-height: rem(20);
        }
        @include media-breakpoint-down(xs) {
          line-height: rem(17);
        }
      }

      .cc-intro-title {
        font-family: "NotoSans-Light", sans-serif;
        display: block;
        font-weight: 400;
        font-size: rem(50);
        letter-spacing: rem(3);
        line-height: rem(55);
        text-transform: uppercase;
        padding: 0;
        margin: 0;

        @include media-breakpoint-down(md) {
          font-size: rem(32);
          line-height: rem(37);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(28);
          line-height: rem(33);
        }
      }

      .cc-intro-text-field {
        font-family: "NotoSans-Light", sans-serif;
        display: block;
        font-size: rem(16);
        line-height: rem(28);
        letter-spacing: rem(0.2);
        padding: 0;
        margin: rem(15) 0 0 0;

        @include media-breakpoint-down(md) {
          font-size: rem(12);
          line-height: rem(24);
        }
      }

      .cc-intro-cta {
        margin-top: rem(50);

        @include media-breakpoint-down(md) {
          margin-top: rem(30);
        }

        .btn {
          width: auto;
          display: inline-block;

          @include media-breakpoint-down(md) {
            font-size: rem(16);
            padding: rem(14) rem(33);
          }
          @include media-breakpoint-down(xs) {
            width: 100%;
            display: block;
            padding-left: rem(14);
            padding-right: rem(14);
          }

          &.btn-outline-primary {
            border-bottom: 1px solid $black;
          }
        }
      }
    }
  }

  .cc-text-image {
    display: block;
    padding: rem(110) 0 rem(100) 0;

    @include media-breakpoint-down(md) {
      padding: rem(60) 0;
    }
    @include media-breakpoint-down(xs) {
      padding: rem(25) 0 rem(35) 0;
    }

    .Light{
      color:#fff !important;
      &:after{
        background: #fff !important;
      }
    }
    .cc-content-subtitle.Light:after{
        background: #fff !important;
    }
    .btn-outline-primary.Light{
      border:1px solid #fff !important;
    }

    .cc-content-elements {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }
    }

    .cc-content-img {
      width: 50%;
      max-width: 50%;
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-breakpoint-down(xs) {
        order: 1;
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        display: block;
      }

      .cc-img-container {
        margin: auto 0;

        @include media-breakpoint-down(xs) {
          margin: 0 rem(-15);
        }
      }

      .cc-img {
        display: block;
        width: 100%;
        margin: 0;
      }
    }

    .cc-content-text {
      width: 60%;
      max-width: 60%;
      flex: 0 0 60%;
      position: relative;
      z-index: 2;
      margin-right: -10%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-breakpoint-down(xs) {
        order: 2;
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        margin: 0;
        display: block;
      }

      .cc-box {
        padding: rem(70) rem(90);
        background: $light-grey;
        margin: auto 0;

        @include media-breakpoint-down(lg) {
          padding: rem(50) rem(60);
        }

        @include media-breakpoint-down(md) {
          padding: rem(30) rem(25);
        }
        @include media-breakpoint-down(xs) {
          margin: rem(-60) 0 0 0;
        }
      }

      .cc-content-subtitle {
        padding-bottom: rem(20);
        position: relative;
        margin-bottom: rem(20);

        @include media-breakpoint-down(md) {
          padding-bottom: rem(15);
          margin-bottom: rem(15);
        }

        &:after {
          content: "";
          display: block;
          width: rem(120);
          height: 1px;
          background: $border-grey;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .cc-text-image-subtitle {
        font-family: "NotoSans-LightItalic", sans-serif;
        display: block;
        font-size: rem(16);
        line-height: rem(22);
        color: $main-color;
        text-align: center;
        letter-spacing: rem(0.5);
        text-transform: uppercase;
        padding: 0;
        margin: 0;

        @include media-breakpoint-down(md) {
          font-size: rem(12);
          line-height: rem(20);
        }
        @include media-breakpoint-down(xs) {
          line-height: rem(17);
        }
      }

      .cc-text-image-title {
        font-family: "NotoSans-Light", sans-serif;
        display: block;
        font-weight: 400;
        font-size: rem(50);
        letter-spacing: rem(3);
        line-height: rem(55);
        color: $main-color;
        text-align: center;
        text-transform: uppercase;
        padding: 0;
        margin: 0;

        @include media-breakpoint-down(md) {
          font-size: rem(32);
          line-height: rem(37);
        }
        @include media-breakpoint-down(xs) {
          font-size: rem(28);
          line-height: rem(33);
        }
      }

      .cc-text-image-text {
        font-family: "NotoSans-Light", sans-serif;
        display: block;
        font-size: rem(16);
        line-height: rem(28);
        color: $main-color;
        text-align: center;
        letter-spacing: rem(0.2);
        padding: 0;
        margin: rem(15) 0 0 0;

        @include media-breakpoint-down(md) {
          font-size: rem(12);
          line-height: rem(24);
          margin-top: rem(10);
        }
      }

      .cc-text-image-content-cta {
        text-align: center;
        margin-top: rem(50);

        @include media-breakpoint-down(md) {
          margin-top: rem(30);
        }

        .btn {
          width: auto;
          display: inline-block;

          @include media-breakpoint-down(md) {
            font-size: rem(16);
            padding: rem(14) rem(33);
          }
          @include media-breakpoint-down(xs) {
            width: 100%;
            display: block;
            padding-left: rem(14);
            padding-right: rem(14);
          }

          &.btn-outline-primary {
            border-bottom: 1px solid $black;
          }
        }
      }
    }

    .cc-content-elements-reverse {
      .cc-content-img {
        order: 1;
      }

      .cc-content-text {
        order: 2;
        margin-right: 0;
        margin-left: -10%;

        @include media-breakpoint-down(xs) {
          margin: 0;
        }
      }
    }
  }

  .cc-listing-tile {
    overflow: hidden;

    .cc-content-listing {
      @include media-breakpoint-down(xs) {
        margin: 0 rem(-15);
      }
    }

    .cc-row {
      &:has(.cc-single-video-html-tile) {
        align-items: center;
      }
      @include media-breakpoint-down(xs) {
        margin-left: rem(-7);
        margin-right: rem(-7);
      }

      .cc-col {
        @include media-breakpoint-down(xs) {
          padding-left: rem(7);
          padding-right: rem(7);
        }
      }
    }

    .cc-tile {
      padding-bottom: rem(60);

      @include media-breakpoint-down(md) {
        padding-bottom: rem(40);
      }

      .cc-single-tile {
        .cc-tile-link {
          display: block;
          text-decoration: none;
          border: none;
          &:hover{
            .cc-content-textlink{
              padding-left:10px;
            }
          }
        }
        .cc-content-textlink {
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 0.9rem;
          color: $black;
          padding: 15px 0px;
          transition: all 0.2s ease;
          @include media-breakpoint-down(xs) {
            font-size: 0.8rem;
          }
          svg{
            width: 22px;
            height: 22px;
            margin-top: -4px;
          }
        }

        .cc-content-img {
          display: block;
          margin: 0;

          .cc-img {
            display: block;
            width: 100%;
            margin: 0;
          }
        }

        .cc-content-icon {
          display: block;
          margin: 0;

          .cc-icon {
            display: block;
            width: 100%;
            margin: 0;
          }
        }
      }

      .cc-single-video-tile {
        .cc-content-video {
          position: relative;
          padding-bottom: 56.25%;
  
          .cc-iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        
        .cc-content-title {
          margin-top: 1.25rem;
          text-align: center;
          text-transform: uppercase;
        }
      }
      
      .cc-single-video-html-tile {
        display: flex;
        align-items: center;

        &.center {
          justify-content: center;
        }

        &.right {
          justify-content: flex-end;
        }

        a{
          border:0px;
        }
        
        .cc-content-video {
          position: relative;
          padding-bottom: 0;
        }
        
        .cc-content-title {
          text-align: left;
          position: absolute;
          padding-right: 15px;
          padding-left: 15px;

          @include media-breakpoint-up(sm) {
            padding-right: 30px;
            padding-left: 30px;
          }

          &.Light {
            color: $white;
          }
        }

        .video-desktop{
          &.video-mobile-true{
            @include media-breakpoint-down(xs) {
              display:none;
            }
          }
        }
        .video-mobile{
          display:block;
          @include media-breakpoint-up(sm) {
            display:none;
          }
        }
      }

      .cc-single-image-tile {
        .cc-content-img {
          display: block;
          margin: 0;

          .cc-img {
            display: block;
            width: 100%;
            margin: 0;
          }
        }
        
        .cc-content-title {
          margin-top: 1.25rem;
          text-align: center;
          text-transform: uppercase;
        }
      }

      .cc-single-product-tile {
        .product-tile {
          border: none;
          background: transparent;
          margin: 0;
          padding: 0;
          min-height: initial;
          position: relative;

          .quickview {
            right: 0;
            top: rem(10);
          }

          .tile-body {
            padding: rem(20) 0 0 0;

            @include media-breakpoint-down(md) {
              padding: rem(17) 0 0 0;
            }
            @include media-breakpoint-down(xs) {
              padding: rem(10) rem(10) 0 rem(10);
            }

            .pdp-link {
              @include media-breakpoint-down(md) {
                margin-bottom: rem(10);
              }
              @include media-breakpoint-down(xs) {
                margin-bottom: rem(4);
              }
            }

            .brand-box a.h7,
            .brand-box a.h8 {
              padding-right: rem(40);

              @include media-breakpoint-down(md) {
                font-size: rem(12);
                line-height: rem(20);
              }
              @include media-breakpoint-down(xs) {
                font-size: rem(10);
                line-height: rem(14);
                padding-right: 0;
              }
            }

            .price .h5 {
              @include media-breakpoint-down(md) {
                font-size: rem(16);
                line-height: rem(24);
                letter-spacing: rem(0.2);
              }
              @include media-breakpoint-down(xs) {
                font-size: rem(12);
                line-height: rem(20);
              }
            }
          }
        }

        .tile-image {
          max-width: initial;
          max-height: initial;
        }
      }
    }

    .product-tile .tile-body .brand-box a {
      font-family: "NotoSans-Light", sans-serif;
    }
  }

  .usp-frontpage{
    .cc-tile{
      border-right:1px solid $light-grey;
      &:last-child{
        border:0px;
      }
      @include media-breakpoint-down(sm) {
        &:nth-last-child(3){
          border-bottom:1px solid $light-grey;
        }
        &:nth-last-child(4){
          border-bottom:1px solid $light-grey;
        }
      }
    }
    img{
      margin-right:10px;
    }
    .image{
      display: flex;
      align-items: center;
      align-items: center;
      justify-content: center;
      padding:30px 0px 30px 0px;

      img{
        max-width:50px;
      }
    }
    .text{
      font-family: sans-serif;
      font-size:13px;
      line-height: 18px;
    }

    @include media-breakpoint-down(sm) {
      .image{
          display: flex;
          align-items: center;
          padding:20px 0px 20px 0px !important;
      }
      .image img{
        max-width:35px;
      }
    }
  }

  .cc-single-video-html-tile {
    display: flex;
    align-items: center;

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }

    .cc-content-video {
      width: 100%;
    }

    .cc-content-title {
      text-align: left;
      position: absolute;
      padding-right: 15px;
      padding-left: 15px;

      @include media-breakpoint-up(sm) {
        padding-right: 30px;
        padding-left: 30px;
      }

      &.Light {
        color: $white;
      }
    }
  }
}
