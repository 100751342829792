@import "../utilities/swatch";
@import "productCommon";
@import "quickView";
@import "plp";

.product-tile {
  @include media-breakpoint-down(md) {
    min-height: 23.4375em;
  }

  @include media-breakpoint-down(sm) {
    min-height: 19.6875em;
  }

  @include media-breakpoint-down(xs) {
    min-height: 13.4375em;
  }

  border: 0;
  margin-bottom: 0;

  .color-swatches {
    margin-bottom: rem(10);
    display: flex;
    align-items: flex-end;
    min-height: 39px;
    @include media-breakpoint-down(sm) {
      min-height: 24px;
    }

    .product-tile-color-label {
      cursor: pointer;
      font-size: 1em;

      @include media-breakpoint-down(md) {
        font-size: 0.9375em;
      }

      @include media-breakpoint-down(sm) {
        font-size: 0.8125em;
      }
    }

    .swatches {
      display: flex;
      > a {
        margin-right: rem(8);
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .tile-body {
    position: relative;
    padding-top: rem(5);
    padding-bottom: rem(73);
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-down(lg) {
      padding-top: rem(23);
      padding-bottom: rem(40);
    }

    @include media-breakpoint-down(sm) {
      padding-left: rem(6);
      padding-right: rem(6);
      padding-top: rem(9);
      padding-bottom: rem(35);
    }

    .price {
      font-size: 1.125em;
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        font-size: 1.0625em;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1em;
      }

      .tiered {
        font-size: 0.875em;

        .value {
          font-weight: bold;
        }
      }

      .h5 {
        display: inline-block;
        margin-bottom: 0;
        font-weight: bold;
        font-size: rem(24);
        @include media-breakpoint-down(lg) {
          font-size: rem(19);
        }
        @include media-breakpoint-down(sm) {
          font-size: rem(15);
          line-height: 1;
        }
        &.strike-through {
          font-family: "NotoSans-Italic", sans-serif;
          margin-left: rem(10);
          margin-right: 0;
          text-decoration-line: inherit;
          color: $medium-grey;
          font-weight: 400;
          .d-lg-block{
            display: none !important;
          }
          @include media-breakpoint-down(sm) {
            margin-left: rem(7);
            letter-spacing: 0;
          }
        }
      }
    }

    .coming-soon-tile {
      text-align: center;
    }

    .quickview-brand {
      position: absolute;
      top: rem(18);
      right: rem(10);
      svg {
        width: rem(20);
        height: rem(20);
        @include media-breakpoint-down(lg) {
          transform: translateY(-3px) scale(0.85);
          transform-origin: center top;
        }
      }
    }

    .brand-box {
      margin-bottom: rem(3);
      .col-12 {
        @include media-breakpoint-down(sm) {
          line-height: 1;
        }
      }
      a {
        font-family: "NotoSans-Regular", sans-serif;
        color: #333 !important;
        text-transform: uppercase;
        font-size: rem(16);
        margin-bottom: rem(1);
        @include media-breakpoint-down(sm) {
          line-height: 0;
          font-size: rem(10);
        }
        &.h8 {
          display:inline-block;
          text-transform: uppercase;
          margin-bottom: 0;
          line-height: 1;
          color: #333 !important;
          @include media-breakpoint-down(lg) {
            font-size: rem(13);
          }
          @include media-breakpoint-down(sm) {
            font-size: rem(10);
          }
        }
      }
    }

    .pdp-link {
      line-height: 1;
      margin-bottom: rem(25);
      @include media-breakpoint-down(lg) {
        margin-bottom: rem(21);
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: rem(14);
      }

      a {
        text-decoration: none;
        font-size: rem(16);

        @include media-breakpoint-down(md) {
          font-size: 0.9375em;
        }

        @include media-breakpoint-down(sm) {
          font-size: 0.8125em;
        }

        &.h8 {
          line-height: rem(24);
          margin-bottom: 0;
          color: #333 !important;
          @include media-breakpoint-down(lg) {
            font-size: rem(13);
            line-height: rem(22);
          }
          @include media-breakpoint-down(sm) {
            font-size: rem(10);
            line-height: rem(15);
          }
        }

        &.quickview {
          position: absolute;
          top: rem(8);
          right: rem(10);
          svg {
            width: rem(26);
            height: rem(28);
            @include media-breakpoint-down(lg) {
              transform: translateY(-3px) scale(0.85);
              transform-origin: center top;
            }
          }
        }
      }
    }


    .ratings {
      font-size: 0.9em;
    }
  }

  .image-container {
    position: relative;
    overflow: auto;
    background-color: #f3f3f3;

    .quickview {
      position: absolute;
      bottom: 1rem;
      right: 1rem;

      i {
        &.fa-expand {
          color: rgba(0, 0, 0, 0.7);
        }

        &.fa-circle {
          color: rgba(255, 255, 255, 0.7);
          text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        }
      }
    }

    a {
      display: block;

      .tile-image {
        width: 100%;
        max-width: rem(387);
        max-height: rem(387);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .swatches {
    a {
      text-decoration: none;
    }
  }

  .swatch-circle {
    // $white will be replaced by color image background via Javascript
    @include swatch(1.8em, $white);
  }
}

#chooseBonusProductModal {
  .modal-footer {
    .container {
      margin-left: 0;
      width: 100%;
      margin-right: 0;
    }
  }

  .select-cbp-container {
    margin-top: auto;
    margin-bottom: auto;
  }

  .product-name-wrapper {
    width: 100%;
  }

  .bonus-quantity,
  .bonus-option {
    margin-top: 0.938em;
  }

  .bonus-quantity-select {
    min-width: 5em;
  }

  .select-bonus-product {
    margin-top: 1em;
  }

  .selected-pid {
    border: 1px solid $grey3;

    .bonus-product-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .bonus-product-price {
    text-align: center;
    margin-top: 1em;
  }
}

/* PLP SHORTCUTS */
.plp-shortcut{
  margin: 0px 0 10px 0;
  padding-left:0px;
  padding-right:0px;
  @media only screen and (min-width: 768px) and (max-width: 1920px) {
    padding-left:15px;
    padding-right:15px;
  }
  @include media-breakpoint-down(xs) {
    padding:0 10px 0 10px;
  }
}
.plp-shortcut ul{padding:0px;}
.plp-shortcut ul li{
    display: inline-block;
    width:16.666666%;
    margin:0px 0px 10px 0px;
    box-sizing: border-box;
    float:left;
}

.plp-shortcut .shortcut-box{
    border:1px solid $grey2;
    background:$grey1;
    padding:5px;
    display:block;
    margin-right:15px;
    min-height: 52px;
    &:hover{
      border:1px solid $grey3;
    }
}
@media only screen and (min-width: 1200px){
    .plp-shortcut ul li:nth-child(6n) .shortcut-box{
        margin-right:0px;
    }
}
@media only screen and (max-width: 768px) {
    .plp-shortcut ul li{
        width:50%;
    }
    .plp-shortcut ul li:nth-child(2n) .shortcut-box{
        margin-right:0px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .plp-shortcut ul li{
        width:25%;
    }
    .plp-shortcut ul li:nth-child(4n) .shortcut-box{
        margin-right:0px;
    }
}
.plp-shortcut .shortcut-image{
  display:none;
}
.plp-shortcut .shortcut-image img{
    width:40px;
    background:$grey2;
}
.plp-shortcut .shortcut-title{
    display: inline-block;
    vertical-align: middle;
    padding-left: 8px;
    padding-top: 6px;
    width: calc(100% - 0px);
    line-height: 1.0rem;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333 !important;
    font-size: 0.8rem;
    font-weight: 700;
    letter-spacing: .05rem;
}  

.container .breadcrumb .breadcrumb-item a:before{
  background-image: none !important;
}
.container .breadcrumb .breadcrumb-item a{
  padding-left:0px !important;
}
.plp-breadcrumb{
  @media only screen and (min-width: 1920px){
    padding:0px 0px;
  }
  @media only screen and (max-width: 768px){
    padding:0px 0px 15px 0px;
  }
  @media only screen and (max-width: 543px){
    padding:0px 10px 15px 10px;
  }
  .col{
    padding:0px;
  }
  .breadcrumb{
    border:0px !important;
    padding:0px;
  }
}

.result-count{
  padding:0px;
  @media only screen and (min-width: 780px) and (max-width: 1920px) {
    padding-left:15px;
    padding-right:15px;
  }
  @include media-breakpoint-down(xs) {
    padding:0 10px 0px 5px;
  }
}

.search-results .grid-header {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* PLP IMAGE HOVER */
.secondary{
  position: absolute;
  top: 0px;
  left: 0px;
  transition: all 0.2s ease;
  transition-delay: 0.2s;
  opacity:0;
}
.tile-image:hover .secondary{
  opacity:1;
}
.secondary img{}


/* PRODUCT TILE - TITLE AND PRICE */
.product-tile .tile-body .price{
  border-top:1px solid $grey3;
  border-bottom:1px solid $grey3;
  min-height: 78px;
  @include media-breakpoint-down(sm){
    min-height: 52px;
  }
  padding:10px 0;
}

.h5.sales, .h5.list {
  display:block !important;
}
.h5.sales .text{
  float:left;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  @include media-breakpoint-down(sm){
    font-size: .8rem;
    margin-bottom:5px;
  }
}
.h5.sales .value{
  float:right;
  @include media-breakpoint-down(sm){
    font-size: .9rem;
    margin-bottom:5px;
  }
}
.price del{
  display:flow-root;
  clear:both;
}
.price del .text{
  float:left;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  @include media-breakpoint-down(sm){
    font-size: .7rem;
  }
}
.price del .value{
  float:right;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  &.before {
    text-decoration: line-through;
  }
  @include media-breakpoint-down(sm){
    font-size: .7rem;
  }
}

.price .range, .price .range span{
  display:block !important;
  padding:0px !important;
}

.product-detail{
  .price .range{
    span.text{
      margin-right: 10px;
      margin-top: 3px;
    }
  }
  .h5.sales .text {
    float: inherit !important;
  }
  .h5.sales .value {
    float: inherit !important;
    @include media-breakpoint-down(md){
        font-size:2rem;
    }
  }
  .prices .price>span .strike-through {
    float: left !important;
    @include media-breakpoint-down(md){
      float: inherit !important;
    }
  }
  .price del .value {
    float: inherit;
    @include media-breakpoint-down(md){
      font-size:1rem;
    }
  }
  .prices .price>span .h5.sales {
    margin-bottom: 0.375rem !important;
  }
}

.product-tile .tile-body .price .h5.strike-through {
  margin-left: 0rem;
  display: block;
}
.pdp-link a{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}