@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "productCard";

.minicart {
  position: relative;

  h1 {
    font-size: 1rem;
    &.h8 {
      letter-spacing: 0.05em;
      line-height: 1;
      color: $main-color;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .cart {
    padding-top: rem(10);
    padding-left: rem(24);
    padding-right: rem(24);
    padding-bottom: rem(24);
    background-color: #f5f5f5;
    height: rem(673);
    display: flex;
    flex-direction: column;

    .minicart-top-part {
      flex: 1;
      overflow: hidden;
      padding-bottom: rem(50);
      &.higher {
        padding-bottom: rem(195);
      }
      .minicart-error.cart-error {
        .alert {
          margin-top: rem(24);
          margin-bottom: rem(24);
          background: #edd3d2;
          font-size: rem(16);
          line-height: rem(24);
          color: $mexican-red;
          padding-right: rem(40);
          button {
            width: rem(16);
            height: rem(16);
            top: rem(7);
            right: rem(15);
            position: absolute;
            margin: 0;
            padding: 0;
            &:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.922' height='15.922'%3E%3Cpath data-name='Tracciato 523' d='M15.92.73L15.19 0 7.96 7.231.729 0l-.73.73L7.23 7.961l-7.231 7.231.73.73L7.96 8.691l7.23 7.231.73-.73-7.23-7.231z' fill='%23ba6c68'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              width: rem(16);
              height: rem(16);
            }
            span {
              display: none;
            }
          }
        }
      }
    }
  }

  .remove-line-item {
    position: relative;
    width: rem(16);
    height: rem(16);
  }
  .remove-btn {
    border: none;
    padding: 0;
    position: absolute;
    width: rem(16);
    height: rem(16);
    top: rem(7);
    right: 0;
    background-color: $white;
    &:active,
    &:focus {
      box-shadow: none !important;
      background-color: transparent !important;
    }
    span {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      width: rem(16);
      height: rem(16);
      top: 0;
      right: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.921' height='15.922'%3E%3Cpath data-name='Tracciato 199' d='M15.92.73L15.19 0 7.96 7.231.729 0l-.73.73L7.23 7.961l-7.231 7.231.73.73L7.96 8.691l7.23 7.231.73-.73-7.23-7.231z' fill='%232d2d2d'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
    }
  }

  .product-summary {
    margin-top: rem(21);
    max-height: 21.875em;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100%;
    max-height: 100%;
  }

  .card {
    border: none;
    margin-bottom: rem(23);
  }

  .card-body {
    padding-top: rem(5);
    padding-left: rem(17);
    padding-right: rem(17);
    padding-bottom: rem(24);
    background-color: $white;
  }

  .product-line-item {
    .line-item {
      &-name {
        span {
          font-weight: 400;
          font-size: rem(16);
          letter-spacing: 0.1em;
          line-height: rem(40);
          text-transform: uppercase;
        }
      }
    }
    .product-line-item-details {
      overflow: auto;
      .item-image {
        width: auto;
        height: auto;
        margin-right: rem(20);
        .product-image {
          max-width: rem(116);
          max-height: rem(116);
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .item-attributes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          font-size: rem(16);
          line-height: rem(28);
          &.not-in-stock {
            color: $mexican-red;
          }
        }
      }
      & + div {
        width: calc(100% + 34px);
        height: rem(22);
        transform: translateX(-17px);
        border-bottom: 1px solid #eae8e8;
      }
    }
  }

  .product-data {
    padding-top: rem(22);
    padding-left: rem(17);
    padding-right: rem(17);
    .col-4 {
      padding: 0;
      .unit-price {
        .price {
          span {
            font-family: "NotoSans-Regular", sans-serif;
            display: flex;
            font-weight: 700;
            flex-direction: column;
            float:none;
            font-size:14px;
            line-height:1.75rem;
            .h5.sales {
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                font-size: rem(20);
                letter-spacing: 0.02em;
                line-height: rem(28);
                color: $black;
                margin-bottom: 0;
                span{
                    font-weight: normal;
                }
                span:nth-child(2){
                    font-size: 1.25rem;
                    letter-spacing: 0.02em;
                    line-height: 1.75rem;
                    font-weight: 700;
                }
            }
            .value{
                    text-decoration: line-through;
                span{
                    line-height: 1;
                }
            }
            .before.memberprice .value{
              text-decoration: none;
              font-style:italic;
            }
            del {
              order: -1;
              text-decoration: none;
              .strike-through,
              .strike-through span {
                display: inline-block;
                font-size: rem(14);
                letter-spacing: 0.02em;
                line-height: rem(28);
                color: $main-color;
                opacity: 0.5;
                margin-right: 0;
                margin-bottom: 0;
                text-decoration: none;
                .value.special {
                  visibility: hidden;
                }
              }
            }
          }
        }
      }
      .quantity-form {
        select.form-control.quantity {
          width: auto;
          padding-left: rem(42);
          padding-right: rem(47);
          text-align-last: center;
        }
      }
      &.line-item-total-price {
        text-align: right;
        .line-item-total-price-label {
          font-size: rem(14);
          letter-spacing: 0.02em;
          line-height: rem(28);
          color: $main-color;
        }
      }
      .line-item-total-price-amount {
        font-weight: 700;
        font-size: rem(20);
        letter-spacing: 0.02em;
        line-height: rem(28);
        color: $black;
        margin-bottom: 0;
      }
    }
  }

  .line-item-promo {
    margin-top: rem(22);
    > div {
      background-color: $mexican-red;
      color: $white;
      letter-spacing: 0.05em;
      line-height: rem(22);
      margin-bottom: 0;
      text-transform: uppercase;
      padding-top: rem(4);
      padding-bottom: rem(4);
      padding-left: rem(15);
      padding-right: rem(15);
      text-align: center;
    }
  }

  .quantity-label {
    font-size: 0.813em;
  }

  .quantity {
    width: 100%;
  }

  .popover {
    top: rem(32);
    left: auto;
    right: rem(15);
    border-radius: 0;
    min-height: rem(673);
    min-width: rem(553);
    max-width: rem(553);
    display: none;

    @media screen and (max-width: 1536px) {
      transform: scale(0.8);
      transform-origin: top right;
    }

    @media screen and (max-width: 1024px) {
      display: none !important;
    }

    &::before {
      left: auto;
      right: 15px;
    }

    &::after {
      left: auto;
      right: 16px;
    }

    &.show {
      display: block;
    }
  }

  .estimated-total {
    margin-top: rem(34);
    @media screen and (max-width: 1440px) {
      margin-top: rem(24);
    }
  }

  .sub-total-label,
  .sub-total {
    font-family: "NotoSans-Regular", sans-serif;
    font-weight: 700;
    font-size: rem(20);
    letter-spacing: 0.02em;
    line-height: rem(28);
    margin-bottom: rem(18);
  }

  .line-item-divider {
    margin: 0.625em -0.625em 0.625em -1.325em;
  }

  .line-item-name {
    width: 90%;
  }
  .line-item-availability .line-item-attributes{
      color:$green !important;
        svg{
          width: 7px;
          margin-top: -3px;
        }
        &.not-in-stock{
          color:$mexican-red !important;
        }
  }
  .quantity-subtract{
      border-right: 1px solid #f0f0f0;
      padding: 8px 15px 8px 15px;
  }
  .quantity-add{
      border-left: 1px solid #f0f0f0;
      padding: 8px 15px 8px 15px;
  }
  .quantity-form > label.line-item-pricing-info.quantity-label{
      display:none !important;
  }
  .card.product-info .line-item-divider{
      margin:25px 0;
  }
  .quantity-cart .quantity-input {
      height: 4.5625rem;
  }
  @include media-breakpoint-only(lg) { 
      .quantity-cart .quantity-input {
          height: 4.5625rem;
      }
  }
  @include media-breakpoint-down(md) {
      .quantity-cart .quantity-input {
          height: 4.5625rem;
      }
  }
  
  @include media-breakpoint-down(sm) {
      .quantity-cart .quantity-input {
          height:42px;
      }
      .quantity-cart .quantity-input {
          height:42px !important;
      }
      .quantity-subtract,
      .quantity-add{
          padding: 5px 15px 5px 15px !important;
      }
  }
  .quantity-cart .quantity-input{
      height:60px;
      border:1px solid #f0f0f0;
      border-radius:3px;
      background:#fff !important;
  }  
}

.hide-link-med {
  @include media-breakpoint-only(sm) {
    display: none;
  }
}

.hide-no-link {
  @include media-breakpoint-up(md) {
    display: none;
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }
}
