@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

//VAR
$white: #fff;
$light-grey: #EDEDED;
$border-grey: #979797;
$main-color: #333333;
$black: #000000;
$dark-grey: #797979 !default;
$form-grey: #D3D3D3;

.cc-footer-page {
    
    .cc-container {
        max-width: 1670px;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            padding: 0 rem(30);
        }
        @include media-breakpoint-down(xs) {
            padding: 0 rem(15);
        }
    }


    &.cc-contact-us-page {
        background: #F5F5F5;
    }

    .cc-content-hero {
        display: block;
        position: relative;
        margin-bottom: rem(50);

        @include media-breakpoint-down(lg) {
            margin-bottom: rem(22);
        }

        .cc-content-hero-img {
            display: block;
            width: 100%;
            min-height: rem(183);

            .cc-hero-img {
                display: block;
                width: 100%;
                height: 100%;
                min-height: rem(183);
                object-fit: cover;
                margin: 0;
                padding: 0;
            }
        }

        .cc-content-hero-text {
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);

            .cc-hero-text {
                display: block;
                font-weight: 400;
                font-size: rem(120);
                line-height: rem(125);
                color: $white;
                text-align: left;
                text-transform: uppercase;
                padding: 0;
                margin: 0;
                text-transform: uppercase;

                @include media-breakpoint-down(lg) {
                    font-size: rem(70);
                    line-height: rem(85);
                    max-width: rem(804);
                    margin: 0 auto;
                }
                @include media-breakpoint-down(xs) {
                    font-size: rem(28);
                    line-height: rem(32);
                    text-align: center;
                }
            }
        }

        &--contact {
            margin-bottom: rem(58);

            @include media-breakpoint-down(lg) {
                margin-bottom: rem(50);
            }
            @include media-breakpoint-down(xs) {
                margin-bottom: rem(28);
            }
        }
    }

    .cc-content-breadcrumbs {
        margin-bottom: rem(50);

        @include media-breakpoint-down(xs) {
            display: none;
        }

        .breadcrumb {
            border: none;
            padding: 0;
            margin: 0;
        }
    }

    .cc-content-faq {
        margin-bottom: rem(180);

        @include media-breakpoint-down(lg) {
            margin-bottom: rem(80);
        }
        @include media-breakpoint-down(xs) {
            margin-bottom: rem(50);
        }

        .cc-faq-title {
            display: block;
            font-weight: 400;
            font-size: rem(34);
            line-height: rem(45);
            color: $black;
            text-align: left;
            text-transform: uppercase;
            padding: 0 0 rem(40) 0;
            margin: 0;

            @include media-breakpoint-down(lg) {
                padding-bottom: rem(35);
            }
            @include media-breakpoint-down(xs) {
                font-size: rem(28);
                line-height: rem(34);
                padding-bottom: rem(20);
            }
        }

        .cc-accordion-item {
            border-top: 1px solid $border-grey;

            @include media-breakpoint-down(xs) {
                margin-left: rem(-15);
                margin-right: rem(-15);
                border-top-color: $form-grey;
            }

            &:last-child {
                border-bottom: 1px solid $border-grey;

                @include media-breakpoint-down(xs) {
                    border-bottom-color: $form-grey;
                }
            }

            .cc-accordion-button {
                display: block;
                background: transparent;
                position: relative;
                border: none;
                cursor: pointer;
                appearance: none;
                width: 100%;
                padding: rem(28) rem(50) rem(28) 0;
                margin: 0;
                font-size: rem(22);
                line-height: rem(30);
                color: $black;
                text-align: left;
                letter-spacing: rem(0.2);
                outline: none;

                @include media-breakpoint-down(xs) {
                    padding: rem(16) rem(35) rem(16) rem(15);
                    font-size: rem(12);
                    line-height: rem(24);
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    margin-top: -8px;
                    right: 28px;
                    transform: rotate(90deg);
                    width: 1px;
                    height: 17px;
                    background: $black;
                    z-index: 2;
                    -webkit-transition: all .2s ease-out;
                    -moz-transition: all .2s ease-out;
                    -o-transition: all .2s ease-out;
                    -ms-transition: all .2s ease-out;
                    transition: all .2s ease-out;

                    @include media-breakpoint-down(xs) {
                        right: 23px;
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    margin-top: 0;
                    width: 17px;
                    height: 1px;
                    background: $black;
                    z-index: 2;

                    @include media-breakpoint-down(xs) {
                        right: 15px;
                    }
                }

                &.collapsed {
                    &:before {
                        display: block;
                        transform: rotate(0);
                    }
                }
            }

            .cc-accordion-body {
                .cc-accordion-text {
                    display: block;
                    font-size: rem(16);
                    line-height: rem(28);
                    color: $main-color;
                    text-align: left;
                    letter-spacing: rem(0.2);
                    padding: rem(20) rem(300) rem(65) rem(140);
                    margin: 0;

                    a {
                        font-size: inherit;
                    }

                    @include media-breakpoint-down(lg) {
                        padding: 0 rem(90) rem(65) 0;
                    }
                    @include media-breakpoint-down(xs) {
                        padding: 0 rem(50) rem(40) rem(15);
                        font-size: rem(12);
                        line-height: rem(24);
                    }
                }
            }
        }
    }

    .cc-content-contact-us {
        padding-bottom: rem(110);

        @include media-breakpoint-down(xs) {
            padding-bottom: rem(30);
        }

        .cc-contact-us-section {
            background: $white;
            padding: rem(40) rem(40) rem(70) rem(40);
            max-width: rem(804);
            margin: 0 auto rem(30) auto;

            @include media-breakpoint-down(xs) {
                padding: rem(20) rem(15) rem(25) rem(15);
                margin-bottom: rem(25);
            }

            &--faq {
                padding-top: 0;
            }

            .cc-contact-us-section-title {
                display: block;
                font-weight: 400;
                font-size: rem(34);
                line-height: rem(40);
                color: $black;
                text-align: left;
                letter-spacing: rem(1);
                text-transform: uppercase;
                padding: 0;
                margin: 0 rem(-40) rem(25) rem(-40);
                padding: rem(20) rem(40) rem(17) rem(40);
                border-bottom: 1px solid #EAE8E8;
                margin-bottom: rem(25);

                @include media-breakpoint-down(xs) {
                    margin: 0 rem(-15) rem(25) rem(-15);
                    padding: rem(20) rem(15) rem(16) rem(15);
                    font-size: rem(22);
                    line-height: rem(30);
                }
            }

            .cc-contact-us-text {
                display: block;
                font-size: rem(16);
                line-height: rem(28);
                color: $black;
                text-align: left;
                letter-spacing: rem(0.2);
                padding: 0;
                margin: 0;

                @include media-breakpoint-down(xs) {
                    font-size: rem(14);
                }

                &--intro {
                    margin-bottom: rem(35);
                }
            }

            .form-group {
                margin-bottom: rem(25);

                @include media-breakpoint-down(xs) {
                    margin-bottom: rem(15);
                }
            }

            .form-control {
                @include media-breakpoint-down(xs) {
                    height: rem(60);
                    font-size: rem(14);
                    padding-left: rem(20);
                    padding-right: rem(20);
                }
            }

            label {
                display: block;
                font-size: rem(16);
                line-height: rem(28);
                color: $main-color;
                text-align: left;
                letter-spacing: rem(0.2);
                padding: 0 0 rem(4) 0;
                margin: 0;

                @include media-breakpoint-down(xs) {
                    font-size: rem(14);
                    padding-bottom: rem(2);
                }
            }

            .required label {
                &:before {
                    content: '*';
                    display: inline;
                    margin-right: rem(6);
                    font-size: rem(16);
                    line-height: rem(28);
                    color: #BA6C68;

                    @include media-breakpoint-down(xs) {
                        font-size: rem(14);
                    }
                }
            }

            textarea {
                padding-top: rem(15);
                padding-bottom: rem(15);
                height: rem(180);
                resize: none;

                @include media-breakpoint-down(xs) {
                    height: rem(165) !important;
                }
            }

            .cc-form-content-button {
                padding-top: rem(15);

                @include media-breakpoint-down(xs) {
                    padding-top: rem(10);
                }

                .btn {
                    display: block;
                    width: 100%;

                    @include media-breakpoint-down(xs) {
                        font-size: rem(20);
                        padding-top: rem(20);
                        padding-bottom: rem(20);
                    }
                }
            }
        }
    }
}