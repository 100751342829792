@import "../utilities/swatch";
@import "productCommon";
@import "../setup/settings";
@import "quickPdp";

.quick-view-dialog,
.choose-bonus-product-dialog {
  max-width: 56.25em;

  .selectable-bonus-product-line-item {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }

  .beenSelected {
    background-color: $grey2;
  }

  .modal-header {
    background-color: $grey2;
    border-bottom: 2px solid #ccc;
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem;

    .full-pdp-link {
      color: #{var(--skin-primary-color-1)};
    }

    .close {
      font-size: 2rem;
      line-height: 1.5rem;
    }
  }

  .modal-title {
    font-size: 1em;
  }

  .product-name {
    font-size: 1.875em;
  }

  .swatch-circle {
    // $white will be replaced by color image background via Javascript
    @include swatch(2.5em, $white);
  }

  a[disabled] .swatch-circle {
    cursor: not-allowed;

    &.color-value.selected::after {
      background-color: $gray-700;
    }
  }

  .availablity-container {
    text-align: right;
  }

  .availablity-container,
  .size-chart {
    margin-top: 0.938em;
  }

  .modal-content {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .modal-body {
    max-height: 28.125em; /* 450/16 */
    overflow-y: auto;
  }

  button.close {
    font-size: 1.25em;
  }

  .modal-footer {
    background-color: $white;
    border: none;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem;

    .prices .price {
      font-size: 1.6em;
    }
  }

  .prices .sales {
    font-size: 1.5rem;
  }

  .promotions {
    text-align: left;
    color: $red;
  }

  .bonus-summary {
    @include media-breakpoint-down(sm) {
      font-size: 0.625em;
    }

    .bonus-product-name {
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
  }

  .pre-cart-products {
    margin-right: 0.125em;
  }

  .color-attribute {
    border: none;
    padding: 0;
    background: none;
  }

  .non-input-label {
    display: block;
    margin-bottom: 0.5rem;
  }
}

#quickViewModal,
#editProductModal,
#editWishlistProductModal {
  .modal {
    &-dialog {
      max-width: rem(1445);
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      margin: 0;
      position: absolute;
      @media screen and (max-width: 1680px) {
        transform: translate(-50%, -50%) scale(0.7);
      }
      @include media-breakpoint-down(lg) {
        transform: translate(-50%, -50%) scale(0.6);
      }
    }
    &-content {
      width: rem(1445);
      height: rem(889);
    }
    &-header {
      padding-top: rem(38);
      padding-bottom: rem(37);
      padding-left: rem(34);
      padding-right: rem(34);
      position: relative;
      background-color: $white;
      border-bottom: 1px solid $border-grey;
      .full-pdp-link {
        font-size: rem(16);
        letter-spacing: 0.02em;
        line-height: rem(28);
        color: $main-color;
        position: relative;
        padding-left: rem(57);
        &:focus {
          outline: none;
        }
        &:before {
          content: "";
          position: absolute;
          width: rem(29);
          height: rem(29);
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg data-name='Componente 55 – 1' xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath data-name='Tracciato 513' d='M8.707 16.707L3.414 22h4.584v2h-8v-8h2v4.586l5.293-5.293zM15.998 0v2h4.586l-5.286 5.293 1.414 1.414 5.293-5.293V8h2V0zm-8 2V0h-8v8h2V3.414l5.293 5.293 1.414-1.414L3.412 2zm14 18.586l-5.293-5.293-1.407 1.414L20.591 22h-4.593v2h8v-8h-2z' fill='%23353535'/%3E%3C/svg%3E");
          top: 0;
          left: 0;
          transform: scale(1.2);
          transform-origin: center;
        }
      }
      .close.pull-right {
        position: absolute;
        top: 50%;
        right: rem(34);
        transform: translateY(-50%);
        width: rem(27);
        height: rem(27);
        margin: 0;
        padding: 0;
        opacity: 1;
        &:focus {
          outline: none;
        }
        span {
          display: none;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: rem(27);
          height: rem(27);
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg data-name='Componente 52 – 1' xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath data-name='Tracciato 510' d='M15.707 14.293a1 1 0 11-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 11-1.414-1.414L6.586 8 .293 1.707A1 1 0 111.707.293L8 6.586 14.293.293a1 1 0 011.414 1.414L9.414 8z' fill='%232e3138'/%3E%3C/svg%3E");
          transform: scale(1.5);
          transform-origin: center 0;
        }
      }
    }
    &-body {
      padding-top: rem(67);
      padding-bottom: rem(91);
      padding-left: rem(92);
      padding-right: rem(78);
      max-height: none;
      .primary-images {
        padding: 0 !important;
        background-color: transparent !important;
        .carousel.slide {
          max-width: rem(626);
          max-height: rem(626);
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        @include carousel-controls;
      }
      .right-body-part {
        text-align: left !important;
        padding-left: 0;
        padding-left: rem(37);
        @include media-breakpoint-down(lg) {
          padding-top: 15px !important;
        }
        @include promotion-details;
        .attributes{
          margin-top:0px !important;
          .swatch.row{
            margin-bottom: 0px !important;
          }
        }
      }
      .body-info {
        @include on-sale;
        a {
          @include brand-style;
        }
        h1 {
          @include product-name;
          text-transform: inherit;
          margin-bottom: 1rem;
        }
        @include prices-block;
      }
      .detail-panel {
        @include attributes-block;
      }
      @include wishlist-block;
      .body-end {
        margin-top: rem(20);
        @include select-quantity;
        @include buy-button;
      }
    }
    &-footer {
      display: none;
    }
  }
}

.inventory-date-expected {
  text-align: center;
  margin-top: rem(25);
  margin-bottom: 0;
  @include media-breakpoint-down(lg) {
    margin-top: rem(15);
  }
  .inventory-expected-date,
  .inventory-expected-label {
    font-size: rem(16);
    letter-spacing: 0.01em;
    line-height: 1;
    color: $mexican-red;
    margin: 0;
    @include media-breakpoint-down(lg) {
      font-size: rem(14);
    }
    @include media-breakpoint-down(sm) {
      font-size: rem(12);
    }
  }
}
