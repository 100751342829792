$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 768px,
    lg: 1024px,
    xl: 1366px,
    xxl: 1920px
) !default;

$container-max-widths: (
    xl: 100%,
    xxl: 1638px
) !default;

// Mobile version -> resolution lower than 768px
// Tablet version -> from 768px to 1023 px 
// Desktop version -> from 1024px on