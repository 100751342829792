@mixin toast-message() {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  @include media-breakpoint-down(sm) {
    width: 85%;
  }
}

@mixin toast-alert() {
  animation: fade 5s linear forwards;
  box-shadow: none;
  font-weight: 600;
  font-size: rem(16);
  line-height: 1;
  padding: rem(40);
  border: none;

  @include media-breakpoint-down(sm) {
    font-size: rem(16);
    font-weight: 400;
    line-height: rem(22);
    padding: rem(15);
  }

  &.alert-success {
    background-color: #333;
    color: $white;
    position: relative;
    padding-left: rem(70);
    @include media-breakpoint-down(sm) {
      padding-left: rem(40);
    }
    &:before {
      content: "";
      background-image: url("../images/cart.svg");
      filter: invert(1);
      background-repeat: no-repeat;
      position: absolute;
      width: rem(35);
      height: rem(35);
      top: 50%;
      left: rem(23);
      transform: translateY(-60%);
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &.show {
    display: block;
  }
}
