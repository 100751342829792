@import "toastMessage";
@import "pdp";

.attribute {
  margin-top: 0.938em;

  label {
    display: block;
  }
}

.swatch a {
  text-decoration: none;
}

.primary-images {
  @include media-breakpoint-down(xs) {
    margin: 0;
    padding: 0;
  }
}

.prices-add-to-cart-actions {
  width: 100%;
  position: relative;
  bottom: 0;
  z-index: 0;
  padding-right: 0;
  padding-left: 0;
  left: 0;

  .price {
      text-align: center;
  }
}

.prices {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  text-align: center;
}

.cart-and-ipay {
  text-align: center;

  @include media-breakpoint-only(xs) {
      padding-bottom: 0px;

      .btn {
          width: 98%;
          margin: 0%;
          display: block;
      }
  }
}

.add-to-cart-messages {
  @include toast-message();
  z-index: 5;
  .add-to-basket-alert {
    &.alert-success {
      &:before {
        background-image: url("../images/cart.svg");
      }
    }
  }
}

.add-to-basket-alert {
  @include toast-alert();
}

.simple-quantity {
  margin-top: 1em;
}

.main-attributes {
  margin-top: 1em;
}

.size-chart {
  margin-top: 1.071em;
}

div.availability {
  margin-top: 1.071em;
}

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid $hr-border-color;

  &:last-child {
    border-bottom: none;
  }
}

// .container.product-detail {
//     margin-top: 2em;
//     margin-bottom: 2em;

//     @include media-breakpoint-only(xs) {
//         margin-top: 0;
//         margin-bottom: 0;
//     }
// }

.product-option:not(:first-child) {
  margin-top: 1.071em;
}

// .cc-product-description-details {
//     border-top: 1px solid lightgray;
//     border-bottom: 1px solid lightgray;
// }

.cc-product-dad {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 4rem;
}

// .cc-product-description {
//     border-right: 1px solid lightgray;
// }
