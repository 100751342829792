@import "../variables";
@import "shapes";

@mixin swatch($diameter, $color) {
  @include circle($diameter, $color);
  @include swatch-check-mark();

  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin-right: 0.313em;
  position: relative;

  // swatch-filter x mark to undo a filter
  i.fa-times-circle {
    background: $white;
    border-radius: 50%;
    height: 0.75em;
    line-height: 0.8em;
    width: 0.8em;
  }
}

@mixin swatch-check-mark() {
  &.color-value[data-selected="true"]::after {
    color: $black;
    content: "\f058";
    display: table-caption;
    font-family: "FontAwesome";
    font-size: 1.625em;
    left: 0.295em;
    position: absolute;
  }

  &.color-value.selected::after {
    background: $white;
    border-radius: 50%;
    color: $black;
    content: "\f058";
    display: table-caption;
    font-family: "FontAwesome";
    font-size: 1.625em;
    height: 0.75em;
    left: 0.31em;
    line-height: 0.8em;
    position: absolute;
    top: 0.35em;
    width: 0.8em;
  }
}

$swatch-colors: (
  "beige": #f5f5dc,
  "black": $black,
  "blue": $blue,
  "brown": #a52a2a,
  "green": $green,
  "grey": #8f979d,
  "navy": #000080,
  "orange": #ffa500,
  "pink": #fe249a,
  "purple": #800080,
  "red": #f00,
  "white": $white,
  "yellow": #ff0,
  "gold": #af9668,
  "natur": #c7bba7,
  "Titanium": #cecece,
  "E2E2E2": #e2e2e2,
  "CFB69F": #cfb69f,
  "F5F5F5": #f5f5f5,
  "3C5377": #3c5377,
  "76462C": #76462c,
  "FFFFFF": #ffffff,
  "4B5836": #4b5836,
  "888888": #888888,
  "EFD64C": #efd64c,
  "AF9668": #af9668,
  "F8F8F8": #f8f8f8,
  "A584AD": #a584ad,
  "B1A786": #b1a786,
  "CECECE": #cecece,
  "C7BBA7": #c7bba7,
  "E38F00": #e38f00,
  "EDD3DD": #edd3dd,
  "CB2C2C": #cb2c2c,
  "000000": #000000,
  "F7F7F7": #f7f7f7,
  "886746": #886746,
  "BAB4AD": #bab4ad,
  'DB87B4': #db87b4,
  'DEDEDE': #dedede
);

@each $color in map-keys($swatch-colors) {
  $colorValue: #{map-get($swatch-colors, $color)};

  .swatch-circle-#{$color} {
    @include circle(2.5em, $colorValue);

    display: block;
    position: relative;

    &.disabled {
      opacity: 0.2;
    }
  }

  .swatch-circle-XXXXXX{
    background-image: url('../../images/Mix_dot.svg');
    display: block;
    position: relative;
    width:2.5em;
    height:2.5em;
  }

  .swatch-filter-#{$color} {
    @include circle(1.38em, $colorValue);

    background-color: $colorValue;
    display: block;
    position: relative;

    &.disabled {
      opacity: 0.2;
    }
  }
}

// Credit: Christian Muecke (cmuecke@demandware.com)
.swatch-circle-miscellaneous {
  background: linear-gradient(
      0deg,
      rgba(130, 30, 145, 1) 0,
      rgba(130, 30, 145, 1) 25%,
      rgba(237, 209, 52, 1) 25%,
      rgba(255, 255, 0, 1) 50%,
      rgba(237, 209, 52, 1) 50%,
      #59ba00 50%,
      #59ba00 76%,
      #111 76%,
      #111 100%
    ),
    linear-gradient(
      0deg,
      rgba(14, 92, 209, 1) 0,
      rgba(14, 92, 209, 1) 50%,
      rgba(226, 11, 11, 1) 50%,
      rgba(226, 11, 11, 1) 100%
    );
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em;

  &.disabled {
    opacity: 0.2;
  }

  &.selected::after {
    transform: rotate(-35deg);
  }
}

.swatch-plp {
  width: 22px;
  height: 22px;
  border: none;
  @include media-breakpoint-down(md) {
    width: 17px;
    height: 17px;
  }
  &.swatch-circle-white,
  &.swatch-circle-FFFFFF {
    border: 1px solid #707070 !important;
  }
  &.swatch-circle-XXXXXX {
    background-position: center;
    background-repeat: no-repeat;
    display: block;
  }
}

.swatch-plus {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22'%3E%3Cg data-name='Raggruppa 234'%3E%3Cg data-name='Ellisse 205' transform='rotate(90 11 11)' fill='%23fff' stroke='%23d3d3d3'%3E%3Ccircle cx='11' cy='11' r='11' stroke='none'/%3E%3Ccircle cx='11' cy='11' r='10.5' fill='none'/%3E%3C/g%3E%3Cg data-name='Componente 40 – 2'%3E%3Cpath data-name='Tracciato 199' d='M17.386 11.303v-.614h-6.08V4.61h-.614v6.08H4.61v.614h6.082v6.081h.613v-6.08z' fill='%232d2d2d'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  @include media-breakpoint-down(md) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.32' height='17.32'%3E%3Cg data-name='Raggruppa 255'%3E%3Cg data-name='Ellisse 235' transform='rotate(90 8.66 8.66)' fill='%23fff' stroke='%23d3d3d3'%3E%3Ccircle cx='8.66' cy='8.66' stroke='none' r='8.66'/%3E%3Ccircle cx='8.66' cy='8.66' fill='none' r='8.16'/%3E%3C/g%3E%3Cg data-name='Componente 40 – 7'%3E%3Cpath data-name='Tracciato 199' d='M14.183 8.83v-.512H9.12V3.255h-.512v5.063H3.545v.512h5.063v5.063h.512V8.83z' fill='%232d2d2d'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
}
