@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "productCard";

.custom-modal {

    .modal-dialog {
        width: calc(100% - 30px);
        max-width: rem(600);
        max-height: 90%;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;

        @include media-breakpoint-down(md) {
            max-width: rem(500);
        }
    }

    .modal-content {
        border-radius: 0;
        border: none;
    }
    
    .modal-header {
        border: none;
        padding: rem(17) rem(45) rem(25) rem(45);

        @include media-breakpoint-down(xs) {
            padding: rem(15) rem(15) rem(12) rem(15);
        }

        .modal-title {
            display: block;
            font-weight: 400;
            font-size: rem(34);
            line-height: rem(38);
            color: $black;
            text-transform: uppercase;
            text-align: left;

            @include media-breakpoint-down(xs) {
                font-size: rem(22);
                line-height: rem(30);
            }
        }

        .close {
            opacity: 1;
            margin-top: rem(-14);

            @include media-breakpoint-down(xs) {
                margin-top: rem(-18);
            }

            & > svg {
                width: rem(17);
                height: rem(17);
            }
        }
    }

    .modal-body {
        padding: rem(25) rem(45) rem(25) rem(45);

        @include media-breakpoint-down(xs) {
            padding: rem(12) rem(15) rem(20) rem(15);
        }

        p {
            @include media-breakpoint-down(xs) {
                font-size: rem(12);
            }
        }

        .form-group {
            padding-top: 0 !important;
        }

        .request-password-body {
            p:last-child {
                margin-bottom: rem(50);

                @include media-breakpoint-down(xs) {
                    margin-bottom: rem(30);
                }
            }
        }
    }
}