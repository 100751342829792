.plp {
  &-intro {
    letter-spacing: rem(.40);
    color: $white;
    background-color: $cascade;
    padding-top: rem(70);
    padding-bottom: rem(80);
    @include media-breakpoint-down(lg) {
      padding-top: rem(65);
      padding-bottom: rem(60);
    }
    @include media-breakpoint-down(sm) {
      padding-top: rem(35);
      padding-bottom: rem(22);
    }
    h1 {
      font-family: "NotoSans-Regular", sans-serif;
      text-transform: uppercase;
      letter-spacing: rem(.40);
      color: $white;
      margin-bottom: 0 !important;
      font-size: rem(50);
      @include media-breakpoint-down(sm) {
        font-size: rem(28);
      }
    }
    .h8 {
      margin-top: rem(17);
      margin-bottom: 0;
      font-size: rem(16);
      letter-spacing: rem(.40);
      line-height: rem(28);
      color: $white;
      @include media-breakpoint-down(sm) {
        margin-top: rem(13);
      }
    }
    & + .search-results {
      .search-nav {
        display: none;
      }
      #content-search-results-pane {
        display: none;
      }
      .filter-bar {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
  &-outro {
    letter-spacing: rem(.40);
    background-color: $light-grey;
    padding-top: rem(120);
    padding-bottom: rem(180);
    @include media-breakpoint-down(lg) {
      padding-top: rem(85);
      padding-bottom: rem(65);
    }
    @include media-breakpoint-down(sm) {
      padding-top: rem(46);
      padding-bottom: rem(38);
    }
    .text-box {
      @include media-breakpoint-up(xxl) {
        padding-left: rem(3);
        padding-right: rem(3);
      }
    }
    h1 {
      font-family: "NotoSans-Regular", sans-serif;
      text-transform: uppercase;
      letter-spacing: rem(.40);
      color: $dark-grey;
      margin-bottom: 0 !important;
      font-size: rem(50);
      @include media-breakpoint-down(sm) {
        font-size: rem(28);
      }
    }
    .h8 {
      margin-top: rem(17);
      margin-bottom: 0;
      font-size: rem(16);
      letter-spacing: rem(.40);
      line-height: rem(28);
      color: $dark-grey;
      @include media-breakpoint-down(sm) {
        margin-top: rem(13);
      }
    }
  }
}

.search-results {
  @include media-breakpoint-down(sm) {
    overflow-x: hidden;
  }
  .grid-header {
    margin-top: rem(45);
    margin-bottom: rem(80);
    @include media-breakpoint-down(lg) {
      margin-top: rem(58);
      margin-bottom: rem(41);
    }
    @include media-breakpoint-down(sm) {
      padding-left: rem(15);
      padding-right: rem(15);
      margin-top: rem(32);
      margin-bottom: 0;
    }
    .result-count {
      letter-spacing: rem(0.40);
      text-transform: uppercase;
      margin-bottom: 0;
      display: flex;
      p {
        flex: 1;
        margin-bottom: 0;
        line-height: 1;
      }
      @include media-breakpoint-down(lg) {
        align-self: center;
      }
      @include media-breakpoint-down(sm) {
        order: 3;
        padding-top: rem(10);
      }
      &.special {
        padding-bottom: rem(120);
        @include media-breakpoint-down(lg) {
          padding-bottom: rem(85);
        }
        @include media-breakpoint-down(sm) {
          padding-bottom: rem(50);
          padding-top: 0;
        }
        .nav-tabs-wrapper {
          display: block !important;
          flex: auto;
          order: -1;
          padding-left: 0;
          padding-right: 0;
          padding-bottom: rem(65);
          margin-bottom: rem(37);
          border-bottom: 1px solid $border-grey;
          @include media-breakpoint-down(lg) {
            margin-bottom: rem(65);
            border-bottom: 0;
            padding: 0;
          }
          @include media-breakpoint-down(sm) {
            width: calc(100% + 30px);
            left: rem(-15);
            margin-bottom: rem(48);
          }
          .nav-tabs {
            max-width: rem(900);
            @include media-breakpoint-down(lg) {
              max-width: rem(980);
            }
            @include media-breakpoint-down(md) {
              max-width: calc(100% - 30px);
            }
            @include media-breakpoint-down(sm) {
              max-width: none;
            }
          }
        }
        .search-result-count,
        .search-keywords {
          text-transform: none;
          font-size: rem(22);
          color: $black;
          @include media-breakpoint-down(sm) {
            font-size: rem(20);
            line-height: rem(26);
          }
        }
        .suggestion {
          margin-top: rem(30);
          @include media-breakpoint-down(lg) {
            margin-top: rem(25);
          }
          @include media-breakpoint-down(sm) {
            margin-top: rem(20);
          }
          span,
          a {
            font-size: rem(16);
            color: $black;
            text-transform: none;
            @include media-breakpoint-down(sm) {
              font-size: rem(12);
              line-height: rem(24);
            }
          }
          a {
            font-weight: 700;
          }
        }
        .search-tips {
          margin-top: rem(62);
          @include media-breakpoint-down(lg) {
            margin-top: rem(50);
          }
          @include media-breakpoint-down(sm) {
            margin-top: rem(40);
          }
          h5 {
            font-weight: normal;
            font-size: rem(22);
            letter-spacing: 0.02em;
            color: $black;
            text-transform: none;
            @include media-breakpoint-down(lg) {
              margin-bottom: rem(10);
            }
            @include media-breakpoint-down(sm) {
              font-size: rem(20);
              margin-bottom: rem(7);
            }
          }
          ul {
            margin-bottom: 0;
            padding-left: rem(63);
            @include media-breakpoint-down(lg) {
              padding-left: rem(20);
            }
            li {
              text-transform: none;
              font-size: rem(16);
              color: $black;
              @include media-breakpoint-down(sm) {
                font-size: rem(12);
              }
            }
          }
        }
        .content-404-link {
          margin-top: rem(75);
          @include media-breakpoint-down(lg) {
            margin-top: rem(50);
          }
          @include media-breakpoint-down(sm) {
            margin-top: rem(35);
          }
          .link-404 {
            &:last-of-type {
              a {
                margin-bottom: 0;
              }
            }
            a {
              text-transform: none;
              font-size: rem(16);
              display: inline-block;
              margin-bottom: rem(26);
              @include media-breakpoint-down(lg) {
                margin-bottom: rem(30);
              }
              @include media-breakpoint-down(sm) {
                margin-bottom: rem(25);
                font-size: rem(12);
                line-height: rem(24);
              }
            }
          }
        }
      }
    }
    .sort-block {
      font-family: "NotoSans-Regular", sans-serif;
      @include media-breakpoint-down(lg) {
        order: inherit;
        @include media-breakpoint-down(sm) {
          order: inherit;
        }
        .sort-order {
          height: rem(44);
          font-size: rem(16);
          letter-spacing: 0.05em;
          line-height: rem(28);
          text-transform: uppercase;
          padding: 0 15px;
          border: 1px solid $black;
          background-image: url("data:image/svg+xml,%3Csvg data-name='Componente 20 – 181' xmlns='http://www.w3.org/2000/svg' width='14.625' height='8.203'%3E%3Cpath data-name='Tracciato 104' d='M7.313 8.203L.195 1.128a.658.658 0 010-.934.668.668 0 01.94 0l6.178 6.14 6.178-6.14a.668.668 0 01.94 0 .657.657 0 010 .934z'/%3E%3C/svg%3E");
          @include media-breakpoint-down(sm) {
            font-size: rem(12);
            padding-left: rem(5);
            padding-right: rem(5);
            padding-top: 0;
            padding-bottom: 0;
            width: 100%;
            height: rem(40);
            background-size: 5%;
            background-position-y: 48%;
          }
        }
        & + div {
          padding-right: 0;
        }
      }
    }
    .filter-results {
      font-family: "NotoSans-Regular", sans-serif;
      border-radius: 0;
      @include media-breakpoint-down(lg) {
        font-size: rem(16);
        letter-spacing: 0.05em;
        line-height: rem(28);
        text-transform: uppercase;
        padding-top: rem(7);
        padding-bottom: rem(7);
        padding-left: rem(25);
        padding-right: rem(25);
        width: auto;
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background-color: $white;
          color: $black;
        }
        @include media-breakpoint-down(sm) {
          font-size: rem(12);
          padding-left: 0;
          padding-right: 0;
          padding-top: rem(5);
          padding-bottom: rem(5);
          width: 100%;
        }
        > svg {
          width: rem(35);
          height: rem(23);
          margin-right: rem(8);
          @include media-breakpoint-down(sm) {
            width: rem(25);
            height: rem(16);
            margin-right: rem(5);
          }
        }
      }
    }
  }
  .show-more {
    padding-top: rem(55);
    padding-bottom: rem(120);
    @include media-breakpoint-down(lg) {
      padding-top: rem(20);
      padding-bottom: rem(55);
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: rem(45);
    }
    button.more {
      max-width: rem(469);
      border: 1px solid $border-palette-color;
      padding-top: rem(23);
      padding-bottom: rem(22);
      @include media-breakpoint-down(lg) {
        max-width: rem(189);
        font-size: rem(16);
        padding-top: rem(14);
        padding-bottom: rem(12);
      }
      @include media-breakpoint-down(sm) {
        max-width: rem(130);
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .refinement-bar {
    padding-right: rem(27);
    @include media-breakpoint-down(lg) {
      z-index: 5;
      padding: 0;
      overflow-x: hidden;
      overflow-y: scroll;
      top: 0;
      left: 0;
      right: 0;
      bottom: rem(-20);
      background-color: transparent;
      @include media-breakpoint-down(sm) {
        bottom: 0;
        background-color: $white;
        overflow-y: visible;
        min-height: -webkit-fill-available;
        height: 100vh;
      }
      .filter-header {
        margin-left: 0;
        margin-right: 0;
        padding-top: rem(17);
        padding-bottom: rem(17);
        padding-left: rem(23);
        padding-right: rem(23);
        background-color: $light-grey;
        @include media-breakpoint-down(sm) {
          padding-left: rem(12);
          padding-right: rem(12);
        }
        .header-bar {
          background-color: transparent;
          border-bottom: none;
          .close-side {
            align-self: center;
            .close {
              outline: none;
              border: none;
              span {
                margin-right: rem(12);
                @include media-breakpoint-down(sm) {
                  margin-right: rem(7);
                }
              }
              svg {
                @include media-breakpoint-down(sm) {
                  transform: translateY(-1px);
                }
              }
            }
          }
          .result-count span,
          .close span {
            font-size: 12px;
            letter-spacing: 0.04em;
            margin-bottom: 0;
            text-transform: uppercase;
            line-height: 1;
          }
        }
      }
    }
    .secondary-bar {
      padding-bottom: 0;
      @include media-breakpoint-down(lg) {
        padding-top: rem(26);
        padding-bottom: rem(26);
        padding-left: rem(23);
        padding-right: rem(23);
        background-color: $white;
        @include media-breakpoint-down(sm) {
          padding-left: rem(20);
          padding-right: rem(20);
        }
        button.reset {
          font-size: rem(19);
          letter-spacing: 0.05em;
          line-height: rem(28);
          text-transform: uppercase;
          padding-top: rem(23);
          padding-bottom: rem(23);
          @include media-breakpoint-down(sm) {
            font-size: rem(14);
            padding-top: rem(11);
            padding-bottom: rem(11);
          }
          &:hover {
            background-color: $white;
            color: $black;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    select.sort-order {
      max-width: rem(386);
      text-transform: uppercase;
      @include media-breakpoint-down(xl) {
        padding-left: rem(15);
        padding-right: rem(15);
      }
    }
    .refinements {
      .selected{
        font-weight:bold;
      }
      @include media-breakpoint-down(lg) {
        background-color: $white;
        padding-left: rem(23);
        padding-right: rem(23);
      }
      @include media-breakpoint-down(sm) {
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid $form-grey;
      }
      .card {
        border: none;
        @include media-breakpoint-down(lg) {
          margin-bottom: 0;
        }
        &.active {
          @include media-breakpoint-down(lg) {
            .card-header {
              button {
                &:after {
                  transform: rotate(180deg);
                  transform-origin: center 25%;
                  @include media-breakpoint-down(sm) {
                    transform: rotate(180deg) scale(0.7);
                  }
                }
              }
            }
          }
        }
        &-header {
          padding: 0;
          border-bottom: none;
          @include media-breakpoint-down(lg) {
            border-top: 1px solid $form-grey;
            padding-left: rem(260);
            padding-right: rem(260);
          }
          @include media-breakpoint-down(sm) {
            padding-left: rem(20);
            padding-right: rem(20);
          }
          button {
            @include media-breakpoint-down(lg) {
              padding-top: rem(15);
              padding-bottom: rem(15);
              padding-left: 0;
              padding-right: 0;
              position: relative;
              @include media-breakpoint-down(sm) {
                padding-top: rem(4);
                padding-bottom: rem(7);
              }
              &:focus {
                outline: none;
                box-shadow: none;
              }
              &:after {
                content: "";
                width: rem(22);
                height: rem(13);
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.745' height='12.807'%3E%3Cpath data-name='Tracciato 465' d='M10.873 12.808L.29 1.762a1.064 1.064 0 010-1.459.959.959 0 011.4 0l9.185 9.587L20.06.303a.959.959 0 011.4 0 1.063 1.063 0 010 1.459z' fill='%23333'/%3E%3C/svg%3E");
                float: none;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                margin-top: 0;
                @include media-breakpoint-down(sm) {
                  transform: translateY(-50%) scale(0.7);
                }
              }
            }
            > span {
              @include media-breakpoint-down(lg) {
                font-size: rem(19);
                letter-spacing: 0.02em;
                line-height: rem(38);
                text-transform: uppercase;
                @include media-breakpoint-down(sm) {
                  font-size: rem(14);
                  line-height: rem(28);
                }
              }
            }
          }
          h2 {
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin-bottom: 0;
          }
        }
        &-body {
          padding-top: rem(11);
          padding-bottom: rem(20);
          padding-left: rem(23);
          padding-right: rem(23);
          @include media-breakpoint-down(xl) {
            padding-left: rem(12);
          }
          @include media-breakpoint-down(lg) {
            padding-top: 0;
            padding-left: rem(260);
            padding-right: rem(260);
          }
          @include media-breakpoint-down(sm) {
            padding-left: rem(20);
            padding-right: rem(20);
          }
          &#refinement-colore {
            padding-top: rem(20);
            @include media-breakpoint-down(lg) {
              padding-top: rem(5);
            }
            @include media-breakpoint-down(sm) {
              padding-bottom: rem(14);
            }
            ul.values.content {
              overflow: hidden;
            }
          }
          ul {
            padding: 0;
            margin: 0;
            li {
              padding-left: 0;
              button {
                &:focus {
                  outline: none;
                }
                .fa-circle-o,
                .fa-square-o {
                  display: none;
                }
                .fa-check-circle,
                .fa-check-square {
                  display: none;
                  & + span {
                    &:not(.swatch-circle) {
                      text-shadow: 0 0 0.65px #333, 0 0 0.65px #333;
                    }
                  }
                }
                .swatch-mark.selected {
                  &:after {
                    display: none;
                    @include media-breakpoint-down(lg) {
                      display: block;
                      content: "";
                      background-repeat: no-repeat;
                      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.813' height='21.812'%3E%3Cpath data-name='Tracciato 472' d='M9.009 15.37l-4.464-4.464.67-.67 3.793 3.794 7.587-7.587.67.671zm12.8-4.464a10.906 10.906 0 10-10.906 10.906 10.918 10.918 0 0010.91-10.906zm-.949 0A9.958 9.958 0 1110.902.948a9.969 9.969 0 019.961 9.958z' fill='%232d2d2d'/%3E%3C/svg%3E"),
                        #d4d4d4;
                      width: rem(22);
                      height: rem(22);
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    }
                  }
                  & ~ .color-name {
                    border-bottom: 0px dotted $main-color;
                    font-weight:bold;
                  }
                }
                span {
                  &:not(.swatch-circle) {
                    font-size: rem(16);
                    letter-spacing: 0.02em;
                    line-height: rem(34);
                    color: $main-color;
                    transition: text-shadow 0.3s;
                    @include media-breakpoint-down(sm) {
                      font-size: rem(14);
                      line-height: rem(28);
                    }
                  }
                  &:hover {
                    text-shadow: 0 0 0.65px $main-color, 0 0 0.65px $main-color;
                  }
                }
              }
              &.color-attribute {
                display: block;
                height: rem(34);
                @include media-breakpoint-down(lg) {
                  display: inline-block;
                  height: rem(39);
                  margin-right: rem(10);
                  &:last-of-type {
                    margin-right: 0;
                  }
                  @include media-breakpoint-down(sm) {
                    margin-right: rem(5);
                    margin-bottom: rem(12);
                  }
                }
                &.disabled {
                  display: none;
                }
                span {
                  &.swatch-circle {
                    width: rem(15);
                    height: rem(15);
                    @include media-breakpoint-down(lg) {
                      width: rem(40);
                      height: rem(40);
                    }
                    &:not(.swatch-circle-white),
                    &:not(.swatch-circle-FFFFFF) {
                      border: none;
                    }
                    &.swatch-circle-white,
                    &.swatch-circle-FFFFFF {
                      border: 1px solid #707070 !important;
                    }
                  }
                  &.color-name {
                    margin-left: rem(10);
                    line-height: rem(22);
                    @include media-breakpoint-down(lg) {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.search-results.container {
  @include media-breakpoint-up(xxl) {
    padding-left: rem(15);
    padding-right: rem(15);
  }
}

#product-search-results.container,
#content-search-results-pane.container {
  padding-left: 0;
  padding-right: 0;
}

.results-column {
  @include media-breakpoint-up(xxl) {
    padding-left: rem(3);
    padding-right: rem(3);
  }
}

.grid-body {
  @include media-breakpoint-down(lg) {
    margin-left: rem(-9);
    margin-right: rem(-9);
  }
  @include media-breakpoint-down(sm) {
    margin-left: rem(-5);
    margin-right: rem(-5);
  }
}

.product-grid {
  > .col-sm-4 {
    @include media-breakpoint-up(lg) {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
    @include media-breakpoint-down(lg) {
      padding-left: rem(9);
      padding-right: rem(9);
    }
    .product {
      max-width: rem(387);
      &-tile {
        border: 0;
        position: relative;
        .price {
          .range {
            display: flex;
            flex-direction: row;
            line-height: 1;
            span {
              display: inline-block;
            }
            > span {
              &:first-of-type {
                padding-right: rem(10);
                @include media-breakpoint-down(sm) {
                  padding-right: rem(2);
                }
              }
              &:last-of-type {
                padding-left: rem(10);
                @include media-breakpoint-down(sm) {
                  padding-left: rem(2);
                }
              }
            }
            del {
              display: none;
            }
          }
        }
      }
    }
  }
}

#product-search-results {
  .nav-tabs-wrapper {
    width: 100% !important;
    max-width: 100% !important;
    @include media-breakpoint-down(sm) {
      width: calc(100% + 30px) !important;
      max-width: calc(100% + 30px) !important;
    }
    .nav-item {
      &.special-one {
        min-width: rem(450);
        @include media-breakpoint-down(md) {
          min-width: auto;
        }
      }
    }
  }
}

#content-search-results-pane {
  .nav-tabs-wrapper {
    @include media-breakpoint-down(sm) {
      transform: translateX(-15px);
    }
    .nav-item {
      &:first-of-type {
        a {
          span {
            display: inline-block;
            transform: translateX(2px);
            @include media-breakpoint-down(lg) {
              transform: none;
            }
          }
        }
      }
      &:last-of-type {
        min-width: rem(450);
        @include media-breakpoint-down(md) {
          min-width: auto;
        }
      }
    }
  }
  .grid-header {
    margin-bottom: 0;
  }
  .special {
    padding-bottom: 0;
    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }
  #content-search-results {
    padding-bottom: rem(145);
    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
    }
    > div {
      margin-bottom: rem(32);
      &:last-of-type {
        margin-bottom: 0;
        .card {
          @include media-breakpoint-down(sm) {
            border-bottom: none;
          }
        }
      }
    }
    .card {
      border: none;
      border-bottom: 1px solid $border-grey;
      border-radius: 0;
      margin-bottom: 0;
      &-header,
      &-body {
        padding-top: 0;
        padding-left: rem(7);
        padding-right: 0;
        border: none;
        @include media-breakpoint-down(sm) {
          padding-left: rem(15);
          padding-right: rem(15);
        }
        h4 {
          a {
            font-weight: 400;
            font-size: rem(22);
            letter-spacing: 0.02em;
            color: #000;
            @include media-breakpoint-down(sm) {
              font-size: rem(20);
            }
          }
        }
      }
      &-header {
        padding-bottom: rem(16);
        @include media-breakpoint-down(sm) {
          padding-bottom: rem(14);
        }
      }
      &-body {
        font-size: rem(16);
        line-height: rem(28);
        padding-bottom: rem(40);
        color: #000;
        @include media-breakpoint-down(sm) {
          font-size: rem(12);
          line-height: rem(24);
        }
      }
    }
  }
}

.selected-filter-price{
  border-bottom: 1px dotted $main-color;
  text-shadow: 0 0 .65px #333, 0 0 .65px #333;
  font-weight:bold;
}

/* FILTRERING KNAPPER */
.filter-results svg {
  width: 1.1875rem !important;
  height: 1.4375rem !important;
  margin-right: 0.5rem;
}

.search-results .grid-header .filter-results {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  line-height: 1.75rem;
  text-transform: none;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  width: 100%;
  border: 1px solid #ccc;
}
.filter-results:hover{
  border:1px solid #666 !important;
  background:#fff !important;
  color:#333 !important;
}

.search-results .grid-header .sort-block .sort-order {
  height: 2.75rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  line-height: 1.75rem;
  text-transform: none;
  padding: 0 15px;
  border: 0px solid #ccc;
  background-size: 12px;
  text-align: right;
  padding-right: 30px;
}
.result-count .sort-order{
line-height: 44px;
height: 44px;
font-size: 0.875rem;
font-weight: normal;
letter-spacing: 0.05em;
text-align: right;
padding: 0 33px 0 0;
background-size: 12px !important;
border: 0 !important;
cursor:pointer;
}
.result-count-container span{
padding:0 5px;
margin:24px 0px;
background:$grey2;
text-transform: none;
display:inline-block;
}
.result-count div{
  @include media-breakpoint-down(sm) {
      padding: 0px;
  }
  @include media-breakpoint-down(md) {
      padding: 0px;
  }
  @include media-breakpoint-down(lg) {
      padding: 0px;
  }
  @include media-breakpoint-up(lg) {
      padding: 0px;
  }
}
.result-count-container-trigger{display:block; width:100%;height:0;}
.result-count-container-trigger.snap{display:block; width:100%;height:70px;}
.result-count-container{
  display:block; width:100%; background:#fff;
  position: relative;height:70px;
  padding:0px;
}
.result-count-container.snap{
  position: fixed;
  top: 0px;
  left: 0px;
  z-index:5;
}
.result-count-container .row{
  margin:0;
  transition: all 0.0s ease;
}
.result-count-container.snap .row{
  margin:0 auto;
  max-width:1638px;
  @media only screen and (min-width: 1632px) and (max-width: 1920px) {
      padding: 0 6px;
      max-width: 100%;
  }
}

.result-count-container .col-6, .result-count-container .col-12{
  padding:0px;
}
.result-count{
  padding:0px;
}
.result-count .col-6{
  padding: 14px 0;
}

@include media-breakpoint-down(lg) {
  .result-count-container{
  }
  .result-count-container.snap {
      top: 220px;
      opacity:0;
      background:transparent;
  }
  .result-count-container.hellobar-in-page.snap{
    top: 250px;
  }
  .result-count .filter-results{
      background:#fff !important;
      border: 1px solid #ccc !important;
  }
  .result-count-container.snap .sort-order, .result-count-container.snap .result-count span {
      display:none;
  }
}
@include media-breakpoint-down(sm) {
  .result-count-container{}
  .result-count-container.snap {
      top: 220px;
  }
  .result-count-container.hellobar-in-page.snap{
    top: 250px;
  }
  .result-count .col-6 {
      padding: 0px;
  }
  .result-count-container span {
      margin: 12px 0px;
  }
  .result-count-container.snap .result-count {
      padding-right: 5px;
  }
}
@include media-breakpoint-down(xs) {
  .d-xs-none{display:none;}
  .result-count-container{}
  .result-count-container.snap {
      top: 165px;
  }
  .result-count-container.hellobar-in-page.snap{
    top: 195px;
  }
  .result-count .col-6 {
      padding: 0px;
  }
  .result-count-container span {
      margin: 0px 0px;
  }
  .result-count-container .result-count {
      padding-top: 0px !important;
  }
  .result-count-container.snap .result-count {
      padding-right: 5px;
  }
  .result-count-container .d-sm-none{
      margin-top:5px;
  }
}

.viewresult{
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.75rem;
  text-transform: uppercase;
  font-size: .875rem;
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  background:$akaroa;
  color:#fff;
  border:0;
  width:100%;
  margin-top:15px;
  margin-bottom:15px;
  @include media-breakpoint-down(sm) {
      margin-bottom:100px;
  }
}

/* FILTRERING KNAPPER SLUT */


/* OPEN FILTER */
.collapsible-lg.active .content, .collapsible-lg.active .card-body {
  display: block;
}
.collapsible-lg .content, .collapsible-lg .card-body {
  display: none;
}
.refinement-bar{
  padding:0px !important;
}
.refinement-bar .header-bar{
  padding:5px;
}
.card.refinement{
  @include media-breakpoint-up(xl) {
  border-top:1px solid #ccc !important;
  margin-bottom:0px;
  }
}

.refinements .btn {
font-size: 1.1rem;
text-transform: uppercase;
font-weight: 500;
letter-spacing: 1px;
line-height: 1;
padding-top: 1.5625rem;
padding-bottom: 1.5625rem;
padding-left: 2rem;
padding-right: 3.125rem;
border-radius: 0;
font-family: "open sans", serif;
}
.refinements .btn .fa{
float: right;
font-size: 32px;
line-height: 17px;
}

.refinements ul li button{
padding-left:20px;
}

.refinement-bar{
overflow-x: hidden !important;
}
.search-results .refinement-bar .refinements .card-header button:focus {outline:0; box-shadow: none;}
.search-results .refinement-bar .refinements .card-header button:after {
content: '';
width: 1.375rem;
height: 0.8125rem;
background-repeat: no-repeat;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.745' height='12.807'%3E%3Cpath data-name='Tracciato 465' d='M10.873 12.808L.29 1.762a1.064 1.064 0 010-1.459.959.959 0 011.4 0l9.185 9.587L20.06.303a.959.959 0 011.4 0 1.063 1.063 0 010 1.459z' fill='%23333'/%3E%3C/svg%3E");
float: none;
position: absolute;
top: 50%;
right: 25px;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
margin-top: 0;
transition: all 0.5s ease;
}
.search-results .refinement-bar .refinements .card-header button{
padding-top: 1.25rem;
padding-bottom: 1.25rem;
}
.search-results .refinement-bar .refinements .card.active .card-header button:after {
-webkit-transform: rotate(180deg);
-ms-transform: rotate(180deg);
transform: rotate(180deg);
-webkit-transform-origin: center 25%;
-ms-transform-origin: center 25%;
transform-origin: center 25%;
}

@media only screen and (min-width: 1366px) {
  .modal-background{
      z-index:10;
  }
  .search-results .refinement-bar .secondary-bar {
      padding: 15px 25px 15px 30px;
  }
  .search-results .refinement-bar .secondary-bar .h2{
      float:left;
      margin:0px;
      text-transform: uppercase;
      font-size: 27px;
      line-height: 35px;
  }
  .search-results .refinement-bar .secondary-bar button{
      float:right;
      margin:7px 0 0 0;
      border:0px;
      padding:0px;
      text-decoration: underline;
  }
}

.search-results .refinement-bar .refinements .card-body ul li button .fa {
  display: inherit;
}

.search-results .refinement-bar .refinements .card-body ul li button .fa{
  font-size:26px;
  color:$akaroa;
  top:4px;
  position: relative;
  margin-right:10px;
}
.search-results .refinement-bar .refinements .card-body ul li.color-attribute .fa{
  top:1px;
}
.rv-square{
  border: 1px solid $form-grey;
  border-radius: 0;
  width: 1.0625rem;
  height: 1.0625rem;
  background: #fff;
  margin-right: 10px;
  position: relative;
  top: 2px;
  display: inline-block;
}
.rv-check-square{
  border: 1px solid $form-grey;
  border-radius: 0;
  width: 1.0625rem;
  height: 1.0625rem;
  background: $akaroa;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  top: 2px;
  box-shadow: 0px 0px 0px 2px rgb(255, 255, 255) inset;
}
.color-attribute .rv-square,
.color-attribute .rv-check-square{
  top: 0px;
}

@include media-breakpoint-up(lg) {
  .filter-header {
      margin:0px !important;
      }
  .refinement-farve .card-body ul{
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2;
  }
  .header-bar button.close{
      padding:3px 10px 3px 3px;
  }
  .header-bar button.close span{
      font-size: 0.875rem;
      padding:3px;
  }
  .header-bar button.close svg{
      
  }
  .search-results .refinement-bar .refinements .card-body ul li.color-attribute {
      height: 2.4375rem;
      margin-left: 1.2em;
  }
  ul li.color-attribute span.swatch-circle {
      width: 2.5rem;
      height: 2.5rem;
  }
}
/* OPEN FILTER SLUT */
.product-tile{
  .product-tile-image{
      position: relative;
  }
  .h7 {
  font-size: 0.9rem;
  @include media-breakpoint-down(sm) {
    font-size: 0.65em;
  }
  }
  .on-sale{
      padding-top: 0.325rem;
      padding-bottom: 0.325rem;
      padding-left: 0.475rem;
      padding-right: 0.4375rem;
      right: inherit;
      left: 0.375rem;
      top: 0.375rem;
  }
  .free-delivery{
      position:absolute;
      padding-top: 0.325rem;
      padding-bottom: 0.325rem;
      padding-left: 0.475rem;
      padding-right: 0.4375rem;
      margin-bottom:0px;
      bottom: 0.375rem;
      right:0.375rem;
      background:$light-grey;
      line-height: 1;
      color:$main-color;
  }
  .add-to-wishlist{
      position: absolute;
      bottom: 0.5rem;
      left: 0.8125rem;
      margin-bottom:0px;
      z-index: 1;
      &:hover{
      cursor:pointer;
      }
  }
}